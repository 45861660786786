import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';
import { device } from 'utils/device';

const MessageContainer = styled.div`
  position: relative;
  padding-block: 30px;
`;

const Content = styled.div`
  ${Flex({ align: 'flex-start', justify: 'space-between' })};
  margin-right: auto;
  gap: 20px;
  min-width: 130px;
  max-width: 40%;
  padding: 10px;
  width: fit-content;
  background-color: #f3f5f9cf;
  border: 0.5px solid #eaeaea;
  border-radius: 10px 10px 10px 0;

  @media (${device.md}) {
    max-width: 90%;
  }
`;

const TextBlock = styled.div`
  border-radius: 10px 10px 10px 0;
`;

const TextWrapper = styled.div`
  min-width: 65px;
`;

const FileWrap = styled.div`
  ${Flex({ justify: 'flex-start' })};
  padding-block: 5px;
`;

const Footer = styled.div`
  ${Flex({ align: 'flex-end' })};
  justify-content: ${({ reaction }) =>
    reaction ? 'space-between' : 'flex-start'};
  margin-top: 5px;
`;

export { MessageContainer, Content, TextBlock, TextWrapper, FileWrap, Footer };
