import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FlexWrap } from 'components/App/GlobalStyled';
import { RAvatar } from 'components/RAvatar';
import { chatsRoom } from 'store/chats/room';
import { IconDoubleTick, IconTick } from 'components/Icons';
import { auth } from 'store/auth';
import { getUserProfileIndexById } from 'utils/prepare';
import {
  Wrap,
  Name,
  AvatarContainer,
  Content,
  MessageText,
  Aside,
  Date,
  UnreadCount,
  UnreadReactions,
} from './styled';

Chat.propTypes = {
  chat: PropTypes.object,
};

export function Chat({ chat }) {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { _id: managerId } = useSelector(auth.selectors.user);
  const currentRoomId = useSelector(chatsRoom.selectors.currentRoomId);

  const attachedFilesLength = chat.lastMessage
    ? chat.lastMessage.photos.length + chat.lastMessage.files.length
    : null;
  const roomsIdsAreSimilar = chat._id === currentRoomId;
  const messagesArePresent = chat.lastMessage;

  const currentMessageContent = useMemo(() => {
    if (messagesArePresent) {
      if (chat.lastMessage.message) {
        return chat.lastMessage.message;
      }
      return attachedFilesLength > 1
        ? `Attached ${attachedFilesLength} files`
        : `Attached ${attachedFilesLength} file`;
    }
    return null;
  }, [chat]);

  const userProfile = useMemo(
    () => chat.profile[getUserProfileIndexById(chat.profile, managerId)],
    [chat]
  );

  const handleRoom = async () => {
    if (roomsIdsAreSimilar) return;
    dispatch(chatsRoom.actions.RESET_STATE());
    dispatch(chatsRoom.actions.SET_TARGET_USER_ID(userProfile._id));
    history.push(`/manager/inbox/${chat.type.toLowerCase()}/${chat._id}`);
  };

  return (
    <Wrap
      active={roomsIdsAreSimilar}
      onClick={handleRoom}
    >
      <AvatarContainer>
        <RAvatar
          size={60}
          bgColor={chat.config.roomColor}
          name={`${userProfile.firstname} ${userProfile.lastname}`}
          isOnline={userProfile.isOnline}
        />
      </AvatarContainer>

      <Content>
        <Name>
          {userProfile.firstname}&nbsp;
          {userProfile.lastname}
        </Name>
        {chat.project ? (
          <MessageText
            css={{ color: theme.black }}
            active={roomsIdsAreSimilar}
          >
            {chat.project.title}
          </MessageText>
        ) : null}
        {messagesArePresent && (
          <MessageText active={roomsIdsAreSimilar}>
            {currentMessageContent}
          </MessageText>
        )}
      </Content>

      <Aside>
        {messagesArePresent && <Date>{chat?.lastMessage.createdAt}</Date>}
        <FlexWrap
          justify="flex-end"
          gap={5}
        >
          {chat.unreadReactionsCount > 0 && <UnreadReactions />}
          {chat.unreadMessagesCount > 0 && (
            <UnreadCount wide={chat.unreadMessagesCount > 99}>
              {chat.unreadMessagesCount > 99 ? '99+' : chat.unreadMessagesCount}
            </UnreadCount>
          )}
          {chat.unreadMessagesCount === 0 && (
            <>
              {chat.lastMessage?.isRead ? (
                <IconDoubleTick
                  fill={roomsIdsAreSimilar ? theme.white : theme.como}
                />
              ) : (
                <IconTick
                  fill={roomsIdsAreSimilar ? theme.white : theme.como}
                />
              )}
            </>
          )}
        </FlexWrap>
      </Aside>
    </Wrap>
  );
}
