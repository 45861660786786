import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RTable } from 'components/RTable';
import { useTablePagination } from 'hooks/useTablePagination';
import { columns } from 'pages/Manager/UsersPage/Table/config';
import { adminUsersPage } from 'store/admin/usersPage';

export function Table() {
  const dispatch = useDispatch();
  const list = useSelector(adminUsersPage.selectors.list);
  const {
    page,
    perPage,
    totalItems: totalRows,
  } = useSelector(adminUsersPage.selectors.pagination);

  const { fetchData, pageCount, pageIndex, pageSize } = useTablePagination({
    totalRows,
    perPage,
    deps: [page, list],
  });

  useEffect(() => {
    dispatch(
      adminUsersPage.thunks.getUsersList({
        pageIndex,
        pageSize,
      })
    );
  }, [pageIndex, pageSize]);

  return (
    <RTable
      data={list}
      height={620}
      fetchData={fetchData}
      totalRows={totalRows}
      pageCount={pageCount}
      columns={columns}
    />
  );
}
