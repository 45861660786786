import styled, { keyframes } from 'styled-components';

const jump = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const ContextMenuContainer = styled.ul`
  border-radius: 16px;
  padding: 6px;
  width: auto;
  height: 50px;
  margin: 0;
  position: absolute;
  list-style: none;
  top: -20px;
  left: 10px;
  background-color: white;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;

const ReactionContainer = styled.li`
  display: inline-block;
  padding: 6px 8px;
  font-weight: 500;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#D1E4FF' : '#FFFFFF')};

  &:hover {
    animation: ${jump} 0.5s linear;
  }
`;

export { ContextMenuContainer, ReactionContainer };
