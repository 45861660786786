import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';

const defaultValues = {
  name: '',
  onTime: '',
  duration: '',
  cost: '',
  serviceProvider: '',
};

const schema = yup.object().shape({
  name: yup.string().max(25).required(),
  onTime: yup.string().required(),
  duration: yup.string().required(),
  cost: yup.number().nullable().transform(transformNaN).min(1),
  serviceProvider: yup.string().max(30),
});

export { defaultValues, schema };
