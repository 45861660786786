import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const invoicesPage = {
  getInvoices(params) {
    return http.get(WEB_API_ROUTES.manager.invoicesPage.getInvoices, {
      params,
    });
  },
  createInvoice(formData) {
    return http.post(
      WEB_API_ROUTES.manager.invoicesPage.createInvoice,
      formData
    );
  },
};
