import styled from 'styled-components';

import { Flex, ScrollBar, TextEllipsis } from 'components/App/GlobalStyled';

const DeleteButton = styled.div`
  ${Flex};
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;
const DocumentContainer = styled.div`
  width: fit-content;
  &:hover {
    ${DeleteButton} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Wrap = styled.div`
  padding-inline: 56px;
  ${({ css }) => css}

  p {
    ${TextEllipsis({ width: '340px' })}
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ScrollContainer = styled.div`
  ${ScrollBar};
  overflow-y: scroll;
  height: 400px;
`;

export {
  DocumentContainer,
  DeleteButton,
  Wrap,
  ButtonContainer,
  ScrollContainer,
};
