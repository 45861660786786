import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { HelperText } from 'components/Form/_shared/styled';
import { IconUpload } from 'components/Icons';
import { Spacer, Typography } from 'components/App/GlobalStyled';
import { Wrap, FieldInput, FieldWrap, Label } from './styled';

const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

// eslint-disable-next-line react/display-name
export const RFileUpload = forwardRef(
  (
    {
      name,
      label,
      fullWidth = false,
      placeholder,
      onFileLoad,
      disabled = false,
      additionalFileTypes = [],
      children,
      css,
    },
    ref
  ) => {
    const theme = useTheme();
    const inputRef = useRef();
    const [error, setError] = useState(null);

    const validateFile = (file) => {
      if (typeof file !== 'undefined') {
        if (
          ![...acceptedFileTypes, ...additionalFileTypes].includes(file.type)
        ) {
          return onFileSelectError({
            error: 'Wrong file type',
          });
        }
        if (file.size > 30000000) {
          return onFileSelectError({ error: 'Too large file' });
        }
        return onFileSelectSuccess(file, file.name);
      }

      return onFileSelectError({
        error: 'File load error',
      });
    };

    const handleFileInput = (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      validateFile(file);
    };

    const onFileSelectSuccess = async (file, name) => {
      let fileBase64;
      setError(null);
      const fileData = new FileReader();
      fileData.onloadend = () => {
        fileBase64 = fileData.result;
        onFileLoad(file, fileBase64, name);
      };
      fileData.readAsDataURL(file);
    };

    const onFileSelectError = ({ error }) => {
      setError(error);
    };

    useImperativeHandle(ref, () => ({
      cbError(error) {
        onFileSelectError(error);
      },
      clear() {
        inputRef.current.value = null;
      },
    }));

    return (
      <Wrap
        fullWidth={fullWidth}
        withLabel={label}
      >
        <FieldWrap>
          <FieldInput
            ref={inputRef}
            id={name}
            name={name}
            type="file"
            accept={[...acceptedFileTypes, ...additionalFileTypes].join(', ')}
            error={error}
            placeholder={placeholder}
            onChange={!disabled ? handleFileInput : undefined}
            disabled={disabled}
          />
          <Label
            htmlFor={name}
            error={error}
            css={css}
          >
            {!children ? (
              <>
                <IconUpload />
                <Spacer m="0 0 10px 0" />
                <Typography color={theme.concord}>{label}</Typography>
              </>
            ) : (
              children
            )}
          </Label>
        </FieldWrap>
        <HelperText>{error}</HelperText>
      </Wrap>
    );
  }
);

RFileUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  onFileLoad: PropTypes.func,
  additionalFileTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  css: PropTypes.object,
};
