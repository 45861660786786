import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';

import { IconTick } from 'components/Icons';
import { RButton } from 'components/Form/RButton';
import { projectDetails } from 'store/manager/projectDetails';
import { projectStatuses, status as statuses, statusSteps } from 'utils/const';
import { invoicesPage } from 'store/manager/invoicesPage';
import { Wrapper } from './styled';
import { AppointmentForm } from './AppointmentForm';
import { InvoiceForm } from './InvoiceForm';
import { AppointmentIfo } from './AppointmentIfo';
import { InvoiceInfo } from './InvoiceInfo';

export function ProjectSteps() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const btnSubmitAppointment = useRef();
  const btnSubmitInvoice = useRef();

  const invoiceCreatingStatus = useSelector(
    invoicesPage.selectors.invoiceCreatingStatus
  );

  const [activeStepIndx, setActiveStepIndx] = useState(0);
  const status = useSelector(projectDetails.selectors.statusSteps);
  const project = useSelector(projectDetails.selectors.project);
  const invoice = useSelector(projectDetails.selectors.invoice);

  const changeStatus = (status) => {
    dispatch(
      projectDetails.thunks.updateStatusProject({
        projectId,
        project: { ...project, track: status },
      })
    );
  };

  const stepsFunc = {
    0: () => changeStatus('Vendor search'),
    1: () => btnSubmitAppointment.current.click(),
    2: () => changeStatus('Job completed'),
    3: () => btnSubmitInvoice.current.click(),
    4: () => changeStatus('Completed'),
  };

  const saveBtnClick = () => {
    stepsFunc[activeStepIndx]();
  };

  const appointmentSteps = useCallback(() => {
    if (activeStepIndx === 1) {
      return (
        <AppointmentForm
          project={project}
          id={projectId}
          refBtn={btnSubmitAppointment}
        />
      );
    }

    if (project?.appointment) {
      return <AppointmentIfo />;
    }
    return null;
  }, [activeStepIndx, project]);

  const invoiceSteps = useCallback(() => {
    if (activeStepIndx === 3) {
      return (
        <InvoiceForm
          project={project}
          id={projectId}
          refBtn={btnSubmitInvoice}
        />
      );
    }
    if (invoice) {
      return <InvoiceInfo invoice={invoice} />;
    }
    return null;
  }, [invoice, activeStepIndx]);

  const icons = {
    finish: (
      <IconTick
        fill="#fff"
        size="20"
      />
    ),
    error: <IconTick />,
  };

  useEffect(() => {
    const index = statusSteps.findIndex((el) => el === status);
    // eslint-disable-next-line no-unused-expressions
    index !== -1 && setActiveStepIndx(index + 1);
  }, [status]);

  useEffect(() => {
    if (invoiceCreatingStatus === statuses.SUCCESS) {
      dispatch(invoicesPage.actions.RESET_STATUS('invoiceCreatingStatus'));
    }
  }, [invoiceCreatingStatus]);

  return (
    <Wrapper>
      <Steps
        size="small"
        direction="vertical"
        current={activeStepIndx}
        icons={icons}
      >
        <Step title="Vendor Search" />
        <Step
          title="Scheduling appointment"
          description={appointmentSteps()}
        />
        <Step title="Job completed" />
        <Step
          title="Invoice ready"
          description={invoiceSteps()}
        />
        <Step title="Completed" />
      </Steps>
      {status !== 'Completed' && (
        <RButton
          disabled={project.status === projectStatuses.canceled}
          css={{ width: '248px' }}
          type="submit"
          onClick={saveBtnClick}
        >
          Save
        </RButton>
      )}
    </Wrapper>
  );
}
