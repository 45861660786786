import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userPage = {
  getUserInfo(userId) {
    return http.get(
      WEB_API_ROUTES.manager.userPage.getUserInfo.replace('{_id}', userId)
    );
  },
  addBoat(formData) {
    return http.post(WEB_API_ROUTES.manager.usersPage.addBoat, formData);
  },
  editUser(formData, userId) {
    return http.put(
      WEB_API_ROUTES.manager.userPage.editUser.replace('{_id}', userId),
      formData
    );
  },
  getUserProjects(userId, params) {
    return http.get(
      WEB_API_ROUTES.manager.userPage.getUserProjects.replace('{_id}', userId),
      { params }
    );
  },
  getUserInvoices(userId, params) {
    return http.get(
      WEB_API_ROUTES.manager.userPage.getUserInvoices.replace('{_id}', userId),
      { params }
    );
  },
  getUserDocuments(userId, params) {
    return http.get(
      WEB_API_ROUTES.manager.userPage.getUserDocuments.replace(
        '{_user}',
        userId
      ),
      { params }
    );
  },
  uploadDocuments(userId, files) {
    return http.post(
      WEB_API_ROUTES.manager.userPage.uploadDocuments.replace(
        '{_user}',
        userId
      ),
      { files }
    );
  },
  deleteDocument(docId) {
    return http.delete(
      WEB_API_ROUTES.manager.userPage.deleteDocument.replace(
        '{_documentId}',
        docId
      )
    );
  },
};
