import styled from 'styled-components';

import { font } from 'utils/const';
import { Text, TextEllipsis } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 40% 60%;
  padding-inline: 56px;
  ${Text({ size: font.lg })}
  ${({ css }) => css}

  p {
    ${TextEllipsis({ width: '340px' })}
  }
`;

export { Wrap };
