import { MemoizedTextEllipsesCell } from 'components/RTable/SharedCells/TextEllipsesCell';
import { MemoizedPhoneCell } from 'components/RTable/SharedCells/PhoneCell';

export const columns = [
  {
    Header: 'First Name',
    accessor: 'firstname',
    width: 200,
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Last name',
    accessor: 'lastname',
    width: 200,
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Phone number',
    accessor: 'phone',
    width: 200,
    Cell: MemoizedPhoneCell,
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 400,
    Cell: MemoizedTextEllipsesCell,
  },
];
