import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { RTextField } from 'components/Form/RTextField';
import { RTextMaskField } from 'components/Form/RTextMaskField';
import { RCalendarWithTime } from 'components/Form/RCalendarWithTime';
import { RInputTimePicker } from 'components/Form/RInputTimePicker';
import { projectDetails } from 'store/manager/projectDetails';
import { prepareDateToServer } from 'utils/prepareDate';
import { hour, minute } from 'utils/formats';
import { Typography } from 'components/App/GlobalStyled';
import { font, projectTrack } from 'utils/const';
import { getHour, getMinute } from 'utils/prepare';
import { defaultValues, schema } from './config';
import { Form, Btn } from './styled';

AppointmentForm.propTypes = {
  refBtn: PropTypes.any,
  id: PropTypes.string,
  project: PropTypes.object,
};

export function AppointmentForm({ refBtn, id, project }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const editMode = useSelector(projectDetails.selectors.editMode);

  const minutesToDate = (sum) => {
    const date = new Date();
    const hrs = getHour(sum);
    const min = getMinute(sum);

    date.setHours(hrs, min);

    return date;
  };

  const preparedDefaultValues = useMemo(() => {
    if (editMode) {
      const { cost, name, onTime, serviceProvider, duration } =
        project.appointment;

      const formattedOnTime = new Date(onTime * 1000);

      return {
        name,
        serviceProvider,
        cost: cost === 0 ? '' : cost / 100,
        duration: minutesToDate(duration),
        onTime: formattedOnTime,
      };
    }
    return { ...defaultValues };
  }, [editMode]);

  const { control, handleSubmit } = useForm({
    defaultValues: preparedDefaultValues,
    resolver: yupResolver(schema),
  });

  const onCancelEdit = () => {
    dispatch(projectDetails.actions.SET_EDIT_MODE(false));
    dispatch(projectDetails.actions.SET_STATUS_STEP(projectTrack.scheduling));
  };

  const onSubmit = async (data) => {
    const { onTime, duration, cost } = data;
    const hourDur = +format(new Date(duration), hour) * 60;
    const minuteDur = +format(new Date(duration), minute);
    const parsedCost = parseInt(+cost * 100, 10);
    data.onTime = prepareDateToServer(new Date(onTime));
    data.duration = hourDur + minuteDur;
    if (!data.serviceProvider) data.serviceProvider = null;

    dispatch(
      await projectDetails.thunks.updateStatusProject({
        projectId: id,
        project: { ...project, track: projectTrack.scheduling },
      })
    );

    if (editMode) {
      await dispatch(
        projectDetails.thunks.editAppointment({
          id,
          appointment: {
            ...data,
            cost: parsedCost,
            appointmentId: project.appointment._id,
          },
        })
      );
      dispatch(projectDetails.actions.SET_EDIT_MODE(false));
    } else {
      await dispatch(
        projectDetails.thunks.createAppointment({
          id,
          appointment: { ...data, cost: parsedCost },
        })
      );
    }

    dispatch(projectDetails.thunks.getProjectDetails(id));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RTextField
          label="Appointment name"
          control={control}
          name="name"
        />
        <RCalendarWithTime
          disabled
          label="Date"
          control={control}
          name="onTime"
        />
        <RInputTimePicker
          disabled
          label="Appointment duration"
          control={control}
          name="duration"
        />
        <RTextField
          label="Service provider"
          control={control}
          name="serviceProvider"
        />
        <RTextMaskField
          prefix="$ "
          label="Est. cost"
          control={control}
          name="cost"
        />
        <Btn
          ref={refBtn}
          type="submit"
        />
      </Form>
      {editMode ? (
        <Typography
          fz={font.xs}
          fw={500}
          color={theme.blueDark}
          cursor="pointer"
          onClick={onCancelEdit}
        >
          Cancel changes
        </Typography>
      ) : null}
    </>
  );
}
