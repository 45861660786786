import PropTypes from 'prop-types';
import { memo } from 'react';
import { TextEllipsed } from 'components/App/GlobalStyled';

TextEllipsesCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.any,
    column: PropTypes.shape({
      width: PropTypes.number,
    }),
  }),
};

function TextEllipsesCell({ cell: { value, column } }) {
  return <TextEllipsed width={column.width}>{value}</TextEllipsed>;
}

export const MemoizedTextEllipsesCell = memo(TextEllipsesCell);
