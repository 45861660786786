import styled, { css } from 'styled-components';

const Button = styled.button`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ height }) => `${height}px`};
  min-width: 160px;
  padding: 12px 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 8px 8px 24px 0 rgba(183, 183, 183, 0.04);
  transition: all 0.3s ease-out;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'outline':
        return css`
          color: ${theme.como};
          background-color: transparent;
          border-color: 1px solid ${theme.como};
        `;
      default:
        return css`
          color: ${theme.white};
          background-color: ${theme.como};
          border-color: ${theme.como};

          &:hover {
            background-color: ${theme.spanishGreen};
            border-color: ${theme.spanishGreen};
          }

          &:active {
            background-color: ${theme.lunarGreen};
            border-color: ${theme.lunarGreen};
          }
        `;
    }
  }}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.grey};
      background-color: ${theme.gainsboro};
      border-color: ${theme.gainsboro};
      pointer-events: none;
      cursor: not-allowed;
    `}
  ${({ css }) => css}
`;

const ButtonChildren = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const PrependIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export { Button, ButtonChildren, PrependIcon };
