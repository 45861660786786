import yup from 'plugins/yup-config';
import { transformNaN } from 'utils/prepare';

const defaultValues = {
  projectTitle: '',
  firstname: '',
  lastname: '',
  amount: '',
  creationDate: new Date(),
  description: '',
};

const schema = yup.object().shape({
  projectTitle: yup.string().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  amount: yup.number().nullable().required().transform(transformNaN),
  creationDate: yup.string().required(),
  description: yup.string().required(),
});

export { schema, defaultValues };
