export const projectModel = (
  _id = '',
  userId = '',
  title = '',
  description = '',
  urgency = '',
  status = '',
  track = '',
  serviceProvider = '',
  category = '',
  cost = 0,
  photos = [],
  files = [],
  createdAt = '',
  updatedAt = '',
  appointment = ''
) => {
  return {
    _id,
    userId,
    title,
    description,
    urgency,
    status,
    track,
    serviceProvider,
    category,
    cost,
    photos,
    files,
    createdAt,
    updatedAt,
    appointment,
  };
};
