const selectors = {
  data: (state) => ({
    boat: state.boatPage.boat,
    profile: state.boatPage.profile,
  }),
  fetchingStatus: (state) => state.boatPage.fetchingStatus,
  editBoatStatus: (state) => state.boatPage.editBoatStatus,
};

export { selectors };
