import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  padding-block: 35px;
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: 25% 75%;
  padding-inline: 48px;
`;

const ImageContainer = styled.div`
  ${Flex({ justify: 'center' })};
  width: 600px;
  height: 500px;
`;

const BoatImage = styled.img`
  object-fit: contain;
  height: auto;
  width: 450px;
  max-width: 100%;
  max-height: 100%;
`;

const InfoContainer = styled.div``;

export { Wrap, Content, ImageContainer, BoatImage, InfoContainer };
