import styled from 'styled-components';

const Wrap = styled.div`
  padding-top: 35px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 8px;
`;

const Flex = styled.div`
  display: flex;
  padding: 0 48px 64px 48px;
`;

export { Wrap, Title, Flex };
