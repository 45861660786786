import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useState } from 'react';

const theme = {
  LIGHT: {
    error: '#F44336',
    white: '#FFFFFF',
    black: '#000000',
    mistBlue: '#636C79',
    greyCloud: '#B3B4BB',
    concord: '#7C7C7C',
    como: '#587365',
    spanishGreen: '#799C8A',
    lunarGreen: '#3B4D43',
    lightGrey: '#DFDFDF',
    milkWhite: '#FBFBFB',
    heavyMetal: '#2D2D2D',
    dawnPink: '#ECECEC',
    vistaWhite: '#F9F9F9',
    greyGoose: '#CFCFCF',
    grey: '#929292',
    gainsboro: '#DCDCDC',
    metallicSilver: '#C4C4C4',
    granite: '#808080',
    chatContrast: '#f3f5f99c',
    sendActive: '#AAAEB8',
    grayTitle: '#6F6F6F',
    opacityTitle: '#D4D4D4',
    calendarText: '#121212',
    freeDay: '#f36a6a',
    dataPickerDay: '#858585',

    // old
    green: '#e7ffea',
    greenDark: '#30b756',
    blue: '#e2f1ff',
    blueDark: '#2f80ed',
    background:
      'radial-gradient(circle, rgba(244,247,255,1) 0%, rgba(225,226,255,1) 100%)',
    gray: {
      1: '#f2f2f2',
      2: '#e4e4e4',
      3: '#d7d7d7',
      4: '#c9c9c9',
      5: '#aeaeae',
      6: '#939393',
      7: '#787878',
      8: '#606060',
      9: '#484848',
    },

    table: {
      hover: '#f3f5f9',
      headerColor: '#606060',
      rowColor: '#000000',
    },

    font: {
      primary: '#484848',
      title: '#303030',
    },

    field: {
      labelColor: '#939393',
      labelBgColor: '#FFFFFF',
      textColor: '#474747',
      bgColor: '#FFFFFF',
      borderColor: '#C4C4C4',
    },

    avatar: {
      red: '#E83535',
      online: '#FFFFFF',
      alternative: '#587365',
      turquoise: '#009F9F',
    },
  },
  DARK: {},
};

Theme.propTypes = {
  children: PropTypes.node,
};

function Theme({ children }) {
  const [mode] = useState('LIGHT');
  return <ThemeProvider theme={theme[mode]}>{children}</ThemeProvider>;
}

export default Theme;
