import styled from 'styled-components';
import { TextEllipsis } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
  color: ${({ theme }) => theme.como};
`;

const Image = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 5px;
`;

const FileName = styled.a`
  ${({ width }) => TextEllipsis({ width: `${width}px` })}
`;

export { Wrap, Image, FileName };
