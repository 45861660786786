import PropTypes from 'prop-types';
import { createContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedRoute from 'middleware/ProtectedRoute';
import NotFoundPage from 'pages/General/NotFound';
import ManagersPage from 'pages/Admin/ManagersPage';
import UsersPage from 'pages/Admin/UsersPage';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { AppIsCrashed } from 'components/AppIsCrashed';
import { RSidebar } from 'components/RSidebar';
import { adminSidebarLinks, status } from 'utils/const';
import { appRoles } from 'utils/roles';
import { auth } from 'store/auth';
import { Layout, Main, Content } from './styled';

export const ManagerLayoutContext = createContext({});

function AdminLayout() {
  const mainRef = useRef();

  const loginStatus = useSelector(auth.selectors.loginStatus);

  const contextValue = useMemo(() => ({ mainRef }), [mainRef]);

  const isAppReady = loginStatus === status.SUCCESS;

  const isAppFail = loginStatus === status.FAIL;

  return (
    <ProtectedRoute>
      <RoleChecker>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isAppReady ? (
          <Layout>
            <RSidebar
              withNotifications={false}
              links={adminSidebarLinks}
            />
            <ManagerLayoutContext.Provider value={contextValue}>
              <Main ref={mainRef}>
                <div id="modal-portal" />
                <Content>
                  <Switch>
                    {/* Users */}
                    <Route
                      exact
                      path="/admin/users"
                      component={UsersPage}
                    />

                    {/* Managers */}
                    <Route
                      exact
                      path="/admin/managers"
                      component={ManagersPage}
                    />
                    <Route component={NotFoundPage} />
                  </Switch>
                </Content>
              </Main>
            </ManagerLayoutContext.Provider>
          </Layout>
        ) : !isAppFail ? (
          <RLoadingOverlay isVisible />
        ) : (
          <AppIsCrashed />
        )}
      </RoleChecker>
    </ProtectedRoute>
  );
}

RoleChecker.propTypes = {
  children: PropTypes.node,
};

function RoleChecker({ children }) {
  const role = useSelector(auth.selectors.role);
  if (role !== appRoles.ADMIN) return <Redirect to="/" />;
  return children;
}

export default AdminLayout;
