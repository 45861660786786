import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Spacer, TextEllipsed, Typography } from 'components/App/GlobalStyled';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RModalPortal } from 'components/RModalPortal';
import { RButton } from 'components/Form/RButton';
import { RPopup } from 'components/RPopup';
import { RMask } from 'components/RMask';
import { userPage } from 'store/manager/userPage';
import { status } from 'utils/const';
import { Footer } from './styled';

ConfirmDeletePopup.propTypes = {
  document: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export function ConfirmDeletePopup({ document, onClose = () => {} }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const deleteDocumentStatus = useSelector(
    userPage.selectors.deleteDocumentStatus
  );

  const onDelete = () => {
    dispatch(userPage.thunks.deleteDocument({ docId: document._id }));
  };

  useEffect(() => {
    if (deleteDocumentStatus === status.SUCCESS) {
      onClose();
      dispatch(userPage.thunks.getUserDocuments({ userId }));
      dispatch(userPage.actions.RESET_STATUS('deleteDocumentStatus'));
    }
  }, [deleteDocumentStatus]);

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Delete file"
        >
          <RLoadingOverlay
            isVisible={deleteDocumentStatus === status.PENDING}
          />
          <>
            Are you sure you want to delete the file <br />
            <Typography color={theme.como}>
              <TextEllipsed width={350}>"{document.name}"?</TextEllipsed>
            </Typography>
            <Footer>
              <RButton onClick={onDelete}>Delete</RButton>
              <Spacer m="0 8px" />
              <RButton
                onClick={onClose}
                variant="outline"
              >
                Cancel
              </RButton>
            </Footer>
          </>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
