import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import { RTextField } from 'components/Form/RTextField';
import { RTextMaskField } from 'components/Form/RTextMaskField';
import { projectDetails } from 'store/manager/projectDetails';
import { defaultValues, schema } from './config';
import { Form, Btn } from './styled';

InvoiceForm.propTypes = {
  refBtn: PropTypes.any,
  id: PropTypes.string,
  project: PropTypes.object,
};

export function InvoiceForm({ refBtn, id, project }) {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ amount, description }) => {
    dispatch(
      projectDetails.thunks.updateStatusProject({
        projectId: id,
        project: { ...project, track: 'Invoice ready' },
      })
    );

    if (+amount !== 0) {
      dispatch(
        projectDetails.thunks.createInvoice({
          id,
          invoice: { amount: parseInt(+amount * 100, 10), description },
        })
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RTextMaskField
        prefix="$ "
        label="Invoice total"
        control={control}
        name="amount"
      />
      <RTextField
        label="Description"
        control={control}
        name="description"
      />
      <Btn
        ref={refBtn}
        type="submit"
      />
    </Form>
  );
}
