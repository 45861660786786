import { useSelector } from 'react-redux';

import { AddBoatPopup } from 'pages/Manager/UserPage/Boats/AddBoatPopup';
import { RButton } from 'components/Form/RButton';
import { useToggle } from 'hooks/useToggle';
import { userPage } from 'store/manager/userPage';
import { Table } from './Table';
import { ButtonContainer } from './styled';

export function UserBoats() {
  const { boats } = useSelector(userPage.selectors.data);
  const [isAddBoatPopupOpen, setIsAddBoatPopupOpen] = useToggle();

  return (
    <>
      <ButtonContainer>
        <RButton
          onClick={setIsAddBoatPopupOpen}
          disabled={boats.length > 0}
        >
          Add Boat
        </RButton>
      </ButtonContainer>
      <Table />
      {isAddBoatPopupOpen && <AddBoatPopup onClose={setIsAddBoatPopupOpen} />}
    </>
  );
}
