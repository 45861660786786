import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { userNotifications } from 'store/notifications';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useWindowSize } from 'hooks/useWindowSize';
import { RListIsEmpty } from 'components/RListIsEmpty';
import { RButton } from 'components/Form/RButton';
import { RMask } from 'components/RMask';
import { notificationTypes } from 'utils/const';
import { Notification } from './Notification';
import { Main, Wrap } from './styled';

Notifications.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

export function Notifications({ isVisible, onClose }) {
  const ref = useRef();
  const { height } = useWindowSize();
  const dispatch = useDispatch();
  const list = useSelector(userNotifications.selectors.list);

  const filteredList = useMemo(() => {
    return list.filter(
      (item) =>
        item.type !== notificationTypes.CHAT &&
        item.type !== notificationTypes.CHAT_REACTION
    );
  }, [list]);

  const onClearAll = () => {
    dispatch(userNotifications.thunks.deleteAllNotifications());
  };

  useOutsideClick(ref, () => {
    onClose();
  });

  return (
    <>
      <RMask isVisible={isVisible} />
      <Main
        ref={ref}
        isVisible={isVisible}
      >
        <Wrap
          windowHeight={height}
          className="custom-scroll"
        >
          {filteredList.length ? (
            filteredList.map((notification, i) => (
              <Notification
                key={notification._id || `${notification.type}-${i}`}
                notification={notification}
                onClose={onClose}
              />
            ))
          ) : (
            <RListIsEmpty />
          )}
        </Wrap>

        {filteredList.length > 1 && (
          <RButton
            fullWidth
            onClick={onClearAll}
          >
            Clear All
          </RButton>
        )}
      </Main>
    </>
  );
}
