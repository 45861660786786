import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { PageTitle, Header, BreadCrumbs } from 'pages/_shared/styled';
import { invoicePage } from 'store/manager/invoicePage';
import { transformNumber } from 'utils/prepare';
import { Content, Wrap } from './styled';

function InvoiceDetailsPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const {
    invoice: { amount, description, status },
    profile: { firstname, lastname },
    project: { title },
  } = useSelector(invoicePage.selectors.data);
  const fetchingStatus = useSelector(invoicePage.selectors.fetchingStatus);
  const loading = fetchingStatus === status.PENDING;

  const goToInvoices = () => {
    history.push('/manager/invoices');
  };

  const fetchData = () => {
    dispatch(invoicePage.thunks.getInvoiceDetails({ invoiceId }));
  };

  useEffect(() => {
    fetchData();
  }, [invoiceId]);

  return (
    <>
      <Wrap>
        <RLoadingOverlay isVisible={loading} />
        <Header>
          <div>
            <PageTitle>Invoice details</PageTitle>
            <BreadCrumbs onClick={goToInvoices}>
              Invoices&nbsp;&frasl;&nbsp;
              <strong>{title}</strong>
            </BreadCrumbs>
          </div>
        </Header>
        <Content css={{ maxWidth: '640px' }}>
          <p>Project name:</p>
          <p>{title}</p>
          <p>Boat Owner:</p>
          <p>
            {firstname}&nbsp;
            {lastname}
          </p>
          <p>Status:</p>
          <p>{status}</p>
          <p>Invoice total:</p>
          <p>${transformNumber(amount / 100)}</p>
          <p>Description:</p>
          <p>{description}</p>
        </Content>
      </Wrap>
    </>
  );
}

export default InvoiceDetailsPage;
