import styled from 'styled-components/macro';
import { css } from 'styled-components';

const Main = styled.div`
  position: relative;
  width: 100%;
  ${({ css }) => css}
`;

const THeader = styled.div`
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey}; ;
`;

const TCell = styled.div`
  background-color: ${({ theme }) => theme.white};
`;

const THead = styled.div`
  font-weight: 500;
  z-index: 5;
`;

const THeaderRow = styled.div`
  min-width: 100%;
`;

const Table = styled.div`
  position: relative;
  color: ${({ theme }) => theme.heavyMetal};

  ${THeader}, ${TCell} {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 16px 18px;
    overflow: hidden;
    background-color: transparent;

    &:first-of-type {
      padding-left: 48px;
    }

    &:last-of-type {
      padding-right: 56px;
    }
  }
`;

const TScroll = styled.div`
  overflow: auto;
  ${({ height }) =>
    css`
      height: ${height}px;
    `}
`;

const TRow = styled.div`
  min-width: 100%;
  transition: background-color 0.2s ease-in-out;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  &:hover {
    background-color: ${({ theme }) => theme.milkWhite};
  }
`;

const TBody = styled.div`
  width: 100%;
`;

const EmptyText = styled.div`
  color: ${({ theme }) => theme.heavyMetal};
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
`;

export {
  Main,
  Table,
  TScroll,
  TRow,
  TCell,
  THeader,
  THeaderRow,
  THead,
  TBody,
  EmptyText,
};
