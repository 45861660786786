import styled from 'styled-components';

import { device } from 'utils/device';
import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  position: relative;
  padding-block: 20px;
`;

const Content = styled.div`
  margin-left: auto;
  min-width: 80px;
  max-width: 40%;
  padding: 10px;
  width: fit-content;
  background-color: #f3f5f9cf;
  border: 0.5px solid #eaeaea;
  border-radius: 10px 10px 0 10px;

  @media (${device.md}) {
    max-width: 90%;
  }
`;

const TextBlock = styled.div`
  ${Flex};
  flex-wrap: wrap;

  ${({ css }) => css}
`;

const Footer = styled.div`
  ${Flex};
  justify-content: ${({ reaction }) =>
    reaction ? 'space-between' : 'flex-end'};
  margin-top: 5px;
`;

const FileWrap = styled.div`
  ${Flex};
  padding-block: 5px;
`;

export { Wrap, Content, TextBlock, Footer, FileWrap };
