import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import history from 'utils/history';
import { handleServerErrors } from 'utils/serverErrors';
import { selectors } from 'store/chats/room/selectors';
import { getManagerId } from 'utils/prepare';
import { chats } from '../index';

const getMessages = createAsyncThunk(
  'chat/getMessages',
  async ({ params, roomId }) => {
    try {
      const res = await api.chats.getMessages({
        roomId,
        ...params,
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const loadOldMessages = createAsyncThunk(
  'user/loadOldMessages',
  async (params, { getState }) => {
    try {
      const { page, perPage } = selectors.listOldMsgPag(getState());
      const roomId = selectors.currentRoomId(getState());

      const res = await api.chats.loadOldMessages({
        page,
        perPage,
        roomId,
        ...params,
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (message, { getState, dispatch }) => {
    try {
      const roomId = selectors.currentRoomId(getState());
      const {
        auth: { chatSocketId: socketId },
      } = getState();

      const res = await api.chats.sendMessage({
        socketId,
        roomId,
        ...message,
      });

      if (!roomId) {
        dispatch(chats.actions.RESET_STATE());
        dispatch(chats.thunks.getGeneralChats());
        history.push(`/manager/inbox/${res.data.chatId}`);
      }

      return { ...res.data, ...message };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const markAsRead = createAsyncThunk(
  'chat/markAsRead',
  async (messagesIds, { getState }) => {
    try {
      const roomId = selectors.currentRoomId(getState());
      await api.chats.markAsRead({ roomId, messagesIds });

      return { messagesIds, roomId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const fileUpload = createAsyncThunk('chat/fileUpload', async (file) => {
  try {
    return await api.chats.fileUpload(file);
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getChatRoomById = createAsyncThunk(
  'chat/getChatRoomById',
  async ({ chatId }) => {
    const managerId = getManagerId();
    try {
      const res = await api.chats.getChatRoomById(chatId);

      return { ...res.data, managerId };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteOwnMessage = createAsyncThunk(
  'chat/deleteOwnMessage',
  async ({ messageId, params }, { dispatch }) => {
    try {
      const { data } = await api.chats.deleteOwnMessage(messageId, params);
      dispatch(chats.actions.UPDATE_ROOM_ON_DELETE_MESSAGE(data));
      toast.success('Message successfully deleted');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const reactOnMessage = createAsyncThunk(
  'chat/reactOnMessage',
  async ({ messageId, params }, { getState }) => {
    const {
      auth: { chatSocketId: socketId },
    } = getState();

    try {
      const { data } = await api.chats.reactOnMessage(messageId, {
        socketId,
        ...params,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const takeReactionBack = createAsyncThunk(
  'chat/takeReactionBack',
  async ({ messageId, roomId }, { getState }) => {
    const {
      auth: { chatSocketId: socketId },
    } = getState();

    try {
      const { data } = await api.chats.reactOnMessage(messageId, {
        socketId,
        roomId,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const markReactionsAsRead = createAsyncThunk(
  'chat/markReactionsAsRead',
  async ({ roomId, messagesIds }, { dispatch }) => {
    try {
      const { data } = await api.chats.markReactionsAsRead({
        roomId,
        messagesIds,
      });
      dispatch(
        chats.actions.UPDATE_UNREAD_REACTIONS({
          roomId,
          unreadReactionsCount: data.UnreadReactionsCount,
        })
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getMessages,
  loadOldMessages,
  sendMessage,
  markAsRead,
  fileUpload,
  getChatRoomById,
  deleteOwnMessage,
  reactOnMessage,
  takeReactionBack,
  markReactionsAsRead,
};

export { thunks };
