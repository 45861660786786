import styled, { css } from 'styled-components';

import { Circle, Flex, TextEllipsis } from 'components/App/GlobalStyled';
import IconHeart from 'assets/img/global/icon-heart.png';
import { font } from 'utils/const';
import { OnlineCircle } from 'components/RAvatar/styled';

const Date = styled.span`
  font-size: ${font.sm};
  color: rgba(84, 84, 75, 0.5);
  margin-bottom: 8px;
`;

const MessageText = styled.span`
  ${TextEllipsis({ width: '190px' })};
  color: ${({ theme }) => theme.concord};
  transition: 0.3s;

  ${({ css }) => css}
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.white};
    `}
`;

const Wrap = styled.div`
  --content-offset: 10px;
  position: relative;
  display: grid;
  min-height: 10.5%;
  grid-template-columns: auto 1fr auto;
  padding: 20px var(--content-offset);
  cursor: pointer;
  transition: 0.3s;

  &:before {
    content: '';
    position: absolute;
    left: var(--content-offset);
    right: var(--content-offset);
    top: 100%;
    height: 1px;
    background: #e7e7e7;
  }

  &:hover {
    ${Date} {
      color: ${({ theme }) => theme.white};
    }

    ${MessageText} {
      color: ${({ theme }) => theme.white};
    }

    ${OnlineCircle} {
      background-color: ${({ theme }) => theme.avatar.online};
    }

    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.spanishGreen};
  }

  ${({ active, theme }) =>
    active &&
    css`
      ${Date} {
        color: ${theme.white};
      }

      ${OnlineCircle} {
        background-color: ${({ theme }) => theme.avatar.online};
      }
      color: ${theme.white};
      background-color: ${theme.como};
    `}
`;

const Name = styled.span`
  ${TextEllipsis({ width: '190px' })};
  font-weight: 500;
`;

const AvatarContainer = styled.div`
  margin-right: 15px;
`;

const Content = styled.div`
  ${Flex({
    align: 'flex-start',
    direction: 'column',
    justify: 'space-between',
  })}
`;

const Aside = styled.div`
  ${Flex({ direction: 'column', align: 'flex-end', justify: 'flex-end' })}
`;

const UnreadCount = styled.span`
  ${Circle({ size: '24px' })}
  color: ${({ theme }) => theme.white};
  font-size: 10px;
  background-color: ${({ theme }) => theme.como};
`;

const UnreadReactions = styled.span`
  ${Circle({ size: '24px' })}
  font-size: 14px;
  background: url(${IconHeart}) no-repeat center / 14px;
  background-color: ${({ theme }) => theme.como};
`;

export {
  Wrap,
  Name,
  AvatarContainer,
  Content,
  MessageText,
  Aside,
  Date,
  UnreadCount,
  UnreadReactions,
};
