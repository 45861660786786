import { memo } from 'react';
import { format } from 'date-fns';
import { appDateFormat } from 'utils/formats';
import PropTypes from 'prop-types';

DateCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.string,
  }),
};

function DateCell({ cell, row }) {
  return `${format(
    new Date(
      row.original.invoice.creationDate
        ? row.original.invoice.creationDate * 1000
        : cell.value
    ),
    appDateFormat
  )}`;
}

export const MemoizedDateCell = memo(DateCell);
