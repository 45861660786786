import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { RAvatar } from 'components/RAvatar';
import { chatsRoom } from 'store/chats/room';
import { chatTypes } from 'utils/const';
import { FlexWrap, Spacer, Typography } from 'components/App/GlobalStyled';
import { Wrap, Status } from './styled';

export function Heading() {
  const history = useHistory();
  const { chatType } = useParams();
  const project = useSelector(chatsRoom.selectors.project);
  const roomUserProfile = useSelector(chatsRoom.selectors.profile);

  const userStatus = roomUserProfile.isOnline ? 'online' : 'offline';

  const goToProject = () => {
    history.push(`/manager/projects/${project._id}`);
  };

  return (
    <Wrap>
      <FlexWrap>
        <RAvatar
          size={65}
          bgColor={roomUserProfile.roomColor}
          name={`${roomUserProfile.firstname} ${roomUserProfile.lastname}`}
        />
        <Spacer p="0 0 0 10px" />
        <div>
          <div>
            {roomUserProfile.firstname}&nbsp;{roomUserProfile.lastname}
          </div>
          <Status status={userStatus}>{userStatus}</Status>
        </div>
      </FlexWrap>
      {chatType === chatTypes.project ? (
        <Typography
          fw={500}
          cursor="pointer"
          onClick={goToProject}
        >
          Go to project
        </Typography>
      ) : null}
    </Wrap>
  );
}
