import styled from 'styled-components';
import { Circle } from 'components/App/GlobalStyled';

const Main = styled.div``;

const Wrap = styled.div`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ bgColor }) =>
    bgColor.includes('#') ? `${bgColor}` : `#${bgColor}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #808080;
  border-radius: 50%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const Name = styled.div`
  font-size: ${({ size }) => `${size / 2.5}px`};
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
`;

const OnlineCircle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  ${Circle({ size: '17px' })};
  border: 0.5px solid ${({ theme }) => theme.como};
  background-color: ${({ theme }) => theme.avatar.alternative};
  transition: 0.3s;
`;

export { Main, Wrap, Image, Name, OnlineCircle };
