import { css } from 'styled-components';

export const Pagination = css`
  .rc-pagination {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &::after {
      display: block;
      clear: both;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      content: ' ';
    }
  }

  .rc-pagination-total-text {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
    line-height: 26px;
    vertical-align: middle;
  }

  .rc-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 1px;
    padding: 0 4px;
    line-height: 1;
    list-style: none;
    outline: 0;
    cursor: pointer;
    user-select: none;
    color: ${({ theme }) => theme.heavyMetal};

    &:hover {
      background-color: ${({ theme }) => theme.dawnPink};
      transition: all 0.3s;
    }
  }

  .rc-pagination-item-active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.como};
    border-color: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.spanishGreen};
      transition: all 0.3s;
    }
  }

  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 1px;
    padding: 0 4px;
    line-height: 1;
    list-style: none;
    outline: 0;
    cursor: pointer;
    user-select: none;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: #666;

      &:after {
        display: block;
        content: '•••';
      }
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 1px;
    line-height: 1;
    list-style: none;
    outline: 0;
    cursor: pointer;
    user-select: none;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.dawnPink};
      transition: all 0.3s;
    }

    .rc-pagination-item-link {
      display: block;
      font-size: 16px;
      outline: none;
      transition: all 0.3s;
    }

    &:focus {
      .rc-pagination-item-link {
        color: #1890ff;
        border-color: #1890ff;
      }
    }
  }

  .rc-pagination-prev {
    margin-right: 16px;
  }

  .rc-pagination-next {
    margin-left: 16px;
  }

  .rc-pagination-disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.vistaWhite};

    svg {
      fill: ${({ theme }) => theme.greyGoose};
    }

    &:hover {
      cursor: not-allowed;

      .rc-pagination-item-link {
        color: #f3f5f9;
        border-color: #d9d9d9;
        cursor: not-allowed;
      }
    }

    &:focus {
      cursor: not-allowed;

      .rc-pagination-item-link {
        color: #f3f5f9;
        border-color: #d9d9d9;
        cursor: not-allowed;
      }
    }

    .rc-pagination-item-link {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor: not-allowed;
    }
  }

  .rc-pagination-slash {
    margin: 0 10px 0 5px;
  }

  .rc-pagination-options {
    display: inline-block;
    vertical-align: middle;
  }

  .rc-pagination-options-size-changer.rc-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }

  .rc-pagination-options-quick-jumper {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    vertical-align: top;

    input {
      width: 50px;
      margin: 0 8px;
    }
  }

  .rc-pagination-simple {
    .rc-pagination-prev {
      height: 24px;
      line-height: 24px;
      vertical-align: top;

      .rc-pagination-item-link {
        height: 24px;
        background-color: transparent;
        border: 0;

        &::after {
          height: 24px;
          line-height: 24px;
        }
      }
    }

    .rc-pagination-next {
      height: 24px;
      line-height: 24px;
      vertical-align: top;

      .rc-pagination-item-link {
        height: 24px;
        background-color: transparent;
        border: 0;

        &::after {
          height: 24px;
          line-height: 24px;
        }
      }
    }

    .rc-pagination-simple-pager {
      display: inline-block;
      height: 24px;
      margin-right: 8px;

      input {
        box-sizing: border-box;
        height: 100%;
        margin-right: 8px;
        padding: 0 6px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        outline: none;
        transition: border-color 0.3s;

        &:hover {
          border-color: #1890ff;
        }
      }
    }
  }

  .rc-pagination.rc-pagination-disabled {
    cursor: not-allowed;

    .rc-pagination-item {
      background: #f5f5f5;
      border-color: #d9d9d9;
      cursor: not-allowed;

      a {
        color: rgba(0, 0, 0, 0.25);
        background: transparent;
        border: none;
        cursor: not-allowed;
      }
    }

    .rc-pagination-item-active {
      background: #e2f1ff;
      border-color: transparent;
    }

    .rc-pagination-item-link {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      cursor: not-allowed;
    }

    .rc-pagination-item-link-icon {
      opacity: 0;
    }

    .rc-pagination-item-ellipsis {
      opacity: 1;
    }
  }

  .image-gallery-thumbnail {
    width: 72px;
    height: 72px;
    border: none !important;

    .image-gallery-thumbnail-image,
    .image-gallery-thumbnail-inner {
      height: 100%;
    }
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 16px;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 496px;
    min-height: 496px;
    margin-bottom: 16px;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    object-fit: cover;
  }

  @media all and (-ms-high-contrast: none) {
    .rc-pagination-options {
      * {
        &::-ms-backdrop {
          vertical-align: top;
        }
      }

      vertical-align: top;
    }
  }

  @media only screen and (max-width: 992px) {
    .rc-pagination-item-after-jump-prev {
      display: none;
    }
    .rc-pagination-item-before-jump-next {
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    .rc-pagination-options {
      display: none;
    }
  }
`;
