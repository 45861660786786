import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const chats = {
  getGeneralChats(params) {
    return http.get(WEB_API_ROUTES.chats.generalChats, {
      params,
    });
  },
  getProjectChats(params) {
    return http.get(WEB_API_ROUTES.chats.projectChats, {
      params,
    });
  },
  getMessages(params) {
    return http.get(WEB_API_ROUTES.chats.getMessages, {
      params,
    });
  },
  loadOldMessages(params) {
    return http.get(WEB_API_ROUTES.chats.loadOldMessages, {
      params,
    });
  },
  sendMessage(data) {
    return http.post(WEB_API_ROUTES.chats.sendMessage, data);
  },
  muteOrArchiveChat(roomId, data) {
    return http.patch(
      WEB_API_ROUTES.chats.muteOrArchiveChat.replace('{roomId}', roomId),
      data
    );
  },
  removeChat(roomId) {
    return http.delete(
      WEB_API_ROUTES.chats.removeChat.replace('{roomId}', roomId)
    );
  },
  markAsRead(data) {
    return http.post(WEB_API_ROUTES.chats.markAsRead, data);
  },
  fileUpload(data) {
    return http.post(WEB_API_ROUTES.chats.fileUpload, data);
  },
  getChatRoomById(chatId) {
    return http.get(
      WEB_API_ROUTES.chats.getChatRoomById.replace('{_id}', chatId)
    );
  },
  getChatUnreadCount() {
    return http.get(WEB_API_ROUTES.chats.getChatUnreadCount);
  },
  deleteOwnMessage(messageId, params) {
    return http.delete(
      WEB_API_ROUTES.chats.deleteOwnMessage.replace('{_id}', messageId),
      { params }
    );
  },
  reactOnMessage(messageId, params) {
    return http.post(
      WEB_API_ROUTES.chats.reactOnMessage.replace('{messageId}', messageId),
      params
    );
  },
  markReactionsAsRead(data) {
    return http.post(WEB_API_ROUTES.chats.markReactionsAsRead, data);
  },
};
