import styled from 'styled-components';
import { Flex } from 'components/App/GlobalStyled';

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ css }) => css}
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 16px;
`;

const Header = styled.div`
  ${Flex({ justify: 'space-between' })}
  margin-bottom: 30px;
  padding: 0 56px 0 48px;
`;

const Title = styled.div`
  display: flex;
  gap: 6px;
  font-weight: 600;
  line-height: 1.5;
`;

const PageTitle = styled.h1`
  font-weight: 500;
  line-height: 40px;
  font-size: 32px;
  margin-bottom: 8px;
`;

const PageDesc = styled.div`
  color: ${({ theme }) => theme.concord};
  ${({ css }) => css}
`;

const BreadCrumbs = styled.div`
  color: ${({ theme }) => theme.como};
  cursor: pointer;
  ${({ css }) => css}
`;

const PopupForm = styled.form`
  & > div {
    &:last-of-type {
      margin-bottom: 16px;
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
`;

export {
  Main,
  Wrap,
  Header,
  Title,
  PageTitle,
  PageDesc,
  BreadCrumbs,
  PopupForm,
};
