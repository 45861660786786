import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { memo } from 'react';

import { appDateFormat } from 'utils/formats';

CreateDateCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.string,
  }),
};

function CreateDateCell({ cell }) {
  return `${format(new Date(cell.value), appDateFormat)}`;
}

export const MemoizedCreateDateCell = memo(CreateDateCell);
