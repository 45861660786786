import styled from 'styled-components';

const Wrap = styled.div`
  grid-gap: 16px;
  display: grid;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.grayTitle};
  ${({ css }) => css}
`;

const Time = styled.span`
  margin-left: 16px;
`;

export { Text, Wrap, Time };
