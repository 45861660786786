import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import { RListIsEmpty } from 'components/RListIsEmpty';
import { ChatTabs } from 'components/ChatPage/Sidebar/Tabs';
import { Spacer } from 'components/App/GlobalStyled';
import { throttle } from 'utils/throttle';
import { chatTypes, projectChatTabs, status } from 'utils/const';
import { chats } from 'store/chats';
import { chatsRoom } from 'store/chats/room';
import { Chat } from './Chat';
import { Title } from './Title';
import { Wrap, Overlay, Content, Chats } from './styled';

export function Sidebar() {
  const history = useHistory();
  const sidebarRef = useRef();
  const { chatType, roomId } = useParams();
  const dispatch = useDispatch();

  const chatsList = useSelector(chats.selectors.list);
  const { general, project } = useSelector(chats.selectors.listPag);
  const chatsListStatus = useSelector(chats.selectors.listStatus);
  const activeProjectTab = useSelector(chats.selectors.activeProjectTab);
  const chatsListMoreStatus = useSelector(chats.selectors.chatsListMoreStatus);

  const getChats = async ({ chatType }) => {
    if (chatType === chatTypes.general) {
      await dispatch(chats.thunks.getGeneralChats());
    } else {
      await dispatch(chats.thunks.getProjectChats());
    }
  };

  const onSetActiveTab = (payload) => () => {
    dispatch(chats.actions.SET_PROJECT_CHAT(payload));
    dispatch(chatsRoom.actions.RESET_STATE());
    history.replace(`/manager/inbox/project`);
  };

  useEffect(() => {
    getChats({ chatType });
    return () => dispatch(chats.actions.RESET_PAGINATION());
  }, [chatType, activeProjectTab]);

  useEffect(() => {
    if (!roomId) dispatch(chatsRoom.actions.RESET_STATE());
  }, [roomId]);

  const loadMoreChats = () => {
    if (chatType === chatTypes.general) {
      return general.page > 1
        ? dispatch(chats.thunks.getMoreGeneralChats())
        : null;
    }
    return project[activeProjectTab].page > 1
      ? dispatch(chats.thunks.getMoreProjectChats())
      : null;
  };

  const onScrollChats = () => {
    if (sidebarRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = sidebarRef.current;
      const isBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      if (isBottom && chatsListMoreStatus === status.SUCCESS) {
        loadMoreChats();
      }
    }
  };

  return (
    <Wrap>
      <Overlay />
      <Content>
        <Title />
        <Spacer m="0 0 25px 0" />
        {chatType === chatTypes.project ? (
          <ChatTabs
            options={projectChatTabs}
            activeTab={activeProjectTab}
            setTab={onSetActiveTab}
          />
        ) : null}
        <Chats
          className="custom-scroll"
          ref={sidebarRef}
          onScroll={throttle(onScrollChats, 500)}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {chatsListStatus === status.PENDING ? (
            <Loader size={80} />
          ) : chatsList.length > 0 ? (
            <>
              {chatsList.map((chat) => (
                <Chat
                  key={chat._id}
                  chat={chat}
                />
              ))}
              {chatsListMoreStatus === status.PENDING && <Loader size={80} />}
            </>
          ) : (
            <RListIsEmpty>No results</RListIsEmpty>
          )}
        </Chats>
      </Content>
    </Wrap>
  );
}
