import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getManagersList = createAsyncThunk(
  'admin/getManagersList',
  async ({ pageIndex, pageSize }) => {
    try {
      const { data } = await api.adminManagersPage.getManagersList({
        perPage: pageSize,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addManager = createAsyncThunk('admin/addManager', async (formData) => {
  try {
    const { data } = await api.adminManagersPage.addManager(formData);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getManagersList,
  addManager,
};

export { thunks };
