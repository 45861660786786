import styled, { css } from 'styled-components';

const Tabs = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-inline: 56px;
  border-bottom: 0.5px solid ${({ theme }) => theme.field.borderColor};
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  padding: 11px;
  cursor: pointer;
`;

const TabTitle = styled.div`
  position: relative;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.como};

  &:after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -11px;
    left: 50%;
    background-color: ${({ theme }) => theme.como};
    transform: translateX(-50%);
    transition: 0.15s linear;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: 500;

      &:after {
        width: 160px;
      }
    `}
`;

export { Tabs, Tab, TabTitle };
