import styled from 'styled-components';

const Wrap = styled.div``;

const FieldInput = styled.input`
  display: none;
`;

const FieldWrap = styled.div``;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 120px;
  border: 1px solid ${({ theme }) => theme.field.borderColor};
  border-radius: 4px;
  color: ${({ theme }) => theme.concord};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.heavyMetal};
  }

  ${({ css }) => css}
`;

export { Wrap, FieldInput, FieldWrap, Label };
