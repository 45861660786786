import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { userNotifications } from 'store/notifications';
import { font, notificationTypes } from 'utils/const';

const defaultNotification = {
  level: 'info',
  autoDismiss: 15,
  title: '',
  message: '',
  action: null,
};

export const useNotificationSystem = (notification, ref) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [updatedNotification, setUpdatedNotification] =
    useState(defaultNotification);

  const styles = useMemo(() => {
    return {
      Containers: {
        DefaultStyle: {
          width: '470px',
          padding: '15px 25px 25px 25px',
        },
      },

      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          fontSize: `${font.sm}`,
          padding: '16px 32px',
          margin: '10px 5px 2px 1px',
        },

        info: {
          borderTop: 'none',
          borderRadius: '12px',
          backgroundColor: '#ffffff',
          color: `#808080`,
          WebkitBoxShadow: '0 0 3px ',
          MozBoxShadow: '0 0 3px ',
          boxShadow: '0 0 3px',
        },
      },

      MessageWrapper: {
        fontSize: `${font.sm}`,

        DefaultStyle: {
          margin: 0,
          padding: 0,
        },
      },

      Title: {
        DefaultStyle: {
          fontSize: `${font.sm}`,
        },
        info: {
          color: `${theme.granite}`,
        },
      },

      Action: {
        DefaultStyle: {
          padding: '0',
        },
        info: {
          backgroundColor: '#ffffff',
          color: `${theme.como}`,
        },
      },

      Dismiss: {
        DefaultStyle: {
          top: 16,
          right: 15,
          fontSize: '24px',
          width: '24px',
          height: '24px',
          padding: '0',
        },
        info: {
          backgroundColor: '#ffffff',
          color: `#000000`,
        },
      },
    };
  }, [theme]);

  const goToChat = () => {
    history.push(
      `/manager/inbox/${notification.message.roomType.toLowerCase()}/${
        notification.message.roomId
      }`
    );
  };

  const goToProject = () => {
    history.push(`/manager/projects/${notification.project._id}`);
  };

  const goToInvoice = () => {
    history.push(`/manager/invoices/${notification.invoice._id}`);
  };

  const setMessageBody = ({ message }) => {
    if (message.message) {
      return message.message;
    }

    if (message.photos.length || message.files.length) {
      return message.photos.length + message.files.length > 1
        ? 'Attached files'
        : 'Attached file';
    }

    return '';
  };

  const setNotificationByType = (type) => {
    switch (type) {
      case notificationTypes.CHAT:
        dispatch(userNotifications.thunks.getChatUnreadCount());

        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: setMessageBody(notification),
          title: `New message from ${notification?.sender.name}`,
          action: {
            label: 'View in chat',
            callback: goToChat,
          },
        }));
        break;

      case notificationTypes.CHAT_REACTION:
        dispatch(userNotifications.thunks.getChatUnreadCount());

        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `New reaction from ${notification.sender.firstname} ${notification.sender.lastname}`,
          action: {
            label: 'View in chat',
            callback: goToChat,
          },
        }));
        break;

      case notificationTypes.NOTE:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `${notification?.client.firstname} ${notification?.client.lastname} created a new note`,
          action: null,
        }));
        break;

      case notificationTypes.PROJECT:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: `${notification?.project.title}`,
          title: `${notification?.client.firstname} ${notification?.client.lastname} created a new project`,
          action: {
            label: 'View project',
            callback: goToProject,
          },
        }));
        break;

      case notificationTypes.PROJECT_UPDATE:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `${notification.project.title} project files have been updated`,
          action: {
            label: 'View project',
            callback: goToProject,
          },
        }));
        break;

      case notificationTypes.PROJECT_CANCELED:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `${notification.project.title} project has been canceled`,
          action: {
            label: 'View project',
            callback: goToProject,
          },
        }));
        break;

      case notificationTypes.INVOICE_PAID:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `${notification?.project.title} invoice paid`,
          action: {
            label: 'View invoice',
            callback: goToInvoice,
          },
        }));
        break;

      default:
        setUpdatedNotification((prevState) => ({
          ...prevState,
          message: '',
          title: `You received a notification`,
          action: null,
        }));
    }
  };

  const onAddNotification = (ref) => {
    ref.current.addNotification({
      ...updatedNotification,
    });
  };

  useEffect(() => {
    if (notification) setNotificationByType(notification.type);
  }, [notification]);

  useEffect(() => {
    if (notification) onAddNotification(ref);
  }, [updatedNotification]);

  return { styles };
};
