import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';

const defaultValues = {
  amount: '',
  description: '',
};

const validateAmount = (value) => {
  return !(typeof value === 'number' && value > 0 && value < 0.5);
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .nullable()
    .transform(transformNaN)
    .required()
    .test(
      'amountValidation',
      'Enter amount more than 0.5 or 0',
      validateAmount
    ),
  description: yup.string().max(200).required(),
});

export { defaultValues, schema };
