export const profileModel = (
  _id = '',
  firstname = '',
  lastname = '',
  email = '',
  phone = 0,
  role = ''
) => {
  return { _id, firstname, lastname, email, phone, role };
};
