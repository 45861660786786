import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RTable } from 'components/RTable';
import { useTablePagination } from 'hooks/useTablePagination';
import { userPage } from 'store/manager/userPage';
import { columns } from './config';

export function Table() {
  const { userId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { invoices: list } = useSelector(userPage.selectors.data);
  const {
    page,
    perPage,
    totalItems: totalRows,
  } = useSelector(userPage.selectors.invoicesPagination);

  const { fetchData, pageCount, pageIndex, pageSize } = useTablePagination({
    totalRows,
    perPage,
    deps: [page, list],
  });

  const fetchInvoices = () => {
    dispatch(userPage.thunks.getUserInvoices({ pageIndex, pageSize, userId }));
  };

  const onRowClick = (row) => () => {
    history.push(`/manager/invoices/${row.original.invoice._id}`);
  };

  useEffect(() => {
    fetchInvoices();
  }, [pageIndex, pageSize, userId]);

  return (
    <RTable
      data={list}
      height={550}
      totalRows={totalRows}
      fetchData={fetchData}
      pageCount={pageCount}
      columns={columns}
      onRowClick={onRowClick}
      isClickableRow
    />
  );
}
