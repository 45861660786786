import { createGlobalStyle } from 'styled-components';

import { Reset } from 'components/App/GlobalStyled/global/reset';
import { Base } from 'components/App/GlobalStyled/global/base';
import { Pagination } from 'components/App/GlobalStyled/global/pagination';

export const GlobalStyles = createGlobalStyle`
  ${Reset}
  ${Base}
  ${Pagination}
`;
