import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Spacer } from 'components/App/GlobalStyled';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RModalPortal } from 'components/RModalPortal';
import { RButton } from 'components/Form/RButton';
import { RPopup } from 'components/RPopup';
import { RMask } from 'components/RMask';
import { status } from 'utils/const';
import { chatsRoom } from 'store/chats/room';
import { Footer } from './styled';

ConfirmDeleteMessagePopup.propTypes = {
  message: PropTypes.shape({
    _id: PropTypes.string,
    nameOfList: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

export function ConfirmDeleteMessagePopup({
  onClose = () => {},
  onDelete,
  message: { _id, nameOfList },
}) {
  const dispatch = useDispatch();

  const deleteOwnMessageStatus = useSelector(
    chatsRoom.selectors.deleteOwnMessageStatus
  );

  useEffect(() => {
    if (deleteOwnMessageStatus === status.SUCCESS) {
      onClose();
      dispatch(
        chatsRoom.actions.DELETE_OWN_MESSAGE({
          _id,
          nameOfList,
        })
      );
      dispatch(chatsRoom.actions.RESET_STATUS('deleteOwnMessageStatus'));
    }
  }, [deleteOwnMessageStatus]);

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Delete message"
        >
          <RLoadingOverlay
            isVisible={deleteOwnMessageStatus === status.PENDING}
          />
          Are you sure you want to delete this message?
          <Footer>
            <RButton onClick={onDelete}>Delete</RButton>
            <Spacer m="0 8px" />
            <RButton
              onClick={onClose}
              variant="outline"
            >
              Cancel
            </RButton>
          </Footer>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
