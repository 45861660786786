import { createSlice } from '@reduxjs/toolkit';
import { notificationTypes, status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  newNotification: null,
  listCount: 0,
  unreadMessagesCount: {
    generalChatCount: 0,
    projectChatCount: 0,
  },
  listIsVisible: false,
  listStatus: status.IDLE,
  socketStatus: status.IDLE,
  deleteNotificationStatus: status.IDLE,
  deleteAllNotificationsStatus: status.IDLE,
  getUnreadMessagesCountStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userNotifications',
  initialState: { ...initialState },
  reducers: {
    SET_NEW_NOTIFICATION: (state, { payload }) => {
      state.list.unshift({
        ...payload.data,
        type: payload.type,
      });
      state.newNotification = {
        ...payload.data,
        type: payload.type,
      };

      if (
        payload.type !== notificationTypes.CHAT &&
        payload.type !== notificationTypes.CHAT_REACTION
      )
        state.listCount += 1;
    },
    DELETE_NOTIFICATION: (state, { payload }) => {
      state.list = state.list.filter(
        (notification) => !payload.includes(notification._id)
      );
      state.listCount = state.list.length;
    },
    DECREMENT_UNREAD_COUNT: (state, { payload }) => {
      if (payload.chatType === 'General') {
        if (state.unreadMessagesCount.generalChatCount > 0)
          state.unreadMessagesCount.generalChatCount -= 1;
      }
      if (payload.chatType === 'Project') {
        if (state.unreadMessagesCount.projectChatCount > 0)
          state.unreadMessagesCount.projectChatCount -= 1;
      }
    },
    TOGGLE_LIST_VISIBLE: (state) => {
      state.listIsVisible = !state.listIsVisible;
    },
    SET_SOCKET_STATUS: (state, { payload }) => {
      state.socketStatus = payload;
    },
    RESET_STATE: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getNotifications.pending, (state) => {
        state.listStatus = status.PENDING;
      })
      .addCase(thunks.getNotifications.fulfilled, (state, { payload }) => {
        state.list = payload.notifications.map(({ data, type, _id }) => ({
          ...data,
          _id,
          type,
        }));
        state.listCount = payload.count;
        state.listStatus = status.SUCCESS;
      })
      .addCase(thunks.getNotifications.rejected, (state) => {
        state.listStatus = status.FAIL;
      })

      .addCase(thunks.deleteAllNotifications.pending, (state) => {
        state.deleteAllNotificationsStatus = status.PENDING;
      })
      .addCase(thunks.deleteAllNotifications.fulfilled, (state) => {
        state.list = [];
        state.listCount = 0;
        state.deleteAllNotificationsStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteAllNotifications.rejected, (state) => {
        state.deleteAllNotificationsStatus = status.FAIL;
      })

      .addCase(thunks.getChatUnreadCount.pending, (state) => {
        state.getUnreadMessagesCountStatus = status.PENDING;
      })
      .addCase(thunks.getChatUnreadCount.fulfilled, (state, { payload }) => {
        state.unreadMessagesCount = { ...payload };
        state.getUnreadMessagesCountStatus = status.SUCCESS;
      })
      .addCase(thunks.getChatUnreadCount.rejected, (state) => {
        state.getUnreadMessagesCountStatus = status.FAIL;
      });
  },
});

const userNotifications = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { userNotifications };
export default slice.reducer;
