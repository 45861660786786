import PropTypes from 'prop-types';
import { memo } from 'react';
import { TextEllipsed } from 'components/App/GlobalStyled';

OwnerCell.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.shape({
      _id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    column: PropTypes.shape({
      width: PropTypes.number,
    }),
  }),
};

function OwnerCell({ cell: { value, column } }) {
  return (
    <TextEllipsed width={column.width}>
      {value.firstname} {value.lastname}
    </TextEllipsed>
  );
}

export const MemoizedOwnerCell = memo(OwnerCell);
