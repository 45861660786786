import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  pagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  fetchingStatus: status.IDLE,
  addUserStatus: status.IDLE,
};

const slice = createSlice({
  name: 'usersPage',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUsersList.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getUsersList.fulfilled, (state, { payload }) => {
        state.list = payload.users;
        state.pagination = { ...state.pagination, totalItems: payload.count };
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getUsersList.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.addUser.pending, (state) => {
        state.addUserStatus = status.PENDING;
      })
      .addCase(thunks.addUser.fulfilled, (state, { payload }) => {
        state.list = [...state.list, payload];
        state.pagination = {
          ...state.pagination,
          totalItems: state.pagination.totalItems + 1,
        };
        state.addUserStatus = status.SUCCESS;
      })
      .addCase(thunks.addUser.rejected, (state) => {
        state.addUserStatus = status.FAIL;
      });
  },
});

const usersPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { usersPage };
export default slice.reducer;
