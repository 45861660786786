import styled from 'styled-components';

import { Flex, TextEllipsisLines } from 'components/App/GlobalStyled';
import { font } from 'utils/const';

const Notification = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 32px;
  background-color: #ffffff;
  margin-bottom: 8px;
  border-radius: 24px;
`;

const Header = styled.div`
  ${Flex({ justify: 'space-between' })};
  padding-bottom: 5px;
`;

const Title = styled.div`
  font-size: ${font.sm};
  font-weight: 600;
  color: ${({ theme }) => theme.granite};

  span {
    color: #000000;
  }
`;

const Content = styled.p`
  color: ${({ theme }) => theme.granite};
  ${TextEllipsisLines({ width: '100%' })}
`;

const Action = styled.span`
  display: block;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  color: ${({ theme }) => theme.como};
`;

export { Notification, Header, Title, Content, Action };
