import { useState } from 'react';
import { useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { ChevronSliderLeft, ChevronSliderRight } from 'components/Icons';
import { RFileComponent } from 'components/RFileComponent';
import { RPopup } from 'components/RPopup';
import { RMask } from 'components/RMask';
import { RModalPortal } from 'components/RModalPortal';
import { projectDetails } from 'store/manager/projectDetails';
import {
  Info,
  Title,
  Text,
  Wrapper,
  ImagesBlock,
  Image,
  DocumentLink,
  LeftBtn,
  RightBtn,
  FileWrap,
} from './styled';

export function ProjectInfo() {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [indexMainPhoto, setIndexMainPhoto] = useState(0);
  const { category, status, urgency, description, title, photos, files } =
    useSelector(projectDetails.selectors.project) ?? {};

  const images = photos.map(({ url }) => ({
    original: url,
    thumbnail: url,
  }));

  const rightNav = (onClick) => {
    return (
      <RightBtn onClick={onClick}>
        <ChevronSliderRight />
      </RightBtn>
    );
  };

  const leftNav = (onClick) => {
    return (
      <LeftBtn onClick={onClick}>
        <ChevronSliderLeft />
      </LeftBtn>
    );
  };

  const onClose = () => {
    setOpenPopUp(!openPopUp);
  };

  const onClickImage = (idx) => {
    setIndexMainPhoto(idx);
    setOpenPopUp(true);
  };

  return (
    <Wrapper>
      <Info>
        <Title>Project name:</Title>
        <Text>{title}</Text>
      </Info>
      <Info>
        <Title>Priority:</Title>
        <Text>{urgency}</Text>
      </Info>
      <Info>
        <Title>Category:</Title>
        <Text>{category}</Text>
      </Info>
      <Info>
        <Title>Status:</Title>
        <Text>{status}</Text>
      </Info>
      <Info lastElement>
        <Title>Description:</Title>
        <Text>{description}</Text>
      </Info>
      {!!images.length && (
        <div>
          <Title>Attached files:</Title>
          <ImagesBlock>
            {images.map(({ original }, idx) => (
              <Image
                onClick={() => onClickImage(idx)}
                key={`${original + idx}`}
                src={original}
                alt="Attached files"
              />
            ))}
          </ImagesBlock>
        </div>
      )}
      {!!files.length && (
        <DocumentLink>
          {files.map(({ thumb, originalName: name, url, _id }) => (
            <FileWrap key={_id}>
              <RFileComponent
                ellipsisWidth={300}
                thumb={thumb}
                name={name}
                url={url}
              />
            </FileWrap>
          ))}
        </DocumentLink>
      )}
      {openPopUp && (
        <RModalPortal>
          <RMask>
            <RPopup
              onClose={onClose}
              css={{ padding: '80px' }}
              width={760}
            >
              <ImageGallery
                renderRightNav={rightNav}
                renderLeftNav={leftNav}
                startIndex={indexMainPhoto}
                showFullscreenButton={false}
                showPlayButton={false}
                items={images}
              />
            </RPopup>
          </RMask>
        </RModalPortal>
      )}
    </Wrapper>
  );
}
