import { memo } from 'react';
import PropTypes from 'prop-types';

ManufacturerCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.string,
  }),
};

function ManufacturerCell({ cell: { value, row } }) {
  return (
    <>
      {value}&nbsp;
      {row.original.length}&apos;
    </>
  );
}

export const MemoizedManufacturerCell = memo(ManufacturerCell);
