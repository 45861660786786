import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  list: [],
  pagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  fetchingStatus: status.IDLE,
  addUserStatus: status.IDLE,
};

const slice = createSlice({
  name: 'adminUsersPage',
  initialState,
  reducers: {
    RESET_ADD_USER_STATUS: (state) => {
      state.addUserStatus = state.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUsersList.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getUsersList.fulfilled, (state, { payload }) => {
        state.list = payload.users;
        state.pagination = { ...state.pagination, totalItems: payload.count };
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getUsersList.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

const adminUsersPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { adminUsersPage };
export default slice.reducer;
