import styled from 'styled-components';

import { Container, Flex } from 'components/App/GlobalStyled';

const Main = styled.div`
  ${Container({ width: 520 })};
  top: 32px;
  left: 32px;
  padding: 16px;
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
  background-color: #f4f4f4;
  z-index: 99999;

  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(0)' : 'translateY(500px)'};
`;

const Wrap = styled.div`
  ${Flex({ direction: 'column' })};
  max-height: ${({ windowHeight }) => `${windowHeight - 200}px`};
  margin-bottom: 24px;
  padding-right: 16px;
  overflow-y: scroll;
`;

export { Main, Wrap };
