import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Notification,
  Header,
  Title,
  Content,
  Action,
} from '../_shared/styled';

ProjectNotification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    client: PropTypes.shape({
      _id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    project: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

export function ProjectNotification({ notification, onClose = () => {} }) {
  const history = useHistory();

  const goToProject = async () => {
    history.push(`/manager/projects/${notification.project._id}`);
    onClose();
  };

  return (
    <Notification>
      <Header>
        <Title>
          <span>
            {notification.client.firstname}&nbsp;
            {notification.client.lastname}
          </span>
          &nbsp;created a new project
        </Title>
      </Header>
      <Content>"{notification.project.title}"</Content>
      <Action onClick={goToProject}>View project</Action>
    </Notification>
  );
}
