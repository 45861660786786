import { useTheme } from 'styled-components';
import { format } from 'date-fns';

import { appDateFormat, calendarTimeFormat } from 'utils/formats';
import { getHour, getMinute, transformNumber } from 'utils/prepare';
import { prepareDateFromServer } from 'utils/prepareDate';
import { projectTrack } from 'utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { projectDetails } from 'store/manager/projectDetails';
import { Text, Wrap, Time } from './styled';

export function AppointmentIfo() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { appointment, track } = useSelector(projectDetails.selectors.project);

  const { cost, duration, name, onTime, serviceProvider } = appointment;
  const dateTime = format(prepareDateFromServer(onTime), appDateFormat);
  const time = format(prepareDateFromServer(onTime), calendarTimeFormat);

  const setEditAppointment = () => {
    dispatch(projectDetails.actions.SET_EDIT_MODE(true));
    dispatch(projectDetails.actions.SET_STATUS_STEP(projectTrack.search));
  };

  return (
    <Wrap>
      <Text>{name}</Text>
      <Text>
        {dateTime}
        <Time>{time}</Time>
      </Text>
      <Text>
        {getHour(duration)} hours {getMinute(duration)} minutes
      </Text>
      <Text>{serviceProvider}</Text>
      <Text>$ {transformNumber(cost / 100)}</Text>
      {track === projectTrack.scheduling ? (
        <Text
          onClick={setEditAppointment}
          css={{
            cursor: 'pointer',
            fontWeight: 500,
            color: theme.blueDark,
          }}
        >
          Edit appointment
        </Text>
      ) : null}
    </Wrap>
  );
}
