import PropTypes from 'prop-types';

import { Wrap } from 'pages/_shared/styled';
import { MsgText } from '../_shared/MsgText';
import { TextBlock } from './styled';

SystemMessage.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      avatarPath: PropTypes.string,
    }),
    message: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export function SystemMessage({ message }) {
  return (
    <Wrap>
      <TextBlock>
        <MsgText message={message.message} />
      </TextBlock>
    </Wrap>
  );
}
