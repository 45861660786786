import styled from 'styled-components';

import { RootFieldInput } from '../_shared/styled';

const FieldInput = styled(RootFieldInput)`
  padding: ${({ appendIcon }) => (appendIcon ? '16px 48px 16px 16px' : '16px')};
  cursor: pointer;
`;

const FieldInputTime = styled(FieldInput)`
  width: 112px;
  min-width: 112px;
`;

const IconField = styled.div`
  position: absolute;
  top: 12px;
  right: 13px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const Calendar = styled.div`
  .react-datepicker__time-container {
    width: 87px;
    border-left: none;
    margin-left: 24px;
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
  }

  .react-datepicker__time-list-item {
    font-family: 'Poppins';
    font-size: 14px;
    height: 36px !important;
    color: ${({ theme }) => theme.calendarText};
    display: flex;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    font-weight: 400;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.como}!important;
  }

  .react-datepicker__header {
    padding-top: 20px;
    background: ${({ theme }) => theme.field.bgColor};
    border: none;
  }

  .react-datepicker__header--time {
    padding-bottom: 12px;
  }

  .react-datepicker-time__header {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.calendarText};
    font-family: 'Poppins';
  }

  .react-datepicker {
    position: absolute;
    right: 0;
    z-index: 200;
    width: 385px;
    box-shadow: 4px 4px 32px rgb(73 73 73 / 8%);
    border-radius: 8px;
    border: none;
  }

  .react-datepicker__current-month {
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.calendarText};
    font-family: 'Poppins';
  }

  .react-datepicker__navigation--previous {
    top: 20px;
    left: 41px;
  }

  .react-datepicker__navigation--next--with-time {
    right: 151px;
    top: 20px;
  }

  .react-datepicker__day {
    font-family: 'Poppins';
    font-size: 12px;
    color: ${({ theme }) => theme.dataPickerDay};
    border-radius: 8px;
    font-weight: 400;
    width: 32px;
    line-height: 32px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: ${({ theme }) => theme.como};
    color: ${({ theme }) => theme.field.bgColor};
  }

  .react-datepicker__day-name {
    width: 32px;
  }

  .react-datepicker__navigation-icon--previous {
    &:before {
      border-color: ${({ theme }) => theme.table.rowColor};
      border-width: 2px 2px 0 0;
      height: 8px;
      width: 8px;
    }
  }

  .react-datepicker__navigation-icon--next {
    &:before {
      border-color: ${({ theme }) => theme.table.rowColor};
      border-width: 2px 2px 0 0;
      height: 8px;
      width: 8px;
    }
  }

  .react-datepicker__day-names {
    > div {
      &:first-of-type {
        color: ${({ theme }) => theme.freeDay};
      }

      &:last-of-type {
        color: ${({ theme }) => theme.freeDay};
      }
    }
  }
`;

export { FieldInput, IconField, Calendar, Wrapper, FieldInputTime };
