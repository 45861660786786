import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppLogo } from 'components/AppLogo';
import { IconBell } from 'components/Icons';
import { userNotifications } from 'store/notifications';
import { auth } from 'store/auth';
import { Spacer } from 'components/App/GlobalStyled';
import {
  Sidebar,
  Header,
  Notifications,
  NotificationsCount,
  UserName,
  ItemWrap,
  ItemLink,
  SubLinks,
  ItemIcon,
  ItemText,
  Logout,
} from './styled';

RSidebar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      icon: PropTypes.node,
      title: PropTypes.string,
      subLinks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          route: PropTypes.string,
        })
      ),
    })
  ),
  withNotifications: PropTypes.bool,
};

export function RSidebar({ links, withNotifications = true }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const unreadMessagesCount = useSelector(
    userNotifications.selectors.unreadMessagesCount
  );
  const { firstname, lastname } = useSelector(auth.selectors.user);
  const listCount = useSelector(userNotifications.selectors.listCount);

  const onLogout = () => {
    dispatch(auth.thunks.authLogout());
  };

  const onShowNotifications = () => {
    dispatch(userNotifications.actions.TOGGLE_LIST_VISIBLE());
  };

  const preventOnLinks = (route) => (e) => {
    e.preventDefault();
    history.replace(route);
  };

  useEffect(() => {
    dispatch(userNotifications.thunks.getChatUnreadCount());
  }, []);

  const renderLinks = useCallback(() => {
    return links.map(({ route, icon, title, subLinks }) => {
      return (
        <ItemWrap key={route}>
          <ItemLink
            to={route}
            onClick={subLinks ? preventOnLinks(subLinks[0].route) : null}
          >
            <ItemIcon>{icon}</ItemIcon>
            <ItemText>{title}</ItemText>
          </ItemLink>
          {subLinks?.length && (
            <SubLinks>
              {subLinks.map(({ route, title, counter }) => {
                return (
                  <ItemLink
                    key={route}
                    to={route}
                  >
                    <Spacer p="0 0 0 24px" />
                    <ItemText>{title}</ItemText>
                    {unreadMessagesCount[counter] > 0 ? (
                      <NotificationsCount
                        wider={unreadMessagesCount[counter] > 99}
                        css={{ top: 0, right: 0 }}
                      >
                        {unreadMessagesCount[counter] <= 99
                          ? unreadMessagesCount[counter]
                          : '99+'}
                      </NotificationsCount>
                    ) : null}
                  </ItemLink>
                );
              })}
            </SubLinks>
          )}
        </ItemWrap>
      );
    });
  }, [unreadMessagesCount]);

  return (
    <Sidebar>
      <Header>
        <AppLogo />
        {withNotifications ? (
          <Notifications onClick={onShowNotifications}>
            {listCount > 0 && (
              <NotificationsCount wider={listCount > 99}>
                {listCount <= 99 ? listCount : '99+'}
              </NotificationsCount>
            )}
            <IconBell />
          </Notifications>
        ) : null}
      </Header>
      <UserName>
        {firstname}&nbsp;{lastname}
      </UserName>
      <div>{renderLinks()}</div>
      <Logout onClick={onLogout}>
        <span>Logout</span>
      </Logout>
    </Sidebar>
  );
}
