import styled from 'styled-components';
import { device } from 'utils/device';

const RoomWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 14px;
  overflow: hidden;

  @media (${device.md}) {
    grid-template-rows: 40px auto 1fr auto;
  }

  @media (${device.sm}) {
    padding: 24px 0;
  }
`;

export { RoomWrap };
