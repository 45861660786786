import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RMask } from 'components/RMask';
import { RModalPortal } from 'components/RModalPortal';
import { RPopup } from 'components/RPopup';
import { RTextField } from 'components/Form/RTextField';
import { RPhoneNumberField } from 'components/Form/RPhoneNumberField';
import { RButton } from 'components/Form/RButton';
import { status } from 'utils/const';
import { userPage } from 'store/manager/userPage';
import { PopupForm } from 'pages/_shared/styled';
import { schema } from './config';

EditUserPopup.propTypes = {
  onClose: PropTypes.func,
};

const fields = [
  { name: 'firstname', label: 'First name', type: 'text' },
  { name: 'lastname', label: 'Last name', type: 'text' },
  { name: 'email', label: 'Mail', type: 'text' },
  { name: 'phone', label: 'Phone number', type: 'phone' },
];

export function EditUserPopup({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const {
    user,
    user: { firstname, lastname, email, phone },
  } = useSelector(userPage.selectors.data);
  const editUserStatus = useSelector(userPage.selectors.editUserStatus);

  const { handleSubmit, control } = useForm({
    defaultValues: useMemo(
      () => ({ firstname, lastname, email, phone: String(phone) }),
      [user]
    ),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (editUserStatus === status.SUCCESS) {
      onClose();
      toast.success(`${firstname} ${lastname} profile updated`);
      dispatch(userPage.actions.RESET_STATUS('editUserStatus'));
    }
  }, [editUserStatus]);

  const onEdit = (formData) => {
    dispatch(userPage.thunks.editUser({ formData, userId }));
  };

  const renderFields = () => {
    return fields.map(({ type, ...rest }) =>
      type === 'phone' ? (
        <RPhoneNumberField
          {...rest}
          key={rest.name}
          control={control}
          labelStatic={false}
          fullWidth
        />
      ) : (
        <RTextField
          {...rest}
          key={rest.name}
          control={control}
          fullWidth
        />
      )
    );
  };

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Edit user"
        >
          <RLoadingOverlay isVisible={editUserStatus === status.PENDING} />
          <PopupForm onSubmit={handleSubmit(onEdit)}>
            {renderFields()}
            <RButton
              type="submit"
              fullWidth
            >
              Save
            </RButton>
          </PopupForm>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
