import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Notification,
  Header,
  Title,
  Action,
} from 'components/Notifications/Notification/_shared/styled';

ProjectCanceledNotification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    client: PropTypes.shape({
      _id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    project: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

export function ProjectCanceledNotification({ notification, onClose }) {
  const history = useHistory();

  const goToProject = async () => {
    history.push(`/manager/projects/${notification.project._id}`);
    onClose();
  };

  return (
    <Notification>
      <Header>
        <Title>
          <span>{notification.project.title}</span>&nbsp;project has been
          canceled
        </Title>
      </Header>
      <Action onClick={goToProject}>View project</Action>
    </Notification>
  );
}
