import io from 'socket.io-client';
import { toast } from 'react-toastify';

import { userNotifications } from 'store/notifications';
import { chatsRoom } from 'store/chats/room';
import { notificationTypes, status } from 'utils/const';
import { chats } from 'store/chats';
import { auth } from 'store/auth';
import store from 'store';

// To detect which users` socket action received
function belongsToOpponent(_id) {
  const {
    auth: { user },
  } = store.getState();

  return _id !== user._id;
}

class InitChatSocket {
  constructor({ jwtToken }) {
    this.token = jwtToken;
    this.namespace = 'chat';
    this.socket = null;
    this.timeoutSeconds = 15000;
    this.belongsToOpponent = belongsToOpponent.bind(this);
  }

  init() {
    if (!this.token) {
      console.error('=> Token not found. Sockets are not connected');
      toast.error('Problems with permissions. Sockets are not connected');
      store.dispatch(chats.actions.SET_SOCKET_STATUS(status.FAIL));
      return;
    }

    this.socket = io(`${window.location.hostname}/${this.namespace}`, {
      reconnectionDelayMax: 10000,
      extraHeaders: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    this.socket.on('connect', () => {
      console.log(
        '%c=> Connected chat socket',
        'color: limegreen',
        this.socket.connected
      );
      store.dispatch(auth.actions.SET_SOCKET_ID(this.socket.id));
      store.dispatch(chats.actions.SET_SOCKET_STATUS(status.SUCCESS));
    });

    this.socket.on('close', (payload) => {
      console.log('=> Socket chat was closed', payload);
    });

    this.socket.on('disconnect', (data) => {
      console.log(
        '%c=> Socket chat disconnected!',
        'color: red',
        data,
        this.socket
      );
      store.dispatch(chats.actions.SET_SOCKET_STATUS(status.FAIL));
    });

    this.socket.on('connect_error', (err) => {
      console.error('=> Socket chat connect_error:', err);
      store.dispatch(chats.actions.SET_SOCKET_STATUS('error'));
    });
  }

  connectToRoom() {
    if (!this.socket) return;

    this.socket.on('message.send', (msg) => {
      console.log('%c=> Message sent', 'color: limegreen', msg);
      store.dispatch(
        chats.actions.SET_NEW_MSG({
          ...msg,
          opponent: this.belongsToOpponent(msg.sender._id),
        })
      );
      store.dispatch(chatsRoom.actions.SET_NEW_MSG(msg));

      if (this.belongsToOpponent(msg.sender._id)) {
        store.dispatch(
          userNotifications.actions.SET_NEW_NOTIFICATION({
            data: { ...msg },
            type: notificationTypes.CHAT,
          })
        );
      }
    });

    this.socket.on('message.deleted', (data) => {
      console.log('%c=> Message deleted', 'color: limegreen', data);
    });

    this.socket.on('message.read', (messages) => {
      console.log('%c=> Message read', 'color: limegreen', messages);
      store.dispatch(chatsRoom.actions.SET_MSG_IS_READ(messages));
      store.dispatch(userNotifications.thunks.getChatUnreadCount());

      store.dispatch(chats.actions.SET_CHAT_ON_MANAGER_READ(messages));

      if (messages.length > 0) {
        store.dispatch(userNotifications.actions.DELETE_NOTIFICATION(messages));
      }
    });

    this.socket.on(
      'message.reaction',
      ({ chatMessage: message, sender, room }) => {
        console.log('%c=> Message reaction', 'color: limegreen', {
          message,
          sender,
          room,
        });
        store.dispatch(
          chatsRoom.actions.SET_NEW_REACTION({ message, action: 'add' })
        );
        if (belongsToOpponent(sender._id)) {
          store.dispatch(
            chats.actions.UPDATE_UNREAD_REACTIONS({
              message,
              roomId: room._id,
              unreadReactionsCount: room.unreadReactionsCount || 0,
            })
          );
          store.dispatch(
            userNotifications.actions.SET_NEW_NOTIFICATION({
              data: { message: { ...message, roomType: room.type }, sender },
              type: notificationTypes.CHAT_REACTION,
            })
          );
        }
      }
    );

    this.socket.on(
      'reaction.deleted',
      ({ chatMessage: message, room, sender }) => {
        console.log('%c=> Message reaction deleted', 'color: limegreen', {
          sender,
          message,
          room,
        });
        store.dispatch(
          chatsRoom.actions.SET_NEW_REACTION({ message, action: 'remove' })
        );
        if (belongsToOpponent(sender._id)) {
          store.dispatch(
            chats.actions.UPDATE_UNREAD_REACTIONS({
              message,
              roomId: room._id,
              unreadReactionsCount: room.unreadReactionsCount || 0,
            })
          );
          store.dispatch(
            userNotifications.actions.DECREMENT_UNREAD_COUNT({
              chatType: room.type,
            })
          );
        }
      }
    );

    this.socket.on('reaction.read', ({ roomId, room }) => {
      console.log('%c=> reaction read', 'color: limegreen', roomId, room);
      store.dispatch(
        chats.actions.UPDATE_UNREAD_REACTIONS({
          roomId,
          unreadReactionsCount: 0,
        })
      );
      store.dispatch(
        userNotifications.actions.DECREMENT_UNREAD_COUNT({
          chatType: room.type,
        })
      );
    });
  }

  socketDisconnect() {
    this.socket.disconnect();
    this.token = null;
    this.socket = null;
  }
}

export default InitChatSocket;
