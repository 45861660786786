import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect, useCallback } from 'react';

import { IconAttach, IconClose, IconSend } from 'components/Icons';
import { RFileUpload } from 'components/Form/RFileUpload';
import { FlexWrap, Typography } from 'components/App/GlobalStyled';
import { deleteObjInArrByProperty, prepareFormData } from 'utils/prepare';
import { filesFormats, photosFormats, status } from 'utils/const';
import { chatsRoom } from 'store/chats/room';
import {
  TextAreaContainer,
  Wrap,
  Send,
  Field,
  Attach,
  AttachedFiles,
  Delete,
} from './styled';

export function Panel() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const textareaSelectionRange = useRef([0, 0]);
  const [textareaValue, setTextareaValue] = useState('');
  const [attachedFiles, setAttachedFiles] = useState([]);
  const sendMessageStatus = useSelector(chatsRoom.selectors.sendMessageStatus);
  const messageSent = sendMessageStatus === status.SUCCESS;

  const isSendActive = attachedFiles.length || textareaValue.length;

  const handleTextareaInput = (e) => {
    const val = e.target.value.replace(/^ +| +$/gm, '');

    if (val.length > 0) setTextareaValue(e.target.value);
    else setTextareaValue('');
  };

  const updateTextareaSelectionRange = () => {
    if (textareaRef.current === null) return;
    textareaSelectionRange.current = [
      textareaRef.current.selectionStart,
      textareaRef.current.selectionEnd,
    ];
  };

  const onSubmitMsg = async () => {
    const files = attachedFiles.filter((file) => file.type === 'file');

    const photos = attachedFiles.filter((file) => file.type === 'photo');

    await dispatch(
      chatsRoom.thunks.sendMessage({
        message: textareaValue,
        photos,
        files,
      })
    );

    setAttachedFiles([]);
  };

  const onEnterSubmit = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (textareaValue.length > 0) onSubmitMsg();
    }

    if (e.keyCode === 13 && e.shiftKey === true && textareaValue <= 0) {
      e.preventDefault();
    }
  };

  const onAttachFile = async (file) => {
    const res = await dispatch(
      chatsRoom.thunks.fileUpload(prepareFormData({ file }))
    );

    if (res.payload?.status === 200) {
      await setAttachedFiles((prev) => [
        ...prev,
        {
          ...res.payload.data,
          type: photosFormats.includes(file.type) ? 'photo' : 'file',
        },
      ]);
      fileInputRef.current.clear();
    }
  };

  const handleKeypress = (e) => {
    // send message by pressing the enter key
    if (e.keyCode === 13) {
      onSubmitMsg();
    }
  };

  const onDeleteAttached = (name) => () => {
    setAttachedFiles((prev) => deleteObjInArrByProperty(prev, name, 'name'));
  };

  const renderAttachedFiles = useCallback(() => {
    return (
      <AttachedFiles>
        {attachedFiles.map(({ originalName: name, url }) => (
          <FlexWrap
            key={url}
            justify="space-between"
            p="10px"
          >
            <Typography color={theme.como}>{name}</Typography>
            <Delete onClick={onDeleteAttached(name)}>
              <IconClose />
            </Delete>
          </FlexWrap>
        ))}
      </AttachedFiles>
    );
  }, [attachedFiles]);

  useEffect(() => {
    if (messageSent) setTextareaValue('');
    return () => dispatch(chatsRoom.actions.RESET_STATUS('sendMessageStatus'));
  }, [messageSent]);

  return (
    <>
      {renderAttachedFiles()}
      <Wrap>
        <Field>
          <TextAreaContainer
            onKeyPress={handleKeypress}
            className="custom-scroll"
            placeholder="Write your message..."
            rows={1}
            maxRows={3}
            ref={textareaRef}
            value={textareaValue}
            onInput={handleTextareaInput}
            onBlur={updateTextareaSelectionRange}
            onKeyDown={onEnterSubmit}
          />
          <Attach>
            <RFileUpload
              ref={fileInputRef}
              name="attachFileToMessage"
              onFileLoad={onAttachFile}
              additionalFileTypes={[
                '.doc',
                '.docx',
                '.heic',
                ...photosFormats,
                ...filesFormats,
              ]}
              css={{ border: 'none', alignItems: 'center', minHeight: 'auto' }}
            >
              <IconAttach />
            </RFileUpload>
          </Attach>
        </Field>
        <Send
          disabled={!isSendActive}
          onClick={onSubmitMsg}
        >
          <IconSend color={!isSendActive ? theme.sendActive : theme.granite} />
        </Send>
      </Wrap>
    </>
  );
}
