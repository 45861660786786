import { Typography } from 'components/App/GlobalStyled';

import { font } from 'utils/const';
import { Wrap } from './styled';

export function Title() {
  return (
    <Wrap>
      <Typography
        fw={600}
        fz={font.xl}
      >
        Messages
      </Typography>
    </Wrap>
  );
}
