import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ListChats } from 'components/ChatPage/ListChats';
import { chatsRoom } from 'store/chats/room';
import { chats } from 'store/chats';

function InboxPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(chats.actions.RESET_STATE());
      dispatch(chatsRoom.actions.RESET_STATE());
    };
  }, []);

  return <ListChats />;
}

export default InboxPage;
