import PropTypes from 'prop-types';

import { notificationTypes } from 'utils/const';
import { ProjectUpdatedNotification } from './Project/Updated';
import { ProjectCanceledNotification } from './Project/Canceled';
import { InvoiceNotification } from './Invoice';
import { ProjectNotification } from './Project';
import { NoteNotification } from './Note';
import { ChatNotification } from './Chat';

const notificationTypesAsArray = Object.values(notificationTypes);
const notificationComponents = {
  [notificationTypes.CHAT]: ChatNotification,
  [notificationTypes.NOTE]: NoteNotification,
  [notificationTypes.PROJECT]: ProjectNotification,
  [notificationTypes.INVOICE_PAID]: InvoiceNotification,
  [notificationTypes.PROJECT_UPDATE]: ProjectUpdatedNotification,
  [notificationTypes.PROJECT_CANCELED]: ProjectCanceledNotification,
};

Notification.propTypes = {
  notification: PropTypes.object,
};

export function Notification({ notification, ...rest }) {
  if (!notificationTypesAsArray.includes(notification.type)) return null; // return layout for 'broken' notification

  const NotificationComponent = notificationComponents[notification.type];
  return (
    <NotificationComponent
      notification={notification}
      {...rest}
    />
  );
}
