import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RTable } from 'components/RTable';
import { useTablePagination } from 'hooks/useTablePagination';
import { status } from 'utils/const';
import { invoicesPage } from 'store/manager/invoicesPage';
import { columns } from './config';

export function Table() {
  const dispatch = useDispatch();
  const history = useHistory();
  const listInvoice = useSelector(invoicesPage.selectors.list);
  const fetchingStatus = useSelector(invoicesPage.selectors.fetchingStatus);

  const { perPage, totalItems: totalRows } = useSelector(
    invoicesPage.selectors.pagination
  );

  const { fetchData, pageCount, pageIndex, pageSize } = useTablePagination({
    totalRows,
    perPage,
    deps: [listInvoice],
  });

  useEffect(() => {
    dispatch(invoicesPage.actions.SET_PAGINATION({ page: pageIndex }));
    dispatch(invoicesPage.thunks.getInvoices({ pageIndex, pageSize }));
  }, [pageIndex, pageSize]);

  const onRowClick = (row) => () => {
    history.push(`/manager/invoices/${row.original._id}`);
  };

  return (
    <RTable
      loading={status.PENDING === fetchingStatus}
      data={listInvoice}
      height={620}
      fetchData={fetchData}
      totalRows={totalRows}
      pageCount={pageCount}
      columns={columns}
      onRowClick={onRowClick}
      isClickableRow
    />
  );
}
