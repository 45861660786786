import { css } from 'styled-components';

export const Base = () => css`
  * {
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.como};
      border-radius: 5px;
    }
  }

  body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
    letter-spacing: 0.25px;
    color: black;

    .Toastify__toast-container {
      word-break: break-word;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
  }

  h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.1px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0;
  }

  h3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.5px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.5px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.3px;
  }
`;
