import PropTypes from 'prop-types';

import { IconLoader } from 'components/Icons';
import { Overlay } from './styled';

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export function Loader({ size, color }) {
  return (
    <Overlay>
      <IconLoader
        fill={color}
        size={size}
      />
    </Overlay>
  );
}
