import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleServerErrors } from 'utils/serverErrors';
import { api } from 'api';
import { chats } from 'store/chats/index';
import { chatStatuses } from 'utils/const';

const getGeneralChats = createAsyncThunk(
  'user/getGeneralChats',
  async (params, { getState }) => {
    try {
      const {
        general: { page },
      } = chats.selectors.listPag(getState());

      const res = await api.chats.getGeneralChats({ page });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getProjectChats = createAsyncThunk(
  'user/getProjectChats',
  async (_, { getState }) => {
    try {
      const { project } = chats.selectors.listPag(getState());
      const activeProjectTab = chats.selectors.activeProjectTab(getState());

      const res = await api.chats.getProjectChats({
        page: project[activeProjectTab].page,
        status: chatStatuses[activeProjectTab],
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMoreGeneralChats = createAsyncThunk(
  'user/getMoreGeneralChats',
  async (params, { getState }) => {
    try {
      const { general } = chats.selectors.listPag(getState());
      const res = await api.chats.getGeneralChats({
        page: general.page,
        perPage: general.perPage,
        ...params,
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getMoreProjectChats = createAsyncThunk(
  'user/getMoreProjectChats',
  async (_, { getState }) => {
    try {
      const { project } = chats.selectors.listPag(getState());
      const activeProjectTab = chats.selectors.activeProjectTab(getState());
      const res = await api.chats.getProjectChats({
        status: chatStatuses[activeProjectTab],
        page: project[activeProjectTab].page,
        perPage: project[activeProjectTab].perPage,
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getGeneralChats,
  getProjectChats,
  getMoreGeneralChats,
  getMoreProjectChats,
};

export { thunks };
