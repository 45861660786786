import { useSelector } from 'react-redux';

import { userPage } from 'store/manager/userPage';
import { Wrap } from './styled';

export function UserData() {
  const {
    user: { firstname, lastname, phone, email },
  } = useSelector(userPage.selectors.data);

  return (
    <Wrap css={{ maxWidth: '640px' }}>
      <p>First name:</p>
      <p>{firstname}</p>
      <p>Last name:</p>
      <p>{lastname}</p>
      <p>Phone number:</p>
      <p>+{phone}</p>
      <p>Mail:</p>
      <p>{email}</p>
    </Wrap>
  );
}
