import { MemoizedTextEllipsesCell } from 'components/RTable/SharedCells/TextEllipsesCell';
import { MemoizedCurrencyCell } from 'components/RTable/SharedCells/CurrencyCell';
import { MemoizedDateCell } from './DateCell';

export const columns = [
  {
    Header: 'Project name',
    accessor: 'project.title',
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Status',
    accessor: 'invoice.status',
    width: 70,
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Invoice total',
    accessor: 'invoice.amount',
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedCurrencyCell,
  },
  {
    Header: 'Creation date',
    accessor: 'invoice.createdAt',
    headStyle: {
      paddingBlock: '12px',
      justifyContent: 'flex-end',
    },
    bodyStyle: {
      paddingBlock: '12px',
      justifyContent: 'flex-end',
    },
    Cell: MemoizedDateCell,
  },
];
