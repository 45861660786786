import PropTypes from 'prop-types';

import { RButtonIcon } from 'components/Form/RButtonIcon';
import { IconClose } from 'components/Icons';
import { Popup, Title, Close } from './styled';

RPopup.propTypes = {
  width: PropTypes.number,
  fullHeight: PropTypes.bool,
  maxHeight: PropTypes.number,
  onClose: PropTypes.func,
  popupTitle: PropTypes.string,
  children: PropTypes.node,
  titleCss: PropTypes.object,
  css: PropTypes.object,
};

function RPopup({
  width = 420,
  titleCss,
  fullHeight,
  maxHeight,
  onClose,
  popupTitle,
  children,
  css,
}) {
  return (
    <Popup {...{ width, fullHeight, maxHeight, popupTitle, css }}>
      {popupTitle ? <Title css={titleCss}>{popupTitle}</Title> : null}
      <Close>
        {onClose ? (
          <RButtonIcon
            size={24}
            color="#000000"
            onClick={onClose}
          >
            <IconClose />
          </RButtonIcon>
        ) : null}
      </Close>
      {children}
    </Popup>
  );
}

export { RPopup };
