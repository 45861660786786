import { formattedDateForChat } from 'utils/prepare';

export default class Chat {
  constructor(chat) {
    this.chat = { ...chat };
    this.chatDefault = {
      _id: 0,
      unreadMessagesCount: 0,
      unreadReactionsCount: 0,
      lastMessage: null,
      profile: {
        name: '',
        isOnline: false,
      },
    };
  }

  getChat() {
    const { lastMessage, unreadMessageCount, unreadReactionsCount, ...data } =
      this.chat;
    return {
      ...this.chatDefault,
      ...data,
      unreadMessagesCount: unreadMessageCount || 0,
      unreadReactionsCount: unreadReactionsCount || 0,
      lastMessage: lastMessage
        ? {
            ...lastMessage,
            createdAt: formattedDateForChat(lastMessage.createdAt),
          }
        : null,
    };
  }
}
