import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getUsersList = createAsyncThunk(
  'manager/getUsersList',
  async ({ pageIndex, pageSize }) => {
    try {
      const { data } = await api.usersPage.getUsersList({
        perPage: pageSize,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addUser = createAsyncThunk('manager/addUser', async (formData) => {
  try {
    const { data } = await api.usersPage.addUser(formData);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  getUsersList,
  addUser,
};

export { thunks };
