import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleServerErrors } from 'utils/serverErrors';
import { api } from 'api';

const getNotifications = createAsyncThunk('user/getNotifications', async () => {
  try {
    const res = await api.userNotifications.getNotifications();
    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const deleteAllNotifications = createAsyncThunk(
  'user/deleteAllNotifications',
  async () => {
    try {
      const res = await api.userNotifications.deleteAllNotifications();
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getChatUnreadCount = createAsyncThunk(
  'user/getChatUnreadCount',
  async () => {
    try {
      const res = await api.chats.getChatUnreadCount();

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getNotifications,
  deleteAllNotifications,
  getChatUnreadCount,
};

export { thunks };
