import { MemoizedTextEllipsesCell } from 'components/RTable/SharedCells/TextEllipsesCell';
import { MemoizedCurrencyCell } from 'components/RTable/SharedCells/CurrencyCell';
import { MemoizedCreateDateCell } from './CreateDateCell';

export const columns = [
  {
    Header: 'Project name',
    accessor: 'projectName',
    width: 200,
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Boat Owner',
    accessor: 'owner',
    width: 150,
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 150,
  },
  {
    Header: 'Invoice total',
    accessor: 'amount',
    width: 150,
    Cell: MemoizedCurrencyCell,
  },
  {
    Header: 'Creation date',
    accessor: 'createdAt',
    width: 200,
    headStyle: {
      justifyContent: 'flex-end',
    },
    bodyStyle: {
      justifyContent: 'flex-end',
    },
    Cell: MemoizedCreateDateCell,
  },
];
