const selectors = {
  data: (state) => ({
    invoice: state.invoicePage.invoice,
    project: state.invoicePage.project,
    profile: state.invoicePage.profile,
  }),
  fetchingStatus: (state) => state.invoicePage.fetchingStatus,
};

export { selectors };
