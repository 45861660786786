import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RTable } from 'components/RTable';
import { useTablePagination } from 'hooks/useTablePagination';
import { status } from 'utils/const';
import { userPage } from 'store/manager/userPage';
import { columns } from './config';

export function Table() {
  const history = useHistory();
  const { boats: list } = useSelector(userPage.selectors.data);
  const fetchingStatus = useSelector(userPage.selectors.fetchingStatus);
  const { fetchData, pageCount } = useTablePagination({
    deps: [list],
  });

  const onRowClick = (row) => () => {
    history.push(`/manager/boats/${row.original._id}`);
  };

  return (
    <RTable
      data={list}
      height={550}
      fetchData={fetchData}
      pageCount={pageCount}
      columns={columns}
      loading={fetchingStatus === status.PENDING}
      onRowClick={onRowClick}
      isClickableRow
      disablePagination
    />
  );
}
