import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getUserInfo = createAsyncThunk(
  'manager/getUserInfo',
  async ({ userId }) => {
    try {
      const { data } = await api.userPage.getUserInfo(userId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addBoat = createAsyncThunk('manager/addBoat', async (formData) => {
  try {
    const { data } = await api.userPage.addBoat(formData);
    return data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getUserProjects = createAsyncThunk(
  'manager/getUserProjects',
  async ({ userId, pageSize, pageIndex }) => {
    try {
      const { data } = await api.userPage.getUserProjects(userId, {
        perPage: pageSize,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getUserDocuments = createAsyncThunk(
  'manager/getUserDocuments',
  async ({ userId }) => {
    try {
      const { data } = await api.userPage.getUserDocuments(userId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const uploadDocuments = createAsyncThunk(
  'manager/uploadDocuments',
  async ({ userId, files }) => {
    try {
      const { data } = await api.userPage.uploadDocuments(userId, files);
      toast.success('Documents successfully added');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteDocument = createAsyncThunk(
  'manager/deleteDocument',
  async ({ docId }) => {
    try {
      const { data } = await api.userPage.deleteDocument(docId);
      toast.success('Document successfully deleted');
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getUserInvoices = createAsyncThunk(
  'manager/getUserInvoices',
  async ({ userId, pageSize, pageIndex }) => {
    try {
      const { data } = await api.userPage.getUserInvoices(userId, {
        perPage: pageSize,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editUser = createAsyncThunk(
  'manager/editUser',
  async ({ formData, userId }) => {
    try {
      const { data } = await api.userPage.editUser(formData, userId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  addBoat,
  getUserInfo,
  getUserProjects,
  getUserInvoices,
  getUserDocuments,
  uploadDocuments,
  deleteDocument,
  editUser,
};

export { thunks };
