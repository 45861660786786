import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import ChatMessage from 'resources/Chats/Message';
import { usersOnline } from 'store/online';
import { getUserProfileIndexById } from 'utils/prepare';
import { selectors } from './selectors';
import { thunks } from './thunks';

const { HANDLE_USER } = usersOnline.actions;

const initialState = {
  listMsg: [],
  listMsgPag: {
    page: 1,
    perPage: 10,
  },
  listOldMsg: [],
  listOldMsgPag: {
    page: 1,
    perPage: 10,
  },
  listUnreadMsg: [],
  listNewMsg: [],
  //
  currentRoomId: null,
  targetUserId: null,
  profile: {
    _id: '',
    firstname: '',
    lastname: '',
    isOnline: false,
    roomColor: 'ffffff',
  },
  project: null,
  listMsgStatus: status.IDLE,
  listOldMsgStatus: status.IDLE,
  markAsReadStatus: status.IDLE,
  sendMessageStatus: status.IDLE,
  fileUploadStatus: status.IDLE,
  getChatRoomByIdStatus: status.IDLE,
  deleteOwnMessageStatus: status.IDLE,
  sendingReactionStatus: status.IDLE,
  takingReactionBackStatus: status.IDLE,
  markReactionsAsReadStatus: status.IDLE,
};

const writeToListAsRead = (state, nameOfList, msgIds) => {
  state[nameOfList] = state[nameOfList].map((msg) => {
    if (!msg.isRead && msgIds.includes(msg._id)) {
      return { ...msg, isRead: true };
    }

    return { ...msg };
  });
};

export const slice = createSlice({
  name: 'chatsRoom',
  initialState: { ...initialState },
  reducers: {
    SET_ROOM_ID: (state, { payload }) => {
      state.currentRoomId = payload;
    },
    SET_TARGET_USER_ID: (state, { payload }) => {
      state.targetUserId = payload;
    },
    SET_PROFILE: (state, { payload }) => {
      state.profile = { ...payload };
    },
    SET_NEW_MSG: (state, { payload }) => {
      console.log('new message ==>', payload);
      if (state.currentRoomId === payload.message.roomId) {
        const newMsg = new ChatMessage(
          { ...payload.message, nameOfList: 'listNewMsg' },
          state.targetUserId
        );

        state.listNewMsg.push(newMsg.getMessage());
      }
    },
    SET_NEW_REACTION: (state, { payload }) => {
      if (state.currentRoomId === payload.message.roomId) {
        const message = [
          ...state.listMsg,
          ...state.listOldMsg,
          ...state.listNewMsg,
        ].find((message) => message._id === payload.message._id);

        const currentArray = state[message.nameOfList];

        const updatedMessage = currentArray.find(
          (message) => message._id === payload.message._id
        );

        if (updatedMessage) {
          state[message.nameOfList] = currentArray.map((message) => {
            if (message._id === updatedMessage._id) {
              if (payload.action === 'add') {
                return { ...message, reaction: payload.message.reaction };
              }
              delete message.reaction;
              return message;
            }
            return message;
          });
        }
      }
    },
    SET_MSG_IS_READ: (state, { payload }) => {
      if (state.listMsg.length > 0)
        writeToListAsRead(state, 'listMsg', payload.msgIds);
      if (state.listNewMsg.length > 0)
        writeToListAsRead(state, 'listNewMsg', payload.msgIds);
    },
    REACT_ON_MESSAGE: (state, { payload }) => {
      const currentArray = state[payload.nameOfList];

      const reactedMessage = currentArray.find(
        (message) => message._id === payload._id
      );

      if (reactedMessage) {
        state[payload.nameOfList] = currentArray.map((message) => {
          if (message._id === reactedMessage._id) {
            return { ...message, reaction: payload.reaction };
          }

          return { ...message };
        });
      }
    },
    TAKE_REACTION_BACK: (state, { payload }) => {
      const currentArray = state[payload.nameOfList];

      const unreactedMessage = currentArray.find(
        (message) => message._id === payload._id
      );

      if (unreactedMessage) {
        state[payload.nameOfList] = currentArray.map((message) => {
          if (message._id === unreactedMessage._id) {
            delete message.reaction;
            return message;
          }

          return message;
        });
      }
    },
    DELETE_OWN_MESSAGE: (state, { payload }) => {
      const currentArray = state[payload.nameOfList];

      const deletedMessage = currentArray.find(
        (message) => message._id === payload._id
      );

      if (deletedMessage) {
        state[payload.nameOfList] = currentArray.filter(
          (message) => message._id !== deletedMessage._id
        );
      }
    },
    RESET_ROOM_ID: (state) => {
      state.currentRoomId = null;
    },
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    RESET_NEW_MESSAGES: (state) => {
      state.listNewMsg = [];
    },
    RESET_STATE: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getMessages.pending, (state) => {
        state.listMsgStatus = status.PENDING;
      })
      .addCase(thunks.getMessages.fulfilled, (state, { payload }) => {
        state.listMsg = payload.messages.map((msg) => {
          const newMsg = new ChatMessage(msg, state.targetUserId);
          return newMsg.getMessage();
        });

        // unread messages
        state.listUnreadMsg = payload.UnreadMessages?.map((msg) => {
          const newMsg = new ChatMessage(msg, state.targetUserId);
          return newMsg.getMessage();
        });

        state.listOldMsgPag = {
          ...state.listOldMsgPag,
          page: (state.listOldMsgPag.page += 1),
        };

        state.listMsgStatus = status.SUCCESS;
        state.listOldMsgStatus = status.SUCCESS;
      })
      .addCase(thunks.getMessages.rejected, (state, { payload }) => {
        if (payload) {
          const { statusCode } = payload;
          if (statusCode === 406) {
            state.listMsgStatus = status.IDLE;
            return;
          }
        }

        state.listMsgStatus = status.FAIL;
      })

      .addCase(thunks.loadOldMessages.pending, (state) => {
        state.listOldMsgStatus = status.PENDING;
      })
      .addCase(thunks.loadOldMessages.fulfilled, (state, { payload }) => {
        if (!payload.length) {
          state.listOldMsgStatus = status.IDLE;
          return;
        }

        const messages = payload?.map((msg) => {
          const newMsg = new ChatMessage(
            { ...msg, nameOfList: 'listOldMsg' },
            state.targetUserId
          );
          return newMsg.getMessage();
        });

        state.listOldMsg.unshift(...messages);
        state.listOldMsgPag = {
          ...state.listOldMsgPag,
          page: (state.listOldMsgPag.page += 1),
        };

        state.listOldMsgStatus = status.SUCCESS;
      })
      .addCase(thunks.loadOldMessages.rejected, (state) => {
        state.listOldMsgStatus = status.FAIL;
      })

      .addCase(thunks.sendMessage.pending, (state) => {
        state.sendMessageStatus = status.PENDING;
      })
      .addCase(thunks.sendMessage.fulfilled, (state, { payload }) => {
        if (!state.currentRoomId) {
          state.currentRoomId = payload.roomId;
        } else {
          const newMsg = new ChatMessage({
            _id: payload._id,
            createdAt: payload.createdAt,
            message: payload.message,
            isRead: payload.isRead,
            userId: payload.userId,
            photos: payload.photos,
            files: payload.files,
            nameOfList: 'listNewMsg',
          });
          state.listNewMsg.push(newMsg.setNewMessage());
        }

        state.sendMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.sendMessage.rejected, (state) => {
        state.sendMessageStatus = status.FAIL;
      })

      .addCase(thunks.markAsRead.pending, (state) => {
        state.markAsReadStatus = status.PENDING;
      })
      .addCase(thunks.markAsRead.fulfilled, (state, { payload }) => {
        if (state.listUnreadMsg.length > 0)
          writeToListAsRead(state, 'listUnreadMsg', payload.messagesIds);
        if (state.listNewMsg.length > 0)
          writeToListAsRead(state, 'listNewMsg', payload.messagesIds);

        state.markAsReadStatus = status.SUCCESS;
      })
      .addCase(thunks.markAsRead.rejected, (state) => {
        state.markAsReadStatus = status.FAIL;
      })

      .addCase(thunks.getChatRoomById.pending, (state) => {
        state.getChatRoomByIdStatus = status.PENDING;
      })
      .addCase(thunks.getChatRoomById.fulfilled, (state, { payload }) => {
        Object.assign(state, initialState);
        const userIdx = getUserProfileIndexById(
          payload.profile,
          payload.managerId
        );
        state.currentRoomId = payload._id;
        state.targetUserId = payload.profile[userIdx]._id;
        state.profile = {
          ...payload.profile[userIdx],
          roomColor: payload.config.roomColor,
        };
        if (payload.project) state.project = payload.project;
        state.getChatRoomByIdStatus = status.SUCCESS;
      })
      .addCase(thunks.getChatRoomById.rejected, (state) => {
        state.getChatRoomByIdStatus = status.FAIL;
      })

      .addCase(thunks.deleteOwnMessage.pending, (state) => {
        state.deleteOwnMessageStatus = status.PENDING;
      })
      .addCase(thunks.deleteOwnMessage.fulfilled, (state) => {
        state.deleteOwnMessageStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteOwnMessage.rejected, (state) => {
        state.deleteOwnMessageStatus = status.FAIL;
      })

      // TODO: move thunk to general, slice, thunk, selector
      .addCase(thunks.fileUpload.pending, (state) => {
        state.fileUploadStatus = status.PENDING;
      })
      .addCase(thunks.fileUpload.fulfilled, (state) => {
        state.fileUploadStatus = status.SUCCESS;
      })
      .addCase(thunks.fileUpload.rejected, (state) => {
        state.fileUploadStatus = status.FAIL;
      })

      .addCase(thunks.reactOnMessage.pending, (state) => {
        state.sendingReactionStatus = status.PENDING;
      })
      .addCase(thunks.reactOnMessage.fulfilled, (state) => {
        state.sendingReactionStatus = status.SUCCESS;
      })
      .addCase(thunks.reactOnMessage.rejected, (state) => {
        state.sendingReactionStatus = status.FAIL;
      })

      .addCase(thunks.takeReactionBack.pending, (state) => {
        state.takingReactionBackStatus = status.PENDING;
      })
      .addCase(thunks.takeReactionBack.fulfilled, (state) => {
        state.takingReactionBackStatus = status.SUCCESS;
      })
      .addCase(thunks.takeReactionBack.rejected, (state) => {
        state.takingReactionBackStatus = status.FAIL;
      })

      .addCase(thunks.markReactionsAsRead.pending, (state) => {
        state.markReactionsAsReadStatus = status.PENDING;
      })
      .addCase(thunks.markReactionsAsRead.fulfilled, (state) => {
        state.markReactionsAsReadStatus = status.SUCCESS;
      })
      .addCase(thunks.markReactionsAsRead.rejected, (state) => {
        state.markReactionsAsReadStatus = status.FAIL;
      })

      .addCase(HANDLE_USER, (state, { payload }) => {
        const activeChat = state.profile._id === payload.id;
        if (activeChat) {
          if (payload.action === 'add') {
            state.profile.isOnline = true;
          }

          if (payload.action === 'remove') {
            state.profile.isOnline = false;
            state.profile.updatedAt = Date.now(new Date());
          }
        }
      });
  },
});

const chatsRoom = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { chatsRoom };
export default slice.reducer;
