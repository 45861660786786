import { Flex } from 'components/App/GlobalStyled';
import styled from 'styled-components';

const Footer = styled.div`
  ${Flex({ justify: 'space-between' })}
  padding-block: 8px;
  margin-top: 30px;
`;

export { Footer };
