import yup from 'plugins/yup-config';

const defaultValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
};

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  password: yup.string().min(8).max(32).required(),
});

export { schema, defaultValues };
