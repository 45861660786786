const selectors = {
  data: (state) => state.userPage.data,
  projectsPagination: (state) => state.userPage.projectsPagination,
  invoicesPagination: (state) => state.userPage.invoicesPagination,
  addingBoatStatus: (state) => state.userPage.addingBoatStatus,
  editUserStatus: (state) => state.userPage.editUserStatus,
  fetchingStatus: (state) => state.userPage.fetchingStatus,
  fetchingProjectsStatus: (state) => state.userPage.fetchingProjectsStatus,
  fetchingInvoicesStatus: (state) => state.userPage.fetchingInvoicesStatus,
  fetchingDocumentsStatus: (state) => state.userPage.fetchingDocumentsStatus,
  uploadingDocumentsStatus: (state) => state.userPage.uploadingDocumentsStatus,
  deleteDocumentStatus: (state) => state.userPage.deleteDocumentStatus,
};

export { selectors };
