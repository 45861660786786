import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getInvoices = createAsyncThunk(
  'manager/getInvoices',
  async ({ pageIndex, pageSize }) => {
    try {
      const { data } = await api.invoicesPage.getInvoices({
        perPage: pageSize,
        page: pageIndex,
      });
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createInvoice = createAsyncThunk(
  'manager/createInvoice',
  async ({ formData }) => {
    try {
      const { data } = await api.invoicesPage.createInvoice(formData);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getInvoices,
  createInvoice,
};

export { thunks };
