import styled from 'styled-components';

const Wrap = styled.div`
  padding: 56px 56px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export { Wrap };
