export const boatModel = (
  _id = '',
  name = '',
  length = 0,
  photo = '',
  userId = '',
  manufacturer = ''
) => {
  return { _id, name, length, photo, userId, manufacturer };
};
