import PropTypes from 'prop-types';

import { prepareFullPhotoPath } from 'utils/prepare';
import LogoPlaceholder from 'assets/img/global/logo/logo.svg';
import { Wrap, Image, FileName } from './styled';

RFileComponent.propTypes = {
  ellipsisWidth: PropTypes.number,
  thumb: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};

export function RFileComponent({ ellipsisWidth, thumb, name, url }) {
  return (
    <Wrap>
      <Image src={thumb || LogoPlaceholder} />
      &nbsp;
      <FileName
        width={ellipsisWidth}
        href={prepareFullPhotoPath(url)}
        target="_blank"
        download
        rel="noreferrer"
      >
        {name}
      </FileName>
    </Wrap>
  );
}
