import io from 'socket.io-client';
import store from 'store';
import { toast } from 'react-toastify';

import { notificationTypes, status } from 'utils/const';
import { usersOnline } from 'store/online';
import { userNotifications } from 'store/notifications';
import { chats } from 'store/chats';
import { getManagerId } from 'utils/prepare';
import { projectDetails } from 'store/manager/projectDetails';

class InitNotificationSocket {
  constructor({ jwtToken }) {
    this.token = jwtToken;
    this.namespace = 'notification';
    this.socket = null;
    this.timeoutNotification = null;
    this.timeoutSeconds = 15000;
  }

  init() {
    if (!this.token) {
      console.error('=> Token not found. Sockets are not connected');
      toast.error('Problems with permissions. Sockets are not connected');
      store.dispatch(userNotifications.actions.SET_SOCKET_STATUS(status.FAIL));
      store.dispatch(usersOnline.actions.SET_SOCKET_STATUS(status.FAIL));
      return;
    }

    this.socket = io(`${window.location.hostname}/${this.namespace}`, {
      reconnectionDelayMax: 10000,
      extraHeaders: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    this.socket.on('connect', () => {
      console.log(
        '%c=> Connected notification socket',
        'color: limegreen',
        this.socket.connected
      );
      store.dispatch(
        userNotifications.actions.SET_SOCKET_STATUS(status.SUCCESS)
      );
      store.dispatch(usersOnline.actions.SET_SOCKET_STATUS(status.SUCCESS));
    });

    this.socket.on('close', (payload) => {
      console.log('=> Socket notification was closed', payload);
    });

    this.socket.on('disconnect', (data) => {
      console.log(
        '%c=> Socket notification disconnected!',
        'color: red',
        data,
        this.socket
      );
      store.dispatch(userNotifications.actions.SET_SOCKET_STATUS(status.FAIL));
      store.dispatch(usersOnline.actions.SET_SOCKET_STATUS(status.FAIL));
    });

    this.socket.on('connect_error', (err) => {
      console.error('=> Socket notification connect_error:', err);
      store.dispatch(userNotifications.actions.SET_SOCKET_STATUS('error'));
      store.dispatch(usersOnline.actions.SET_SOCKET_STATUS('error'));
    });
  }

  connectUsersToSystem() {
    if (!this.socket) return;
    const managerId = getManagerId();

    this.socket.on('login', (userId) => {
      console.log('%c=> USER ONLINE', 'color: limegreen', userId);
      store.dispatch(
        usersOnline.actions.HANDLE_USER({
          id: userId,
          managerId,
          action: 'add',
        })
      );
    });

    this.socket.on('logout', (userId) => {
      console.log('%c=> USER OFFLINE', 'color: limegreen', userId);
      store.dispatch(
        usersOnline.actions.HANDLE_USER({
          id: userId,
          managerId,
          action: 'remove',
        })
      );
    });
  }

  connectToNotificationSystem() {
    if (!this.socket) return;

    const onUpdateProjectChats = async () => {
      await store.dispatch(chats.actions.RESET_PAGINATION());
      await store.dispatch(chats.thunks.getProjectChats());
    };

    this.socket.on('notification.project.file.update', (data) => {
      console.log('%c=> PROJECT FILE UPDATED', 'color: limegreen', data);
      store.dispatch(
        userNotifications.actions.SET_NEW_NOTIFICATION({
          data: { ...data },
          type: notificationTypes.PROJECT_UPDATE,
        })
      );
    });

    this.socket.on('notification.note.create', (data) => {
      store.dispatch(
        userNotifications.actions.SET_NEW_NOTIFICATION({
          data,
          type: notificationTypes.NOTE,
        })
      );
    });

    this.socket.on('notification.project.create', (data) => {
      console.log('%c=> PROJECT CREATED', 'color: limegreen', data);
      store.dispatch(
        userNotifications.actions.SET_NEW_NOTIFICATION({
          data,
          type: notificationTypes.PROJECT,
        })
      );

      if (window.location.pathname.includes('/manager/inbox/project')) {
        onUpdateProjectChats();
      }
    });

    this.socket.on('notification.payment.success', (data) => {
      console.log('%c=> INVOICE PAID', 'color: limegreen', data);
      store.dispatch(
        userNotifications.actions.SET_NEW_NOTIFICATION({
          data,
          type: notificationTypes.INVOICE_PAID,
        })
      );
    });

    this.socket.on('notification.project.canceled', (data) => {
      console.log('%c=> PROJECT CANCELED', 'color: limegreen', data);
      store.dispatch(
        userNotifications.actions.SET_NEW_NOTIFICATION({
          data,
          type: notificationTypes.PROJECT_CANCELED,
        })
      );
      store.dispatch(chats.actions.CANCEL_CURRENT_CHAT(data));
      store.dispatch(projectDetails.actions.CANCEL_CURRENT_PROJECT(data));
    });
  }

  socketDisconnect() {
    this.socket.disconnect();
    this.token = null;
    this.socket = null;
  }
}

export default InitNotificationSocket;
