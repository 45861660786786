import { MemoizedOwnerCell } from 'pages/Manager/BoatsPage/Table/OwnerCell';
import { MemoizedManufacturerCell } from 'pages/Manager/BoatsPage/Table/ManufacturerCell';
import { MemoizedTextEllipsesCell } from 'components/RTable/SharedCells/TextEllipsesCell';

export const columns = [
  {
    Header: 'Boat name',
    accessor: 'name',
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Owner name',
    accessor: 'profile',
    Cell: MemoizedOwnerCell,
  },
  {
    Header: 'Boat made',
    accessor: 'manufacturer',
    Cell: MemoizedManufacturerCell,
  },
  {
    Header: '',
    accessor: 'empty-1',
  },
  {
    Header: '',
    accessor: 'empty-2',
  },
];
