import yup from 'plugins/yup-config';

import { transformNaN } from 'utils/prepare';

const defaultValues = {
  name: '',
  manufacturer: '',
  length: null,
};

const schema = yup.object().shape({
  name: yup.string().required(),
  manufacturer: yup.string().required(),
  length: yup.number().required().nullable().transform(transformNaN),
});

export { schema, defaultValues };
