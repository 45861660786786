import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const projectDetails = {
  getProjects(projectId) {
    return http.get(
      WEB_API_ROUTES.manager.projectDetails.getProjects.replace(
        '{_id}',
        projectId
      )
    );
  },
  updateStatusProject(projectId, data) {
    return http.put(
      WEB_API_ROUTES.manager.projectDetails.getProjects.replace(
        '{_id}',
        projectId
      ),
      data
    );
  },

  createAppointment(projectId, data) {
    return http.post(
      WEB_API_ROUTES.manager.projectDetails.createAppointment.replace(
        '{_id}',
        projectId
      ),
      data
    );
  },

  editAppointment(projectId, data) {
    return http.put(
      WEB_API_ROUTES.manager.projectDetails.createAppointment.replace(
        '{_id}',
        projectId
      ),
      data
    );
  },

  createInvoice(projectId, data) {
    return http.post(
      WEB_API_ROUTES.manager.projectDetails.createInvoice.replace(
        '{_id}',
        projectId
      ),
      data
    );
  },
};
