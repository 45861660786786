import styled, { css } from 'styled-components';
import { Flex } from 'components/App/GlobalStyled';

const ContextMenuContainer = styled.ul`
  border-radius: 8px;
  padding: 6px;
  width: 190px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  top: -20px;
  right: 0;
  background-color: white;

  ${({ position }) =>
    position
      ? css`
          top: ${position.top}px;
          left: ${position.left}px;
        `
      : null}
`;

const MenuItem = styled.li`
  ${Flex};
  gap: 5px;
  padding: 6px;
  font-weight: 500;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(88, 115, 101, 0.4);
  }
`;

export { ContextMenuContainer, MenuItem };
