import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getBoatInfo = createAsyncThunk(
  'manager/getBoatInfo',
  async ({ boatId }) => {
    try {
      const { data } = await api.boatPage.getBoatInfo(boatId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editBoat = createAsyncThunk(
  'manager/editBoat',
  async ({ formData, boatId }) => {
    try {
      const { data } = await api.boatPage.editBoat(formData, boatId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getBoatInfo,
  editBoat,
};

export { thunks };
