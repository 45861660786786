import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const boatPage = {
  getBoatInfo(boatId) {
    return http.get(
      WEB_API_ROUTES.manager.boatPage.getBoatInfo.replace('{_id}', boatId)
    );
  },
  editBoat(formData, boatId) {
    return http.put(
      WEB_API_ROUTES.manager.boatPage.editBoat.replace('{_id}', boatId),
      formData
    );
  },
};
