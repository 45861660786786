import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Circle, Flex, TextEllipsis } from 'components/App/GlobalStyled';

const Sidebar = styled.aside`
  display: grid;
  grid-template-rows: 10% 10% 65% 15%;
  width: 288px;
  height: 100%;
  padding: 32px 40px;
  flex-shrink: 0;
  overflow-y: auto;
`;

const Header = styled.div`
  ${Flex({ justify: 'space-between', align: 'flex-start' })};
  margin-bottom: 16px;

  & > svg:first-of-type {
    margin: 0;
    width: 64px;
  }
`;

const Notifications = styled.div`
  ${Flex};
  position: relative;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const NotificationsCount = styled.div`
  ${Circle({ size: '8px' })};
  position: absolute;
  top: -3px;
  right: 5px;
  font-weight: bold;
  font-size: 10px;
  color: ${({ theme }) => theme.black};

  ${({ wider }) =>
    wider &&
    css`
      padding-inline: 10px;
    `}
  ${({ css }) => css}
`;

const UserName = styled.p`
  ${TextEllipsis({ width: '208px' })};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
`;

const ItemLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.greyCloud};

  &.active {
    color: ${({ theme }) => theme.white};

    svg {
      fill: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    text-decoration: none;
  }
`;

const SubLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.white};

  svg {
    fill: ${({ theme }) => theme.white};
  }

  &:hover {
    text-decoration: none;
  }
`;

const ItemIcon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ItemText = styled.div`
  padding: 13px 13px 13px 0;
  margin-left: 18px;
`;

const Logout = styled.div`
  ${Flex({ justify: 'center', align: 'center' })}
  color: ${({ theme }) => theme.white};

  span {
    cursor: pointer;
  }
`;

export {
  Sidebar,
  Header,
  Notifications,
  NotificationsCount,
  UserName,
  ItemWrap,
  ItemLink,
  SubLinks,
  ItemIcon,
  ItemText,
  Logout,
};
