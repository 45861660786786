const selectors = {
  list: (state) => state.chats.list,
  listPag: (state) => state.chats.listPag,
  listStatus: (state) => state.chats.listStatus,
  unreadCount: (state) => state.chats.unreadCount,
  activeProjectTab: (state) => state.chats.activeProjectTab,
  chatsListMoreStatus: (state) => state.chats.chatsListMoreStatus,
  socketStatus: (state) => state.chats.socketStatus,
};

export { selectors };
