import styled from 'styled-components';

import { ScrollBar } from 'components/App/GlobalStyled';

const AttachedFiles = styled.div`
  ${ScrollBar};
  overflow-y: scroll;
  max-height: 200px;
  margin-bottom: 10px;
`;

const Delete = styled.div`
  cursor: pointer;
`;

export { AttachedFiles, Delete };
