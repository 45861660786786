import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  data: {
    user: {
      mail: '',
      phone: '',
      lastname: '',
      firstname: '',
    },
    boats: [],
    projects: [],
    invoices: [],
    documents: [],
  },
  projectsPagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  invoicesPagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  fetchingStatus: status.IDLE,
  editUserStatus: status.IDLE,
  addingBoatStatus: status.IDLE,
  fetchingProjectsStatus: status.IDLE,
  fetchingInvoicesStatus: status.IDLE,
  fetchingDocumentsStatus: status.IDLE,
  uploadingDocumentsStatus: status.IDLE,
  deleteDocumentStatus: status.IDLE,
};

const slice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getUserInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(
        thunks.getUserInfo.fulfilled,
        (state, { payload: { user, boats } }) => {
          state.data.user = user;
          state.data.boats = boats;
          state.fetchingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getUserInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.addBoat.pending, (state) => {
        state.addingBoatStatus = status.PENDING;
      })
      .addCase(thunks.addBoat.fulfilled, (state) => {
        state.addingBoatStatus = status.SUCCESS;
      })
      .addCase(thunks.addBoat.rejected, (state) => {
        state.addingBoatStatus = status.FAIL;
      })

      .addCase(thunks.getUserProjects.pending, (state) => {
        state.fetchingProjectsStatus = status.PENDING;
      })
      .addCase(thunks.getUserProjects.fulfilled, (state, { payload }) => {
        state.data.projects = payload.projects;
        state.projectsPagination = {
          ...state.projectsPagination,
          totalItems: payload.count,
        };
        state.fetchingProjectsStatus = status.SUCCESS;
      })
      .addCase(thunks.getUserProjects.rejected, (state) => {
        state.fetchingProjectsStatus = status.FAIL;
      })

      .addCase(thunks.getUserInvoices.pending, (state) => {
        state.fetchingInvoicesStatus = status.PENDING;
      })
      .addCase(thunks.getUserInvoices.fulfilled, (state, { payload }) => {
        state.data.invoices = payload.data;
        state.invoicesPagination = {
          ...state.invoicesPagination,
          totalItems: payload.count,
        };
        state.fetchingInvoicesStatus = status.SUCCESS;
      })
      .addCase(thunks.getUserInvoices.rejected, (state) => {
        state.fetchingInvoicesStatus = status.FAIL;
      })

      .addCase(thunks.getUserDocuments.pending, (state) => {
        state.fetchingDocumentsStatus = status.PENDING;
      })
      .addCase(thunks.getUserDocuments.fulfilled, (state, { payload }) => {
        state.data.documents = payload;
        state.fetchingDocumentsStatus = status.SUCCESS;
      })
      .addCase(thunks.getUserDocuments.rejected, (state) => {
        state.fetchingDocumentsStatus = status.FAIL;
      })

      .addCase(thunks.uploadDocuments.pending, (state) => {
        state.uploadingDocumentsStatus = status.PENDING;
      })
      .addCase(thunks.uploadDocuments.fulfilled, (state) => {
        state.uploadingDocumentsStatus = status.SUCCESS;
      })
      .addCase(thunks.uploadDocuments.rejected, (state) => {
        state.uploadingDocumentsStatus = status.FAIL;
      })

      .addCase(thunks.deleteDocument.pending, (state) => {
        state.deleteDocumentStatus = status.PENDING;
      })
      .addCase(thunks.deleteDocument.fulfilled, (state) => {
        state.deleteDocumentStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteDocument.rejected, (state) => {
        state.deleteDocumentStatus = status.FAIL;
      })

      .addCase(thunks.editUser.pending, (state) => {
        state.editUserStatus = status.PENDING;
      })
      .addCase(thunks.editUser.fulfilled, (state, { payload }) => {
        state.data.user = { ...payload };
        state.editUserStatus = status.SUCCESS;
      })
      .addCase(thunks.editUser.rejected, (state) => {
        state.editUserStatus = status.FAIL;
      });
  },
});

const userPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { userPage };
export default slice.reducer;
