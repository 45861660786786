import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getProjectDetails = createAsyncThunk(
  'manager/getProjectDetails',
  async (projectId) => {
    try {
      const { data } = await api.projectDetails.getProjects(projectId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateStatusProject = createAsyncThunk(
  'manager/updateStatusProject',
  async (data) => {
    const { projectId, project } = data;
    try {
      const { data } = await api.projectDetails.updateStatusProject(
        projectId,
        project
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createAppointment = createAsyncThunk(
  'manager/createAppointment',
  async (data) => {
    const { id, appointment } = data;
    try {
      const { data } = await api.projectDetails.createAppointment(
        id,
        appointment
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editAppointment = createAsyncThunk(
  'manager/editAppointment',
  async (data) => {
    const { id, appointment } = data;
    try {
      const { data } = await api.projectDetails.editAppointment(
        id,
        appointment
      );
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createInvoice = createAsyncThunk(
  'manager/createInvoice',
  async (data) => {
    const { id, invoice } = data;
    try {
      const { data } = await api.projectDetails.createInvoice(id, invoice);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getProjectDetails,
  updateStatusProject,
  createAppointment,
  editAppointment,
  createInvoice,
};

export { thunks };
