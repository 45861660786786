import { Table } from 'pages/Manager/BoatsPage/Table';
import { PageTitle, PageDesc, Header } from 'pages/_shared/styled';
import { Wrap } from './styled';

function BoatsPage() {
  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Boats list</PageTitle>
          <PageDesc>List of boats added to the system</PageDesc>
        </div>
      </Header>
      <Table />
    </Wrap>
  );
}

export default BoatsPage;
