import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { RTable } from 'components/RTable';
import { useTablePagination } from 'hooks/useTablePagination';
import { projectPage } from 'store/manager/projectsPage';
import { status } from 'utils/const';
import { columns } from './config';

export function Table() {
  const dispatch = useDispatch();
  const history = useHistory();
  const listProjects = useSelector(projectPage.selectors.list);
  const projectStatus = useSelector(projectPage.selectors.projectStatus);

  const {
    page,
    perPage,
    totalItems: totalRows,
  } = useSelector(projectPage.selectors.pagination);

  const { fetchData, pageCount, pageIndex, pageSize } = useTablePagination({
    totalRows,
    perPage,
    deps: [page, listProjects],
  });

  useEffect(() => {
    dispatch(projectPage.thunks.getProjects({ pageIndex, pageSize }));
  }, [pageIndex, pageSize]);

  const onRowClick = (row) => () => {
    history.push(`/manager/projects/${row.original._id}`);
  };

  return (
    <RTable
      loading={status.PENDING === projectStatus}
      data={listProjects}
      height={620}
      fetchData={fetchData}
      totalRows={totalRows}
      pageCount={pageCount}
      columns={columns}
      onRowClick={onRowClick}
      isClickableRow
    />
  );
}
