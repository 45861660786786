import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system';
import { createContext, createRef, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import InvoiceDetailsPage from 'pages/Manager/InvoicePage';
import ProtectedRoute from 'middleware/ProtectedRoute';
import UserInfoPage from 'pages/Manager/UserPage';
import NotFoundPage from 'pages/General/NotFound';
import UsersPage from 'pages/Manager/UsersPage';
import InboxPage from 'pages/Manager/InboxPage';
import BoatsPage from 'pages/Manager/BoatsPage';
import ProjectDetails from 'pages/Manager/ProjectDetails';
import ProjectsPage from 'pages/Manager/ProjectsPage';
import InvoicesPage from 'pages/Manager/InvoicesPage';
import BoatInfoPage from 'pages/Manager/BoatPage';
import { useNotificationSystem } from 'hooks/useNotificationSystem';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { Notifications } from 'components/Notifications';
import { AppIsCrashed } from 'components/AppIsCrashed';
import { RSidebar } from 'components/RSidebar';
import InitNotificationSocket from 'api/sockets/initNotifications';
import InitChatSocket from 'api/sockets/initChat';
import { managerSidebarLinks, status } from 'utils/const';
import { appRoles } from 'utils/roles';
import { auth } from 'store/auth';
import { userNotifications } from 'store/notifications';
import { Layout, Main, Content } from './styled';

export const ManagerLayoutContext = createContext({});

function ManagerLayout() {
  const dispatch = useDispatch();

  const mainRef = useRef();
  const notificationsRef = createRef();

  const newNotification = useSelector(
    userNotifications.selectors.newNotification
  );
  const listIsVisible = useSelector(userNotifications.selectors.listIsVisible);
  const token = useSelector(auth.selectors.token);
  const loginStatus = useSelector(auth.selectors.loginStatus);

  const { styles } = useNotificationSystem(newNotification, notificationsRef);

  const contextValue = useMemo(() => ({ mainRef }), [mainRef]);

  const isAppReady = loginStatus === status.SUCCESS;

  const isAppFail = loginStatus === status.FAIL;

  const handleListIsVisible = () => {
    dispatch(userNotifications.actions.TOGGLE_LIST_VISIBLE());
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAppReady) {
      const notificationSocket = new InitNotificationSocket({
        jwtToken: token,
      });
      notificationSocket.init();
      notificationSocket.connectUsersToSystem();
      notificationSocket.connectToNotificationSystem();
      return () => notificationSocket.socketDisconnect();
    }
  }, []);

  useEffect(() => {
    const chatSocket = new InitChatSocket({ jwtToken: token });
    chatSocket.init();
    chatSocket.connectToRoom();

    return () => chatSocket.socketDisconnect();
  }, []);

  useEffect(() => {
    dispatch(userNotifications.thunks.getNotifications());
  }, []);

  return (
    <ProtectedRoute>
      <RoleChecker>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isAppReady ? (
          <Layout>
            <RSidebar links={managerSidebarLinks} />
            <ManagerLayoutContext.Provider value={contextValue}>
              <Main ref={mainRef}>
                {listIsVisible && (
                  <Notifications
                    onClose={handleListIsVisible}
                    isVisible={listIsVisible}
                  />
                )}
                <NotificationSystem
                  ref={notificationsRef}
                  style={styles}
                />
                <div id="modal-portal" />
                <Content>
                  <Switch>
                    {/* Users */}
                    <Route
                      exact
                      path="/manager/users"
                      component={UsersPage}
                    />
                    <Route
                      path="/manager/users/:userId"
                      component={UserInfoPage}
                    />

                    {/* Inbox */}
                    <Route
                      path="/manager/inbox/:chatType/:roomId?"
                      component={InboxPage}
                    />

                    {/* Boats */}
                    <Route
                      exact
                      path="/manager/boats"
                      component={BoatsPage}
                    />
                    <Route
                      path="/manager/boats/:boatId"
                      component={BoatInfoPage}
                    />

                    {/* Project */}
                    <Route
                      exact
                      path="/manager/projects"
                      component={ProjectsPage}
                    />
                    <Route
                      exact
                      path="/manager/projects/:projectId"
                      component={ProjectDetails}
                    />

                    {/* Invoice */}
                    <Route
                      exact
                      path="/manager/invoices"
                      component={InvoicesPage}
                    />
                    <Route
                      path="/manager/invoices/:invoiceId"
                      component={InvoiceDetailsPage}
                    />

                    <Route component={NotFoundPage} />
                  </Switch>
                </Content>
              </Main>
            </ManagerLayoutContext.Provider>
          </Layout>
        ) : !isAppFail ? (
          <RLoadingOverlay isVisible />
        ) : (
          <AppIsCrashed />
        )}
      </RoleChecker>
    </ProtectedRoute>
  );
}

RoleChecker.propTypes = {
  children: PropTypes.node,
};

function RoleChecker({ children }) {
  const role = useSelector(auth.selectors.role);
  if (role !== appRoles.MANAGER) return <Redirect to="/" />;
  return children;
}

export default ManagerLayout;
