import { boatPage } from 'api/modules/manager/boatPage';
import { invoicePage } from 'api/modules/manager/invoicePage';
import { adminManagersPage } from 'api/modules/admin/managersPage';
import { adminUsersPage } from 'api/modules/admin/usersPage';
import { userNotifications } from './modules/manager/notifications';
import { chats } from './modules/chats/chats';
import { auth } from './modules/auth/auth';
import { userPage } from './modules/manager/userPage';
import { usersPage } from './modules/manager/usersPage';
import { projectPage } from './modules/manager/projectPage';
import { projectDetails } from './modules/manager/projectDetails';
import { invoicesPage } from './modules/manager/invoicesPage';
import { boatsPage } from './modules/manager/boatsPage';

export const api = {
  // manager
  auth,
  chats,
  userPage,
  usersPage,
  userNotifications,
  projectPage,
  projectDetails,
  invoicesPage,
  invoicePage,
  boatsPage,
  boatPage,

  // admin
  adminUsersPage,
  adminManagersPage,
};
