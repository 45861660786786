import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { throttle } from 'utils/throttle';
import { chatsRoom } from 'store/chats/room';
import { status } from 'utils/const';
import { userNotifications } from 'store/notifications';
import { useDocumentVisibility } from 'hooks/useDocumentVisibility';
import { useParams } from 'react-router-dom';
import { PinnedDate } from './PinnedDate';
import { Message } from './Message';
import {
  Wrap,
  NoMessages,
  MessagesContainer,
  NewMessages,
  NewMessagesText,
} from './styled';

export function Messages() {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const { roomId } = useParams();
  const debounce = useRef(null);
  const unreadMsgContainerRef = useRef();
  const timerRef = useRef(null);

  const [pinnedDate, setPinnedDate] = useState();
  const [pinnedNewMsg, setPinnedNewMsg] = useState(true);
  const [readReactionsMessages, setReadReactionsMessages] = useState([]);

  const listMsg = useSelector(chatsRoom.selectors.listMsg);
  const listUnreadMsg = useSelector(chatsRoom.selectors.listUnreadMsg);
  const listNewMsg = useSelector(chatsRoom.selectors.listNewMsg);
  const listOldMsg = useSelector(chatsRoom.selectors.listOldMsg);
  const listOldMsgStatus = useSelector(chatsRoom.selectors.listOldMsgStatus);

  const isVisible = useDocumentVisibility();

  const scrollToBottom = () => {
    const containerEl = containerRef.current;
    containerEl.scrollTop = containerEl.scrollHeight - containerEl.offsetHeight; // TODO: check scroll sticky height
  };

  const scrollToUnreadMsg = () => {
    const containerEl = containerRef.current;
    const unreadMsgContainerEl = unreadMsgContainerRef.current;
    containerEl.scrollTop =
      containerEl.scrollHeight - unreadMsgContainerEl.offsetHeight - 75;
    // 75 - it's height new msg container
  };

  // eslint-disable-next-line consistent-return
  const onPinnedDate = () => {
    try {
      const containerEl = containerRef.current;
      const { bottom, left } = containerEl.getBoundingClientRect();
      const pickedEL = document.elementFromPoint(left + 20, bottom - 20);
      const messageEl = pickedEL.closest('[data-message-id]');

      const allMessages = [
        ...listOldMsg,
        ...listMsg,
        ...listUnreadMsg,
        ...listNewMsg,
      ];
      const message = allMessages.find(
        (message) => message._id === messageEl.dataset.messageId
      );

      setPinnedDate(message.createdAt);
    } catch (e) {
      return pinnedDate !== undefined && setPinnedDate(undefined);
    }
  };

  const onLoadOldMessages = async () => {
    const containerEl = containerRef.current;
    const roomMsgTop = containerEl.scrollTop === 0;

    if (roomMsgTop && listOldMsgStatus === status.SUCCESS) {
      const prevScrollHeight = containerEl.scrollHeight;
      const scrolled = containerEl.scrollTop;

      await dispatch(chatsRoom.thunks.loadOldMessages());

      const newScrollHeight = containerEl.scrollHeight;
      containerEl.scrollTop = scrolled + newScrollHeight - prevScrollHeight;
    }
  };

  const msgIsRead = (list) => {
    const messagesIds = [];

    const markReadMsg = async () => {
      await dispatch(chatsRoom.thunks.markAsRead(messagesIds));
      dispatch(userNotifications.thunks.getChatUnreadCount());
    };

    list.forEach((msg) => {
      if (!msg.isRead && (msg.type === 'opposite' || msg.type === 'system'))
        messagesIds.push(msg._id);
    });

    if (messagesIds.length > 0) {
      markReadMsg();
    } else {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setPinnedNewMsg(false);
      }, 2000);
    }
  };

  const reactionIsRead = (value) => {
    setReadReactionsMessages((prevState) => [...prevState, value]);
  };

  function markReactionsAsRead() {
    if (readReactionsMessages.length)
      dispatch(
        chatsRoom.thunks.markReactionsAsRead({
          roomId,
          messagesIds: readReactionsMessages,
        })
      ).then(() => {
        setReadReactionsMessages([]);
        dispatch(userNotifications.thunks.getChatUnreadCount());
      });
  }

  function handleContainerScroll() {
    if (!containerRef.current) return;

    onPinnedDate();
    onLoadOldMessages();
  }

  const onDebounce = (delay) => {
    if (debounce.current) clearInterval(debounce.current);
    debounce.current = setTimeout(() => {
      markReactionsAsRead();
    }, delay);
  };

  useEffect(() => {
    if (listUnreadMsg.length > 0) {
      scrollToUnreadMsg();
    } else {
      scrollToBottom();
    }
    onPinnedDate();
  }, []);

  useEffect(() => {
    if (listUnreadMsg.length > 0 && isVisible) {
      msgIsRead(listUnreadMsg);
    }
  }, [listUnreadMsg, isVisible]);

  useEffect(() => {
    if (listNewMsg.length > 0 && isVisible) {
      msgIsRead(listNewMsg);
    }
  }, [listNewMsg, isVisible]);

  useEffect(() => {
    if (listNewMsg.length > 0) {
      const scrollFromBottom = 300;
      const containerEl = containerRef.current;
      const userIsScrolling =
        containerEl.scrollHeight -
          containerEl.offsetHeight -
          containerEl.scrollTop >
        scrollFromBottom;

      if (!userIsScrolling) scrollToBottom();
    }
  }, [listNewMsg]);

  useEffect(() => {
    return () => {
      setPinnedNewMsg(true);
      clearTimeout(timerRef.current);
      dispatch(chatsRoom.actions.RESET_NEW_MESSAGES());
    };
  }, []);

  useEffect(() => {
    onDebounce(1000);
  }, [readReactionsMessages]);

  return (
    <Wrap
      className="custom-scroll"
      ref={containerRef}
      onScroll={throttle(handleContainerScroll, 500)}
    >
      {!listMsg.length && !listNewMsg.length && !listUnreadMsg.length && (
        <NoMessages>No messages yet, start the conversation!</NoMessages>
      )}
      <PinnedDate date={pinnedDate} />
      <MessagesContainer>
        {listOldMsg.map((message) => (
          <div
            key={message._id}
            data-message-id={message._id}
          >
            <Message
              message={message}
              onReadReaction={reactionIsRead}
            />
          </div>
        ))}

        {listMsg.map((message) => (
          <div
            key={message._id}
            data-message-id={message._id}
          >
            <Message
              message={message}
              onReadReaction={reactionIsRead}
            />
          </div>
        ))}

        {listUnreadMsg.length > 0 && (
          <div ref={unreadMsgContainerRef}>
            {pinnedNewMsg && (
              <NewMessages>
                <NewMessagesText>New messages</NewMessagesText>
              </NewMessages>
            )}
            {listUnreadMsg.map((message) => (
              <div
                key={message._id}
                data-message-id={message._id}
              >
                <Message message={message} />
              </div>
            ))}
          </div>
        )}

        {listNewMsg.length > 0 &&
          listNewMsg.map((message) => (
            <div key={`${message.createdAt}${message.message}`}>
              <Message
                message={message}
                onReadReaction={reactionIsRead}
              />
            </div>
          ))}
      </MessagesContainer>
    </Wrap>
  );
}
