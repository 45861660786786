import React from 'react';
import PropTypes from 'prop-types';

import { messageTypes } from 'utils/const';
import { OppositeMessage } from './Opposite';
import { SystemMessage } from './System';
import { OwnMessage } from './Own';

const messageTypesAsArray = Object.values(messageTypes);
const messageComponents = {
  [messageTypes.OWN]: OwnMessage,
  [messageTypes.OPPOSITE]: OppositeMessage,
  [messageTypes.SYSTEM]: SystemMessage,
};

Message.propTypes = {
  message: PropTypes.object,
  onReadReaction: PropTypes.func,
};

export function Message({ message, onReadReaction }) {
  if (!messageTypesAsArray.includes(message.type)) return null; // return layout for 'broken' message

  const MessageComponent = messageComponents[message.type];
  return (
    <MessageComponent
      message={message}
      onReadReaction={onReadReaction}
    />
  );
}
