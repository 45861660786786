export const WEB_API_ROUTES = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    refreshToken: '/auth/fresh',
  },

  manager: {
    notifications: '/notification',
    usersPage: {
      getUsersList: 'manager/user',
      addUser: 'manager/user',
      addBoat: 'manager/boat',
    },
    userPage: {
      getUserInfo: 'manager/user/{_id}',
      getUserProjects: 'manager/user/project/{_id}',
      getUserInvoices: 'manager/invoice/user/{_id}',
      getUserDocuments: 'manager/documents/{_user}',
      uploadDocuments: 'manager/documents/{_user}',
      deleteDocument: 'manager/documents/{_documentId}',
      editUser: 'manager/user/{_id}',
    },
    projectPage: {
      getProjects: 'manager/project',
    },
    projectDetails: {
      getProjects: 'manager/project/{_id}',
      createAppointment: 'manager/project/appointment/{_id}',
      createInvoice: 'manager/invoice/{_id}',
    },
    invoicesPage: {
      getInvoices: 'manager/invoice',
      createInvoice: 'manager/invoice/custom/create',
    },
    invoicePage: {
      getInvoiceDetails: 'manager/invoice/{_id}',
    },
    boatsPage: {
      getBoats: 'manager/boat',
    },
    boatPage: {
      getBoatInfo: 'manager/boat/{_id}',
      editBoat: 'manager/boat/{_id}',
    },
  },

  admin: {
    usersPage: {
      getUsersList: 'admin/user/{type}',
    },
    managersPage: {
      addManager: 'admin/user',
      getManagersList: 'admin/user/{type}',
    },
  },

  chats: {
    generalChats: 'chat-room/general/all',
    projectChats: 'chat-room/project/all',
    getMessages: '/message/middle',
    loadOldMessages: '/message',
    sendMessage: 'message/send',
    markAsRead: 'message/mark-as-read',
    fileUpload: 'file/upload',
    getChatRoomById: 'chat-room/room/{_id}',
    getChatUnreadCount: 'chat/unread/count',
    deleteOwnMessage: 'message/{_id}',
    reactOnMessage: 'message/{messageId}/reaction',
    markReactionsAsRead: 'message/mark-reactions-as-read',
  },
};
