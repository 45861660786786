import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useState, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import { appDateFormat } from 'utils/formats';
import { CalendarIcon } from 'components/Icons';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  Wrap,
  Label,
  LabelStatic,
  FieldWrap,
  HelperText,
} from '../_shared/styled';
import { FieldInput, IconField, Calendar, Wrapper } from './styled';

CalendarField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelStatic: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextStatic: PropTypes.bool,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  css: PropTypes.object,
};

function CalendarField({
  name,
  label,
  labelStatic = false,
  type = 'text',
  value,
  onChange = () => {},
  error = false,
  helperText,
  helperTextStatic = true,
  fullWidth = false,
  height = 48,
  placeholder,
  disabled = false,
  hidden = false,
  css,
}) {
  const refWrapper = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);

  const onClick = () => {
    setIsFocus(true);
    setOpenCalendar(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const onChangeCalendar = (date) => {
    setStartDate(date);
    onChange(date);
    setOpenCalendar(false);
  };

  useOutsideClick(refWrapper, () => {
    setOpenCalendar(false);
  });

  const isFilled = useMemo(() => {
    return value || value.length > 0;
  }, [value]);

  return (
    <Wrap
      css={css}
      fullWidth={fullWidth}
      withLabel={label && !labelStatic}
    >
      {label && labelStatic ? (
        <LabelStatic
          htmlFor={name}
          error={error}
        >
          {label}
        </LabelStatic>
      ) : null}
      <Wrapper ref={refWrapper}>
        <FieldWrap>
          {!labelStatic && (
            <Label
              htmlFor={name}
              focus={isFocus}
              activated={isFilled}
              error={error}
            >
              {label}
            </Label>
          )}
          <FieldInput
            type={type}
            id={name}
            height={height}
            error={error}
            value={value && format(new Date(value), appDateFormat)}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            hidden={hidden}
            readOnly={disabled || hidden}
            labelStatic={labelStatic}
          />
          <IconField>
            <CalendarIcon />
          </IconField>
        </FieldWrap>
        <FieldWrap>
          {openCalendar && (
            <Calendar>
              <DatePicker
                selected={startDate}
                onChange={onChangeCalendar}
                showTimeSelect={false}
                inline
              />
            </Calendar>
          )}
        </FieldWrap>
      </Wrapper>
      {helperTextStatic && <HelperText>{error && helperText}</HelperText>}
    </Wrap>
  );
}

RDatePickerField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.any,
  disabled: PropTypes.bool,
};

export function RDatePickerField({ label, name, control, disabled, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CalendarField
          label={label}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
          error={!!error}
          helperText={error?.message ?? ''}
          {...rest}
        />
      )}
    />
  );
}
