import styled from 'styled-components';

const Form = styled.form`
  margin-top: 24px;
  width: 344px;

  > div {
    max-width: 344px;
  }
`;

const Btn = styled.button`
  display: none;
`;

export { Form, Btn };
