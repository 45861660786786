import { PageTitle, PageDesc, Header } from 'pages/_shared/styled';
import { Table } from './Table';
import { Wrap } from './styled';

const ProjectsPage = () => {
  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Projects list</PageTitle>
          <PageDesc>List of projects created in the system</PageDesc>
        </div>
      </Header>
      <Table />
    </Wrap>
  );
};

export default ProjectsPage;
