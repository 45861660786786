import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Wrapper = styled.div`
  max-width: 344px;
  overflow: hidden;
  margin-right: 48px;
`;

const Info = styled.div`
  display: grid;
  grid-gap: 4px;
  margin-bottom: ${({ lastElement }) => (lastElement ? '28px' : '32px')};
`;

const Title = styled.p`
  color: ${({ theme }) => theme.grayTitle};
`;

const Text = styled.p`
  font-weight: 500;
  color: ${({ theme }) => theme.heavyMetal};
`;

const ImagesBlock = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 8px;
  margin-top: 16px;
`;

const Image = styled.img`
  width: 88px;
  height: 88px;
  cursor: pointer;
`;

const DocumentLink = styled.div`
  margin-top: 27px;
  display: grid;
  grid-gap: 8px;
`;

const LeftBtn = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
  cursor: pointer;
`;

const RightBtn = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-54%);
  right: 14px;
  cursor: pointer;
`;

const FileWrap = styled.div`
  ${Flex};
  padding-block: 5px;
`;

export {
  Info,
  Title,
  Text,
  Wrapper,
  ImagesBlock,
  Image,
  DocumentLink,
  LeftBtn,
  RightBtn,
  FileWrap,
};
