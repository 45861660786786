import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { RAvatar } from 'components/RAvatar';
import { RFileComponent } from 'components/RFileComponent';
import { chatsRoom } from 'store/chats/room';
import { MessageReaction } from 'components/ChatPage/Messages/Message/_shared/Reaction';
import { useContextActions } from 'hooks/useContextActions';
import { ReactionsPopup } from 'components/ChatPage/Messages/Message/ReactionsPopup';
import { useParams } from 'react-router-dom';
import { MsgText } from '../_shared/MsgText';
import { Time } from '../_shared/Time';
import {
  MessageContainer,
  TextBlock,
  TextWrapper,
  Content,
  FileWrap,
  Footer,
} from './styled';

OppositeMessage.propTypes = {
  message: PropTypes.shape({
    _id: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
      avatarPath: PropTypes.string,
    }),
    message: PropTypes.string,
    createdAt: PropTypes.string,
    type: PropTypes.string,
    nameOfList: PropTypes.string,
    reaction: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        originalName: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string,
      })
    ),
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        originalName: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string,
      })
    ),
  }),
};

export function OppositeMessage({ message }) {
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const messageRef = useRef(null);
  const [show, setShow] = useContextActions(messageRef);

  const { firstname, lastname, roomColor } = useSelector(
    chatsRoom.selectors.profile
  );

  const onHandleRightClick = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const onChangeReaction = (value) => () => {
    dispatch(
      chatsRoom.thunks.reactOnMessage({
        messageId: message._id,
        params: { roomId, reaction: value },
      })
    ).then(({ payload }) =>
      dispatch(
        chatsRoom.actions.REACT_ON_MESSAGE({
          _id: payload._id,
          nameOfList: message.nameOfList,
          reaction: payload.reaction,
        })
      )
    );
  };

  const takeReactionBack = () => {
    dispatch(
      chatsRoom.thunks.takeReactionBack({
        messageId: message._id,
        roomId,
      })
    ).then(({ payload }) =>
      dispatch(
        chatsRoom.actions.TAKE_REACTION_BACK({
          _id: payload._id,
          nameOfList: message.nameOfList,
        })
      )
    );
  };

  const renderAttachedFiles = useCallback(() => {
    return (
      <>
        {[...message.files, ...message.photos].map(
          ({ _id, thumb, originalName: name, url }) => (
            <FileWrap key={_id}>
              <RFileComponent
                ellipsisWidth={150}
                thumb={thumb}
                name={name}
                url={url}
              />
            </FileWrap>
          )
        )}
      </>
    );
  }, [message]);

  return (
    <MessageContainer>
      <Content
        ref={messageRef}
        onContextMenu={onHandleRightClick}
      >
        {show && (
          <ReactionsPopup
            onChange={onChangeReaction}
            takeReactionBack={takeReactionBack}
            currentOption={message.reaction && message.reaction}
          />
        )}

        <RAvatar
          size={32}
          bgColor={roomColor}
          name={`${firstname} ${lastname}`}
        />
        <TextWrapper>
          <TextBlock>
            <MsgText
              message={message.message}
              css={{
                wordBreak: 'break-word',
                textAlign: 'left',
                width: '100%',
              }}
            />
          </TextBlock>
          {renderAttachedFiles()}
          <Footer reaction={message.reaction}>
            <Time date={message.createdAt} />
            {message.reaction ? (
              <MessageReaction reaction={message.reaction} />
            ) : null}
          </Footer>
        </TextWrapper>
      </Content>
    </MessageContainer>
  );
}
