import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { EditBoatPopup } from 'pages/Manager/BoatPage/EditBoatPopup';
import { FlexWrap, Spacer, Typography } from 'components/App/GlobalStyled';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RButton } from 'components/Form/RButton';
import { RAvatar } from 'components/RAvatar';
import { useToggle } from 'hooks/useToggle';
import { status } from 'utils/const';
import { prepareFullPhotoPath } from 'utils/prepare';
import { boatPage } from 'store/manager/boatPage';
import { PageTitle, Header, BreadCrumbs } from 'pages/_shared/styled';
import {
  Wrap,
  Content,
  ImageContainer,
  BoatImage,
  InfoContainer,
} from './styled';

function BoatInfoPage() {
  const theme = useTheme();
  const { boatId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { boat, profile } = useSelector(boatPage.selectors.data);
  const fetchingStatus = useSelector(boatPage.selectors.fetchingStatus);

  const [editBoatPopupOpen, setEditBoatPopupOpen] = useToggle(false);
  const loading = fetchingStatus === status.PENDING;

  const goToBoats = () => {
    history.push('/manager/boats');
  };

  const fetchData = () => {
    dispatch(boatPage.thunks.getBoatInfo({ boatId }));
  };

  useEffect(() => {
    fetchData();
  }, [boatId]);

  return (
    <>
      <Wrap>
        <RLoadingOverlay isVisible={loading} />
        <Header>
          <div>
            <PageTitle>Boat details</PageTitle>
            <BreadCrumbs onClick={goToBoats}>
              Boats&nbsp;&frasl;&nbsp;
              <strong>{boat.name}</strong>
            </BreadCrumbs>
          </div>
          <RButton
            disabled={fetchingStatus === status.FAIL}
            onClick={setEditBoatPopupOpen}
          >
            Edit boat
          </RButton>
        </Header>
      </Wrap>
      <Content>
        <InfoContainer>
          <Typography
            fz="32px"
            fw={500}
            color={theme.heavyMetal}
          >
            {boat.name}
          </Typography>
          <Typography color={theme.greyCloud}>
            {boat.manufacturer}&nbsp;
            {boat.length}&apos;
          </Typography>
          <Spacer m="0 0 25px 0" />
          <FlexWrap>
            <RAvatar
              size={32}
              bgColor={theme.avatar.turquoise}
              name={`${profile.firstname} ${profile.lastname}`}
            />
            <Spacer m="0 10px 0 0" />
            <Link to={`/manager/users/${profile._id}`}>
              <span>
                {profile.firstname}&nbsp;{profile.lastname}
              </span>
            </Link>
          </FlexWrap>
        </InfoContainer>
        <ImageContainer>
          <BoatImage src={prepareFullPhotoPath(boat.photo)} />
        </ImageContainer>
      </Content>
      {editBoatPopupOpen && <EditBoatPopup onClose={setEditBoatPopupOpen} />}
    </>
  );
}

export default BoatInfoPage;
