import { useToggle } from 'hooks/useToggle';

import { AddManagerPopup } from 'pages/Admin/ManagersPage/AddManagerPopup';
import { Table } from 'pages/Admin/ManagersPage/Table';
import { PageTitle, PageDesc, Header } from 'pages/_shared/styled';
import { RButton } from 'components/Form/RButton';
import { Wrap } from './styled';

function ManagersPage() {
  const [isAddManagerPopupOpen, setIsAddManagerPopupOpen] = useToggle(false);

  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Managers list</PageTitle>
          <PageDesc>List of managers added to the system</PageDesc>
        </div>
        <RButton onClick={setIsAddManagerPopupOpen}>Add manager</RButton>
      </Header>
      <Table />

      {isAddManagerPopupOpen && (
        <AddManagerPopup onClose={setIsAddManagerPopupOpen} />
      )}
    </Wrap>
  );
}

export default ManagersPage;
