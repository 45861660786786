import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Notification, Header, Title, Action } from '../_shared/styled';

InvoiceNotification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    client: PropTypes.shape({
      _id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
    project: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
    invoice: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

export function InvoiceNotification({ notification, onClose = () => {} }) {
  const history = useHistory();

  const goToInvoice = async () => {
    history.push(`/manager/invoices/${notification.invoice._id}`);
    onClose();
  };

  return (
    <Notification>
      <Header>
        <Title>
          <span>{notification.project.title}</span>
          &nbsp;invoice paid
        </Title>
      </Header>
      <Action onClick={goToInvoice}>View invoice</Action>
    </Notification>
  );
}
