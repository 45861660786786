import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { chatsRoom } from 'store/chats/room';
import { ChatRoom } from '../ChatRoom';
import { Sidebar } from '../Sidebar';
import { Wrap, Main, Text } from './styled';

export function ListChats() {
  const { roomId } = useParams();
  const dispatch = useDispatch();
  const [roomIsVisible, setRoomIsVisible] = useState(false);

  useEffect(() => {
    if (!roomId) {
      setRoomIsVisible(false);
      return;
    }

    const initRoom = async (roomId) => {
      setRoomIsVisible(false);
      await dispatch(chatsRoom.actions.SET_ROOM_ID(roomId));
      setRoomIsVisible(true);
    };

    if (roomId.length > 0) {
      initRoom(roomId);
    } else {
      setRoomIsVisible(true);
    }
  }, [roomId]);

  return (
    <Wrap>
      <Sidebar />
      {roomIsVisible ? (
        <ChatRoom />
      ) : (
        <Main>
          <Text>Select a chat to start messaging</Text>
        </Main>
      )}
    </Wrap>
  );
}
