import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  listProjects: [],
  pagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  projectStatus: status.PENDING,
};

const slice = createSlice({
  name: 'projectPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProjects.fulfilled, (state, { payload }) => {
        state.projectStatus = status.SUCCESS;
        state.listProjects = payload.projects.map((el) => {
          return {
            ...el,
            owner: `${el.profile.firstname} ${el.profile.lastname}`,
          };
        });
        state.pagination = { ...state.pagination, totalItems: payload.count };
      })
      .addCase(thunks.getProjects.pending, (state) => {
        state.projectStatus = status.PENDING;
      })
      .addCase(thunks.getProjects.rejected, (state) => {
        state.projectStatus = status.FAIL;
      });
  },
});

const projectPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { projectPage };
export default slice.reducer;
