import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  padding-top: 24px;
  height: 100vh;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -100vw;
  background: #fafafa;
  z-index: -1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Chats = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

export { Wrap, Overlay, Content, Chats };
