import { useMemo } from 'react';

import { Message } from './styled';

export function MsgText({ message, css }) {
  const msgText = useMemo(() => {
    return message.split('\n');
  }, []);

  return msgText.map((msg) => (
    <Message
      // eslint-disable-next-line react/no-array-index-key
      key={msg + Math.random()}
      css={css}
    >
      {msg}
    </Message>
  ));
}
