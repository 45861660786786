import { useCallback } from 'react';
import PropTypes, { arrayOf } from 'prop-types';

import { ContextMenuContainer, MenuItem } from './styled';

RContextMenu.propTypes = {
  options: arrayOf(
    PropTypes.shape({
      Icon: PropTypes.func,
      title: PropTypes.string,
      action: PropTypes.func,
    })
  ),
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

export function RContextMenu({ options, position }) {
  const renderOptions = useCallback(() => {
    return options.map(({ Icon, title, action }) => {
      return (
        <MenuItem
          key={title}
          onClick={action}
        >
          {Icon && (
            <Icon
              fill="#000000"
              size={20}
            />
          )}
          {title}
        </MenuItem>
      );
    });
  }, []);

  return (
    <ContextMenuContainer position={position}>
      {renderOptions()}
    </ContextMenuContainer>
  );
}
