import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const usersPage = {
  getUsersList(params) {
    return http.get(WEB_API_ROUTES.manager.usersPage.getUsersList, { params });
  },

  addUser(formData) {
    return http.post(WEB_API_ROUTES.manager.usersPage.addUser, formData);
  },
};
