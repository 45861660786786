import styled, { css } from 'styled-components';

import IconSelect from 'assets/img/form/icon-select.svg';

const FieldInput = styled.div`
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '300px')};

  .form-control {
    padding: 8.5px 14px 8.5px 58px;
    border-radius: 4px;
    width: 100%;
    height: ${({ height }) => `${height}px`};
    transition: 0.15s linear;

    ${({ label }) =>
      label &&
      css`
        &::placeholder {
          opacity: 0;
        }
      `}

    ${({ error }) =>
      error
        ? css`
            border-color: ${({ theme }) => theme.error};

            &:focus,
            &:hover {
              color: ${({ theme }) => theme.error};
            }
          `
        : css`
            &:hover {
              border-color: ${({ theme }) => theme.heavyMetal};
            }

            &:focus {
              border-color: ${({ theme }) => theme.como};
            }
          `}

    ${({ dropdownDisabled }) =>
      dropdownDisabled &&
      css`
        padding: 8.5px 14px;
      `}
  }

  ${({ dropdownDisabled }) =>
    dropdownDisabled &&
    css`
      .flag-dropdown {
        display: none;
      }
    `}

  .special-label {
    display: block;
    top: ${({ focus, activated }) => (focus || activated ? '-9px' : '50%')};
    padding: 0 4px;
    left: 12px;
    font-size: ${({ focus, activated }) =>
      focus || activated ? '12px' : '14px'};
    color: ${({ error, disabled, focus, theme, activated }) => {
      if (disabled) {
        return theme.gainsboro;
      }
      if (error) {
        return theme.error;
      }
      if (focus) {
        return theme.como;
      }
      if (activated) {
        return theme.granite;
      }
      return theme.concord;
    }};
    z-index: 1;
    transition: 0.1s linear;
    pointer-events: none;
    background-color: transparent;

    ${({ focus, activated, theme }) =>
      (focus || activated) &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: ${theme.white};
          z-index: -1;
        }
      `};

    ${({ focus, activated }) =>
      !focus &&
      !activated &&
      css`
        transform: translateY(-50%);
      `}
  }
  .react-tel-input .selected-flag .arrow {
    border: none;
    width: 10px;
    height: 10px;
    background: center url(${IconSelect}) no-repeat;
    transition: transform 0.3s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        top: calc(50% - 3px);
      `}
    &.up {
      transform: rotate(180deg);
    }
  }
`;

export { FieldInput };
