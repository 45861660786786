import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  ${Flex({ justify: 'space-between' })};
  padding: 0 15px 10px;
`;

const Status = styled.div`
  color: ${({ status, theme }) =>
    status === 'online' ? theme.como : theme.heavyMetal};
`;

export { Wrap, Status };
