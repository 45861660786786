import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDispatch, useSelector } from 'react-redux';

import { RMask } from 'components/RMask';
import { RModalPortal } from 'components/RModalPortal';
import { RPopup } from 'components/RPopup';
import { RTextField } from 'components/Form/RTextField';
import { RButton } from 'components/Form/RButton';
import { status } from 'utils/const';
import { PopupForm } from 'pages/_shared/styled';
import { Spacer } from 'components/App/GlobalStyled';
import { invoicesPage } from 'store/manager/invoicesPage';
import { RTextMaskField } from 'components/Form/RTextMaskField';
import { RTextareaField } from 'components/Form/RTextareaField';
import { RDatePickerField } from 'components/Form/RDatePickerField';
import { prepareDateToServer } from 'utils/prepareDate';
import { schema, defaultValues } from './config';

CreateInvoicePopup.propTypes = {
  onClose: PropTypes.func,
};

const fields = [
  { name: 'projectTitle', label: 'Project name', type: 'text' },
  { name: 'firstname', label: 'First name', type: 'text' },
  { name: 'lastname', label: 'Last name', type: 'text' },
  { name: 'amount', label: 'Invoice total', type: 'number' },
  { name: 'creationDate', label: 'Date', type: 'date' },
  { name: 'description', label: 'Description', type: 'textarea' },
];

export function CreateInvoicePopup({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const { page, pageSize } = useSelector(invoicesPage.selectors.pagination);

  const invoiceCreatingStatus = useSelector(
    invoicesPage.selectors.invoiceCreatingStatus
  );

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onAdd = (formData) => {
    dispatch(
      invoicesPage.thunks.createInvoice({
        formData: {
          ...formData,
          amount: formData.amount * 100,
          creationDate: prepareDateToServer(new Date(formData.creationDate)),
        },
      })
    );
  };

  const renderFields = () => {
    return fields.map(({ type, ...rest }) => {
      switch (type) {
        case 'number':
          return (
            <RTextMaskField
              {...rest}
              prefix="$ "
              placeholder="$"
              key={rest.name}
              control={control}
              fullWidth
            />
          );
        case 'date':
          return (
            <RDatePickerField
              disabled
              label="Date"
              key={rest.name}
              control={control}
              name="creationDate"
              fullWidth
            />
          );

        case 'textarea':
          return (
            <RTextareaField
              {...rest}
              type={type}
              key={rest.name}
              control={control}
              fullWidth
            />
          );

        default:
          return (
            <RTextField
              {...rest}
              type={type}
              key={rest.name}
              control={control}
              fullWidth
            />
          );
      }
    });
  };

  useEffect(() => {
    if (invoiceCreatingStatus === status.SUCCESS) {
      onClose();
      toast.success(`New invoice created`);
      dispatch(invoicesPage.actions.RESET_STATUS('invoiceCreatingStatus'));
      dispatch(invoicesPage.thunks.getInvoices({ pageSize, pageIndex: page }));
    }
  }, [invoiceCreatingStatus]);

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Add new invoice"
        >
          <PopupForm onSubmit={handleSubmit(onAdd)}>
            <Spacer m="0 0 10px 0" />
            {renderFields()}
            <RButton
              type="submit"
              fullWidth
            >
              Save
            </RButton>
          </PopupForm>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
