import styled from 'styled-components';

const Wrapper = styled.div`
  width: 344px;

  .rc-steps-item-content {
    overflow: initial;
    margin-left: 30px;
  }

  .rc-steps-item-title {
    font-size: 16px;
    color: ${({ theme }) => theme.heavyMetal};
    line-height: 21px !important;
    margin-bottom: 0px;
  }

  .rc-steps-item-finish {
    .rc-steps-item-icon {
      border-color: ${({ theme }) => theme.como};
      background: ${({ theme }) => theme.como};
    }

    .rc-steps-icon {
      > svg {
        transform: translate(-3.5px, -1px);
      }
    }
    .rc-steps-item-tail {
      &:after {
        background: ${({ theme }) => theme.como};
      }
    }
  }

  .rc-steps-item-finish {
    .rc-steps-item-tail {
      padding: 20px 0 0px 0;
    }

    .rc-steps-item-icon {
      width: 20px;
      height: 20px;
    }
  }

  .rc-steps-item-wait {
    .rc-steps-item-icon {
      width: 20px;
      height: 20px;
    }

    .rc-steps-item-title {
      color: ${({ theme }) => theme.opacityTitle};
    }

    .rc-steps-icon {
      width: 20px;
      height: 20px;
      font-size: 0 !important;
    }

    .rc-steps-item-tail {
      padding: 20px 0 0px 0;
    }
  }

  .rc-steps-item-process {
    .rc-steps-item-icon {
      border-color: ${({ theme }) => theme.como};
      background: initial;
      width: 20px;
      height: 20px;
    }

    .rc-steps-item-tail {
      padding: 20px 0 0px 0;
    }

    .rc-steps-icon {
      position: relative;
      font-size: 0;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: ${({ theme }) => theme.como};
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -78%);
      }
    }
  }
`;

export { Wrapper };
