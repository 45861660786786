import styled from 'styled-components';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-inline: 25px;
`;

export { ButtonContainer };
