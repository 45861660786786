import { messageTypes } from 'utils/const';

export default class ChatMessage {
  constructor(message, targetUserId = null) {
    this.targetUserId = targetUserId;
    this.message = { ...message };
    this.messageDefault = {
      _id: '',
      userId: '',
      roomId: '',
      type: messageTypes.OWN, // 'opposite/system'
      nameOfList: 'listMsg', // 'listOldMsg/listNewMsg'
      photos: [],
      files: [],
      isRead: false,
      createdAt: null,
    };
  }

  getTypeMsg(message) {
    if (message.type?.toLowerCase() === messageTypes.SYSTEM) {
      return 'system';
    }
    return this.targetUserId === message.userId ? 'opposite' : 'own';
  }

  setNewMessage() {
    return {
      ...this.messageDefault,
      ...this.message,
    };
  }

  getMessage() {
    const { __v, updatedAt, type, userId, ...data } = this.message;
    return {
      ...this.messageDefault,
      ...data,
      userId,
      type: this.getTypeMsg({ ...this.message, userId }),
    };
  }
}
