import { useSelector } from 'react-redux';

import { RButton } from 'components/Form/RButton';
import { status } from 'utils/const';
import { useToggle } from 'hooks/useToggle';
import { invoicesPage } from 'store/manager/invoicesPage';
import { PageDesc, PageTitle, Header } from 'pages/_shared/styled';
import { CreateInvoicePopup } from './CreateInvoicePopup';
import { Table } from './Table';
import { Wrap } from './styled';

const InvoicesPage = () => {
  const fetchingStatus = useSelector(invoicesPage.selectors.fetchingStatus);

  const [createInvoicePopupOpen, setCreateInvoicePopupOpen] = useToggle(false);

  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Invoices list</PageTitle>
          <PageDesc>List of invoices created in the system</PageDesc>
        </div>
        <RButton
          disabled={fetchingStatus === status.FAIL}
          onClick={setCreateInvoicePopupOpen}
        >
          Add invoice
        </RButton>
      </Header>
      <Table />
      {createInvoicePopupOpen && (
        <CreateInvoicePopup onClose={setCreateInvoicePopupOpen} />
      )}
    </Wrap>
  );
};

export default InvoicesPage;
