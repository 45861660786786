import PropTypes from 'prop-types';
import { memo } from 'react';

PhoneCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.number,
  }),
};

function PhoneCell({ cell }) {
  return `+${cell.value}`;
}

export const MemoizedPhoneCell = memo(PhoneCell);
