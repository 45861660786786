import { createSlice } from '@reduxjs/toolkit';

import { thunks as chatsRoomThunks } from 'store/chats/room/thunks';
import Chat from 'resources/Chats/Chat';
import { formattedDateForChat, getUserProfileIndexById } from 'utils/prepare';
import { usersOnline } from 'store/online';
import { chatStatuses, projectChatTypes, status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const { HANDLE_USER } = usersOnline.actions;

const initialState = {
  list: [],
  listPag: {
    general: {
      page: 1,
      perPage: 10,
    },
    project: {
      active: {
        page: 1,
        perPage: 10,
      },
      completed: {
        page: 1,
        perPage: 10,
      },
    },
  },
  socketId: null,
  activeProjectTab: projectChatTypes.active,
  listStatus: status.IDLE,
  chatsListMoreStatus: status.IDLE,
  socketStatus: status.IDLE,
};

export const slice = createSlice({
  name: 'chats',
  initialState: { ...initialState },
  reducers: {
    RESET_STATE: () => initialState,
    RESET_PAGINATION: (state) => {
      state.listPag = initialState.listPag;
    },
    SET_SOCKET_STATUS: (state, { payload }) => {
      state.socketStatus = payload;
    },
    SET_PROJECT_CHAT: (state, { payload }) => {
      state.activeProjectTab = payload;
    },
    SET_NEW_MSG: (state, { payload }) => {
      const chatIdx = state.list.findIndex((chat) => {
        return chat._id === payload.message.roomId;
      });
      if (chatIdx !== -1) {
        state.list[chatIdx].lastMessage = {
          ...state.list[chatIdx].lastMessage,
          ...payload.message,
          createdAt: formattedDateForChat(payload.message.createdAt),
        };
        if (payload.opponent) {
          state.list[chatIdx].unreadMessagesCount += 1;
        }
        if (chatIdx !== 0) {
          const updatedChat = state.list.splice(chatIdx, 1);
          state.list.unshift(updatedChat[0]);
        }
      }
    },
    UPDATE_UNREAD_REACTIONS: (state, { payload }) => {
      const updatedRoomIdx = state.list.findIndex(
        (chat) => chat._id === payload.roomId
      );

      if (updatedRoomIdx !== -1) {
        state.list[updatedRoomIdx].unreadReactionsCount =
          payload.unreadReactionsCount;
      }
    },
    CREATE_ROOM: (state, { payload }) => {
      const newChat = new Chat(payload);
      state.list.unshift(newChat.getChat());
    },
    SET_CHAT_ON_MANAGER_READ: (state, { payload }) => {
      const chatIdx = state.list.findIndex((chat) => {
        return chat._id === payload.roomId;
      });

      const readMessagesLength = payload.msgIds.length;

      if (chatIdx !== -1) {
        const unreadCurrentCount = state.list[chatIdx].unreadMessagesCount;

        if (unreadCurrentCount) {
          state.list[chatIdx].unreadMessagesCount =
            unreadCurrentCount - readMessagesLength;
        }
      }
    },
    UPDATE_ROOM_ON_DELETE_MESSAGE: (state, { payload }) => {
      state.list = state.list.map((chat) => {
        if (chat._id === payload._id) {
          const newChat = new Chat({
            ...payload,
          });
          return newChat.getChat();
        }

        return chat;
      });
    },
    CANCEL_CURRENT_CHAT: (state, { payload }) => {
      if (
        window.location.pathname.includes(
          `/manager/inbox/project/${payload.room._id}`
        )
      ) {
        Object.assign(state, initialState);
        state.activeProjectTab = projectChatTypes.completed;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getGeneralChats.pending, (state) => {
        state.listStatus = status.PENDING;
      })
      .addCase(thunks.getGeneralChats.fulfilled, (state, { payload }) => {
        state.list = payload?.map((chat) => {
          const newChat = new Chat(chat);
          return newChat.getChat();
        });
        state.listPag.general = {
          ...state.listPag.general,
          page: (state.listPag.general.page += 1),
        };
        state.listStatus = status.SUCCESS;
        state.chatsListMoreStatus = status.SUCCESS;
      })
      .addCase(thunks.getGeneralChats.rejected, (state) => {
        state.listStatus = status.FAIL;
      })

      .addCase(thunks.getMoreGeneralChats.pending, (state) => {
        state.chatsListMoreStatus = status.PENDING;
      })
      .addCase(thunks.getMoreGeneralChats.fulfilled, (state, { payload }) => {
        if (!payload.length) {
          state.chatsListMoreStatus = status.IDLE;
          return;
        }

        const chats = payload?.map((chat) => {
          const newChat = new Chat(chat);
          return newChat.getChat();
        });
        state.list.push(...chats);
        state.listPag.general = {
          ...state.listPag.general,
          page: (state.listPag.general.page += 1),
        };
        state.chatsListMoreStatus = status.SUCCESS;
      })
      .addCase(thunks.getMoreGeneralChats.rejected, (state) => {
        state.chatsListMoreStatus = status.FAIL;
      })

      .addCase(thunks.getProjectChats.pending, (state) => {
        state.listStatus = status.PENDING;
      })
      .addCase(thunks.getProjectChats.fulfilled, (state, { payload }) => {
        const projectChatStatus =
          payload[0]?.project.status === chatStatuses.active
            ? projectChatTypes.active
            : projectChatTypes.completed;
        state.list = payload?.map((chat) => {
          const newChat = new Chat(chat);
          return newChat.getChat();
        });
        state.listPag.project[projectChatStatus] = {
          ...state.listPag.project[projectChatStatus],
          page: (state.listPag.project[projectChatStatus].page += 1),
        };
        state.listStatus = status.SUCCESS;
        state.chatsListMoreStatus = status.SUCCESS;
      })
      .addCase(thunks.getProjectChats.rejected, (state) => {
        state.listStatus = status.FAIL;
      })

      .addCase(thunks.getMoreProjectChats.pending, (state) => {
        state.chatsListMoreStatus = status.PENDING;
      })
      .addCase(thunks.getMoreProjectChats.fulfilled, (state, { payload }) => {
        const projectChatStatus =
          payload[0]?.project.status === chatStatuses.active
            ? projectChatTypes.active
            : projectChatTypes.completed;

        if (!payload.length) {
          state.chatsListMoreStatus = status.IDLE;
          return;
        }
        const chats = payload?.map((chat) => {
          const newChat = new Chat(chat);
          return newChat.getChat();
        });
        state.list.push(...chats);
        state.listPag.project[projectChatStatus] = {
          ...state.listPag.project[projectChatStatus],
          page: (state.listPag.project[projectChatStatus].page += 1),
        };
        state.chatsListMoreStatus = status.SUCCESS;
      })
      .addCase(thunks.getMoreProjectChats.rejected, (state) => {
        state.chatsListMoreStatus = status.FAIL;
      })

      .addCase(chatsRoomThunks.sendMessage.fulfilled, (state, { payload }) => {
        const chatIdx = state.list.findIndex(
          (chat) => chat._id === payload.roomId
        );

        if (chatIdx !== -1) {
          state.list[chatIdx].lastMessage = {
            ...payload,
            createdAt: formattedDateForChat(payload.createdAt),
          };
        }
      })
      .addCase(chatsRoomThunks.markAsRead.fulfilled, (state, { payload }) => {
        const chatIdx = state.list.findIndex(
          (chat) => chat._id === payload.roomId
        );
        if (chatIdx !== -1) {
          state.list[chatIdx].unreadMessagesCount = 0;
          state.list[chatIdx].lastMessage.isRead = true;
        }
      })

      .addCase(HANDLE_USER, (state, { payload }) => {
        state.list.forEach((chat, i) => {
          const userIndex = getUserProfileIndexById(
            state.list[i].profile,
            payload.managerId
          );

          if (chat.profile[userIndex]._id === payload.id) {
            if (payload.action === 'add') {
              state.list[i].profile[userIndex].isOnline = true;
            }

            if (payload.action === 'remove') {
              state.list[i].profile[userIndex].isOnline = false;
              state.list[i].profile[userIndex].updatedAt = Date.now();
            }
          }
        });
      });
  },
});

const chats = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { chats };
export default slice.reducer;
