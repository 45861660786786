import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';

// MODULES

// manager
import auth from 'store/auth';
import chats from 'store/chats';
import userPage from 'store/manager/userPage';
import usersPage from 'store/manager/usersPage';
import chatsRoom from 'store/chats/room';
import usersOnline from 'store/online';
import userNotifications from 'store/notifications';
import projectPage from 'store/manager/projectsPage';
import projectDetails from 'store/manager/projectDetails';
import invoicesPage from 'store/manager/invoicesPage';
import invoicePage from 'store/manager/invoicePage';
import boatsPage from 'store/manager/boatsPage';
import boatPage from 'store/manager/boatPage';

// admin
import adminUsersPage from 'store/admin/usersPage';
import adminManagersPage from 'store/admin/managersPage';

const appReducer = combineReducers({
  auth,
  chats,
  userPage,
  usersPage,
  chatsRoom,
  usersOnline,
  userNotifications,
  projectPage,
  projectDetails,
  invoicesPage,
  invoicePage,
  boatsPage,
  boatPage,
  adminUsersPage,
  adminManagersPage,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'harbor',
  storage,
  blacklist: [],
  whitelist: ['auth', 'chatsRoom'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
