import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { UploadFilePopup } from 'pages/Manager/UserPage/Documents/UploadFilePopup';
import { ConfirmDeletePopup } from 'pages/Manager/UserPage/Documents/ConfirmDeletePopup';
import { FlexWrap, Spacer, Typography } from 'components/App/GlobalStyled';
import { RFileComponent } from 'components/RFileComponent';
import { RButtonIcon } from 'components/Form/RButtonIcon';
import { IconBucket } from 'components/Icons';
import { RButton } from 'components/Form/RButton';
import { useToggle } from 'hooks/useToggle';
import { font } from 'utils/const';
import { userPage } from 'store/manager/userPage';
import { prepareFullPhotoPath } from 'utils/prepare';
import {
  Wrap,
  ButtonContainer,
  DocumentContainer,
  DeleteButton,
  ScrollContainer,
} from './styled';

export function UserDocuments() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [deleteDocument, setDeleteDocument] = useState(null);
  const [isAddFilePopupOpen, setIsAddFilePopupOpen] = useToggle();
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useToggle();

  const { documents: list } = useSelector(userPage.selectors.data);

  const onDeleteFile = (name, _id) => () => {
    setDeleteDocument({ _id, name });
    setIsConfirmDeletePopupOpen();
  };

  const renderDocs = useCallback(() => {
    return list?.length ? (
      list.map(({ thumb, originalName: name, _id, url }) => (
        <DocumentContainer key={_id}>
          <FlexWrap css={{ gap: '25px' }}>
            <RFileComponent
              name={name}
              url={prepareFullPhotoPath(url)}
              thumb={thumb}
            />
            <DeleteButton>
              <RButtonIcon
                size={24}
                onClick={onDeleteFile(name, _id)}
              >
                <IconBucket />
              </RButtonIcon>
            </DeleteButton>
          </FlexWrap>
          <Spacer m="0 0 10px 0" />
        </DocumentContainer>
      ))
    ) : (
      <p>You have no uploaded files</p>
    );
  }, [list]);

  useEffect(() => {
    dispatch(userPage.thunks.getUserDocuments({ userId }));
  }, []);

  return (
    <Wrap>
      <ButtonContainer>
        <RButton onClick={setIsAddFilePopupOpen}>Upload file</RButton>
      </ButtonContainer>
      <Spacer m="0 0 20px 0" />

      <Typography
        fw={500}
        fz={font.xl}
      >
        Uploaded files
      </Typography>
      <Spacer m="0 0 10px 0" />
      <ScrollContainer>{renderDocs()}</ScrollContainer>
      {isAddFilePopupOpen && (
        <UploadFilePopup onClose={setIsAddFilePopupOpen} />
      )}
      {isConfirmDeletePopupOpen && (
        <ConfirmDeletePopup
          onClose={setIsConfirmDeletePopupOpen}
          document={deleteDocument}
        />
      )}
    </Wrap>
  );
}
