import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';

const getInvoiceDetails = createAsyncThunk(
  'manager/getInvoiceDetails',
  async ({ invoiceId }) => {
    try {
      const { data } = await api.invoicePage.getInvoiceDetails(invoiceId);
      return data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getInvoiceDetails,
};

export { thunks };
