import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const setAuthData = (state, payload) => {
  const { jwt, rt, user } = payload;
  state.token = jwt.token;
  state.refreshToken = rt.token;
  state.user = user;
  state.role = user.role;
};

const initialState = {
  token: null,
  refreshToken: null,
  role: null,
  user: {},
  chatSocketId: null,
  loginStatus: status.IDLE,
  registerStatus: status.IDLE,
  logoutStatus: status.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    SET_LOGIN: (state, { payload }) => {
      setAuthData(state, payload);
    },
    SET_SOCKET_ID: (state, { payload }) => {
      state.chatSocketId = payload;
    },
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    SET_REFRESH_TOKEN: (state, { payload }) => {
      const { jwt, rt } = payload;
      state.token = jwt.token;
      state.refreshToken = rt.token;
    },
    SWIPE_TOKENS: (state) => {
      [state.token, state.refreshToken] = [state.refreshToken, state.token];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.authLogin.pending, (state) => {
        state.loginStatus = status.PENDING;
      })
      .addCase(thunks.authLogin.fulfilled, (state) => {
        state.loginStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogin.rejected, (state) => {
        state.loginStatus = status.FAIL;
      })

      .addCase(thunks.authLogout.pending, (state) => {
        state.logoutStatus = status.PENDING;
      })
      .addCase(thunks.authLogout.fulfilled, (state) => {
        Object.assign(state, initialState);
        state.logoutStatus = status.SUCCESS;
      })
      .addCase(thunks.authLogout.rejected, (state) => {
        state.logoutStatus = status.FAIL;
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;
