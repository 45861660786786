import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  listInvoices: [],
  pagination: {
    totalItems: null,
    page: 1,
    pageSize: 10,
  },
  fetchingStatus: status.IDLE,
  invoiceCreatingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'invoicesPage',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
    SET_PAGINATION: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getInvoices.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(thunks.getInvoices.fulfilled, (state, { payload }) => {
        state.listInvoices = payload.data.map((el) => {
          return {
            ...el.invoice,
            projectName: el.project.title,
            owner: `${el.profile.firstname} ${el.profile.lastname}`,
          };
        });
        state.pagination = { ...state.pagination, totalItems: payload.count };
        state.fetchingStatus = status.SUCCESS;
      })
      .addCase(thunks.getInvoices.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.createInvoice.pending, (state) => {
        state.invoiceCreatingStatus = status.PENDING;
      })
      .addCase(thunks.createInvoice.fulfilled, (state) => {
        state.invoiceCreatingStatus = status.SUCCESS;
      })
      .addCase(thunks.createInvoice.rejected, (state) => {
        state.invoiceCreatingStatus = status.FAIL;
      });
  },
});

const invoicesPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { invoicesPage };
export default slice.reducer;
