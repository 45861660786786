import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { formatRelative, isValid } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

import { Loader } from 'components/Loader';
import { chatsRoom } from 'store/chats/room';
import { status } from 'utils/const';
import { Wrap } from './styled';

const ms = 1000;

PinnedDate.propTypes = {
  date: PropTypes.string,
};

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'MM/dd/yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};

export function PinnedDate({ date }) {
  const listOldMsgStatus = useSelector(chatsRoom.selectors.listOldMsgStatus);
  const [dateIsVisible, setDateIsVisible] = useState(true);

  useEffect(() => {
    let timeout = null;

    if (listOldMsgStatus === status.PENDING) {
      timeout = setTimeout(() => setDateIsVisible(false), ms);
    }

    return () => {
      clearInterval(timeout);
      setDateIsVisible(true);
    };
  }, [listOldMsgStatus]);

  const parseDate = new Date(date);

  if (!isValid(parseDate)) return null;

  return (
    <Wrap>
      {dateIsVisible ? (
        formatRelative(parseDate, new Date(), { locale })
      ) : (
        <Loader />
      )}
    </Wrap>
  );
}
