import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import { chatsRoom } from 'store/chats/room';
import { chatStatuses, projectChatTypes, status } from 'utils/const';
import { chats } from 'store/chats';
import { Heading } from '../Heading';
import { Panel } from '../Panel';
import { Messages } from '../Messages';
import { RoomWrap } from './styled';

export function ChatRoom() {
  const dispatch = useDispatch();
  const project = useSelector(chatsRoom.selectors.project);
  const targetUserId = useSelector(chatsRoom.selectors.targetUserId);
  const currentRoomId = useSelector(chatsRoom.selectors.currentRoomId);
  const listMsgStatus = useSelector(chatsRoom.selectors.listMsgStatus);

  const onLoadRoomData = async () => {
    await dispatch(chatsRoom.thunks.getChatRoomById({ chatId: currentRoomId }));
    await dispatch(chatsRoom.thunks.getMessages({ roomId: currentRoomId }));
  };

  useEffect(() => {
    if (!targetUserId) {
      dispatch(chatsRoom.actions.RESET_ROOM_ID());
    }
  }, []);

  useEffect(() => {
    if (project) {
      const currentTab =
        project.status === chatStatuses.active
          ? projectChatTypes.active
          : projectChatTypes.completed;
      dispatch(chats.actions.SET_PROJECT_CHAT(currentTab));
    }
  }, [project]);

  useEffect(() => {
    if (!currentRoomId) return;
    onLoadRoomData();
  }, []);

  if (currentRoomId && [status.IDLE, status.PENDING].includes(listMsgStatus)) {
    return <Loader size={80} />;
  }

  return (
    <RoomWrap>
      <Heading />
      <Messages />
      <Panel />
    </RoomWrap>
  );
}
