import styled from 'styled-components';

const Form = styled.form`
  width: 350px;
  margin: auto;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 30px auto 0;
`;

const LogoContainer = styled.div`
  margin-block: 0 30px;
`;

export { Form, LogoContainer, ButtonContainer };
