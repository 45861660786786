import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const invoicePage = {
  getInvoiceDetails(invoiceId) {
    return http.get(
      WEB_API_ROUTES.manager.invoicePage.getInvoiceDetails.replace(
        '{_id}',
        invoiceId
      )
    );
  },
};
