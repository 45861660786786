import styled from 'styled-components';

import { Text, TextEllipsis } from 'components/App/GlobalStyled';
import { font } from 'utils/const';

const Wrap = styled.div`
  padding-block: 35px;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 40% 60%;
  padding-inline: 48px;
  ${Text({ size: font.lg })}
  ${({ css }) => css}

  p {
    ${TextEllipsis({ width: '340px' })}
  }
`;

export { Wrap, Content };
