import styled, { css } from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Tabs = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 5px;
`;

const TabTitle = styled.div`
  position: relative;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.como};
`;

const Tab = styled.div`
  ${Flex({ justify: 'center' })};
  min-width: 50%;
  padding: 11px;
  border-radius: 6px 6px 6px 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      ${TabTitle} {
        color: ${({ theme }) => theme.white};
      }
      background-color: ${({ theme }) => theme.como};
    `}
`;

export { Tabs, Tab, TabTitle };
