import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RMask } from 'components/RMask';
import { RModalPortal } from 'components/RModalPortal';
import { RPopup } from 'components/RPopup';
import { RTextField } from 'components/Form/RTextField';
import { RPhoneNumberField } from 'components/Form/RPhoneNumberField';
import { RButton } from 'components/Form/RButton';
import { status } from 'utils/const';
import { usersPage } from 'store/manager/usersPage';
import {
  schema,
  defaultValues,
} from 'pages/Manager/UsersPage/AddUserPopup/config';
import { PopupForm } from 'pages/_shared/styled';

AddUserPopup.propTypes = {
  onClose: PropTypes.func,
};

const fields = [
  { name: 'firstname', label: 'First name', type: 'text' },
  { name: 'lastname', label: 'Last name', type: 'text' },
  { name: 'email', label: 'Mail', type: 'text' },
  { name: 'phone', label: 'Phone number', type: 'phone' },
  { name: 'password', label: 'Password', type: 'text' },
];

export function AddUserPopup({ onClose = () => {} }) {
  const dispatch = useDispatch();
  const addUserStatus = useSelector(usersPage.selectors.addUserStatus);

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (addUserStatus === status.SUCCESS) {
      onClose();
      dispatch(usersPage.actions.RESET_STATUS('addUserStatus'));
    }
  }, [addUserStatus]);

  const onAdd = (data) => {
    dispatch(usersPage.thunks.addUser(data));
  };

  const renderFields = () => {
    return fields.map(({ type, ...rest }) =>
      type === 'phone' ? (
        <RPhoneNumberField
          {...rest}
          key={rest.name}
          control={control}
          labelStatic={false}
          fullWidth
        />
      ) : (
        <RTextField
          {...rest}
          key={rest.name}
          control={control}
          fullWidth
        />
      )
    );
  };

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Add new user"
        >
          <RLoadingOverlay isVisible={addUserStatus === status.PENDING} />
          <PopupForm onSubmit={handleSubmit(onAdd)}>
            {renderFields()}
            <RButton
              type="submit"
              fullWidth
            >
              Save
            </RButton>
          </PopupForm>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
