import format from 'date-fns/format';
import PropTypes from 'prop-types';

import { appTimeFormat } from 'utils/formats';
import { Wrap } from './styled';

Time.propTypes = {
  date: PropTypes.string,
};

export function Time({ date }) {
  return <Wrap>{format(new Date(date), appTimeFormat)}</Wrap>;
}
