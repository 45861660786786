import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { boatModel } from 'models/boat';
import { profileModel } from 'models/profile';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  boat: boatModel(),
  profile: profileModel(),
  fetchingStatus: status.IDLE,
  editBoatStatus: status.IDLE,
};

const slice = createSlice({
  name: 'boatPage',
  initialState,
  reducers: {
    RESET_STATUS: (state, { payload }) => {
      state[payload] = status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getBoatInfo.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(
        thunks.getBoatInfo.fulfilled,
        (state, { payload: { boat, profile } }) => {
          state.boat = boatModel(
            boat._id,
            boat.name,
            boat.length,
            boat.photo,
            boat.userId,
            boat.manufacturer
          );
          state.profile = profileModel(
            profile._id,
            profile.firstname,
            profile.lastname,
            profile.email,
            profile.phone,
            profile.role
          );
          state.fetchingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getBoatInfo.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      })

      .addCase(thunks.editBoat.pending, (state) => {
        state.editBoatStatus = status.PENDING;
      })
      .addCase(thunks.editBoat.fulfilled, (state, { payload }) => {
        state.boat = { ...payload };
        state.editBoatStatus = status.SUCCESS;
      })
      .addCase(thunks.editBoat.rejected, (state) => {
        state.editBoatStatus = status.FAIL;
      });
  },
});

const boatPage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { boatPage };
export default slice.reducer;
