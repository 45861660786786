import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 375px 1fr;
  height: calc(100vh - 60px);
`;

const Main = styled.div`
  overflow: hidden;
  ${Flex({ justify: 'center' })};
  background-color: ${({ theme }) => theme.chatContrast};
`;

const Text = styled.div`
  padding: 16px 32px;
  background-color: gold;
  border-radius: 10px;
`;

export { Wrap, Main, Text };
