import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px auto;
  grid-column-gap: 8px;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.chatContrast};
  border-radius: 10px;
`;

const Field = styled.div`
  position: relative;
`;

const TextAreaContainer = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  padding: 16px;
  resize: none;
  border: none;
`;

const Send = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f5f7;
  border-radius: 50%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const Attach = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 32px;
`;

const AttachedFiles = styled.div``;

const Delete = styled.div`
  cursor: pointer;
`;

export { Wrap, Field, TextAreaContainer, Send, Attach, AttachedFiles, Delete };
