import styled from 'styled-components';

import { Flex } from 'components/App/GlobalStyled';

const Overlay = styled.div`
  height: 100%;
  ${Flex({ justify: 'center' })}
`;

export { Overlay };
