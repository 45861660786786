import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const userNotifications = {
  getNotifications() {
    return http.get(WEB_API_ROUTES.manager.notifications);
  },
  deleteAllNotifications() {
    return http.delete(WEB_API_ROUTES.manager.notifications);
  },
};
