import React from 'react';
import PropTypes from 'prop-types';

import { RPagination } from 'components/RPagination';
import { Wrap } from './styled';

TablePagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
};

export function TablePagination({ pageSize, pageIndex, totalRows, gotoPage }) {
  const currentPage = pageIndex + 1;

  const updateStepPage = (p) => {
    gotoPage(p - 1);
  };

  return (
    <Wrap>
      <RPagination
        current={currentPage}
        total={totalRows}
        perPage={pageSize}
        onChange={updateStepPage}
      />
    </Wrap>
  );
}
