import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Notification,
  Header,
  Title,
  Content,
  Action,
} from '../_shared/styled';

ChatNotification.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.shape({
      _id: PropTypes.string,
      isRead: PropTypes.bool,
      message: PropTypes.string,
      roomId: PropTypes.string,
      type: PropTypes.string,
      userId: PropTypes.string,
    }),
    sender: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
};

export function ChatNotification({ notification, onClose = () => {} }) {
  const history = useHistory();
  const { chatType } = useParams();

  const goToChat = async () => {
    history.push(`/manager/inbox/${chatType}`);
    onClose();
  };

  return (
    <Notification>
      <Header>
        <Title>
          New message from <span>{notification.sender.name}</span>
        </Title>
      </Header>
      <Content>{notification.message.message}</Content>
      <Action onClick={goToChat}>View in chat</Action>
    </Notification>
  );
}
