import { memo } from 'react';
import PropTypes from 'prop-types';
import { TextEllipsed } from 'components/App/GlobalStyled';

ManufacturerCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.string,
    column: PropTypes.shape({
      width: PropTypes.number,
    }),
  }),
};

function ManufacturerCell({ cell: { value, row, column } }) {
  return (
    <TextEllipsed width={column.width}>
      {value}&nbsp;
      {row.original.length}&apos;
    </TextEllipsed>
  );
}

export const MemoizedManufacturerCell = memo(ManufacturerCell);
