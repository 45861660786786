import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RFileUpload } from 'components/Form/RFileUpload';
import { RModalPortal } from 'components/RModalPortal';
import { RButton } from 'components/Form/RButton';
import { IconClose } from 'components/Icons';
import { RPopup } from 'components/RPopup';
import { RMask } from 'components/RMask';
import { filesFormats, photosFormats, status } from 'utils/const';
import { deleteObjInArrByProperty, prepareFormData } from 'utils/prepare';
import {
  FlexWrap,
  TextEllipsed,
  Typography,
} from 'components/App/GlobalStyled';
import { chatsRoom } from 'store/chats/room';
import { userPage } from 'store/manager/userPage';
import { AttachedFiles, Delete } from './styled';

UploadFilePopup.propTypes = {
  onClose: PropTypes.func,
};

export function UploadFilePopup({ onClose = () => {} }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { userId } = useParams();
  const [attachedFiles, setAttachedFiles] = useState([]);

  const { documents: list } = useSelector(userPage.selectors.data);

  const uploadingDocumentsStatus = useSelector(
    userPage.selectors.uploadingDocumentsStatus
  );

  const uploadingDocumentStatus = useSelector(
    chatsRoom.selectors.fileUploadStatus
  );

  // eslint-disable-next-line consistent-return
  const onAttachFile = async (file) => {
    // TODO: Temporary, add infinite scroll
    if (
      list.length + attachedFiles.length >= 10 ||
      attachedFiles.length >= 10
    ) {
      return fileInputRef.current.cbError({
        error: 'File uploading limit achieved',
      });
    }

    const res = await dispatch(
      chatsRoom.thunks.fileUpload(prepareFormData({ file }))
    );

    if (res.payload?.status === 200) {
      await setAttachedFiles((prev) => [
        ...prev,
        {
          ...res.payload.data,
        },
      ]);

      fileInputRef.current.clear();
    }
  };

  const onSave = () => {
    dispatch(userPage.thunks.uploadDocuments({ files: attachedFiles, userId }));
    setAttachedFiles([]);
  };

  const onDeleteAttached = (name) => () => {
    setAttachedFiles((prev) => deleteObjInArrByProperty(prev, name, 'name'));
  };

  const renderAttachedFiles = useCallback(() => {
    return (
      <AttachedFiles>
        {attachedFiles.map(({ originalName: name, url }) => (
          <FlexWrap
            key={url}
            justify="space-between"
            p="10px"
          >
            <Typography color={theme.como}>
              <TextEllipsed width={300}>{name}</TextEllipsed>
            </Typography>
            <Delete onClick={onDeleteAttached(name)}>
              <IconClose />
            </Delete>
          </FlexWrap>
        ))}
      </AttachedFiles>
    );
  }, [attachedFiles]);

  useEffect(() => {
    if (uploadingDocumentsStatus === status.SUCCESS) {
      onClose();
      dispatch(userPage.thunks.getUserDocuments({ userId }));
      dispatch(userPage.actions.RESET_STATUS('uploadingDocumentsStatus'));
    }
  }, [uploadingDocumentsStatus]);

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Add new file"
        >
          <RLoadingOverlay
            isVisible={uploadingDocumentsStatus === status.PENDING}
          />
          <RFileUpload
            disabled={uploadingDocumentStatus === status.PENDING}
            ref={fileInputRef}
            onFileLoad={onAttachFile}
            label="Upload File"
            name="attachFile"
            additionalFileTypes={[
              '.doc',
              '.docx',
              '.heic',
              ...photosFormats,
              ...filesFormats,
            ]}
          />
          {renderAttachedFiles()}
          <RButton
            disabled={!attachedFiles.length}
            type="button"
            onClick={onSave}
            fullWidth
          >
            Save
          </RButton>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
