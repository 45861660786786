import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { UserData } from 'pages/Manager/UserPage/Data';
import { UserBoats } from 'pages/Manager/UserPage/Boats';
import { UserProjects } from 'pages/Manager/UserPage/Projects';
import { UserInvoices } from 'pages/Manager/UserPage/Invoices';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { EditUserPopup } from 'pages/Manager/UserPage/EditUserPopup';
import { Spacer } from 'components/App/GlobalStyled';
import { RButton } from 'components/Form/RButton';
import { RTabs } from 'components/RTabs';
import { status } from 'utils/const';
import { useToggle } from 'hooks/useToggle';
import { userPage } from 'store/manager/userPage';
import { PageTitle, Header, BreadCrumbs } from 'pages/_shared/styled';
import { UserDocuments } from 'pages/Manager/UserPage/Documents';
import { Wrap } from './styled';

const tabs = [
  { slug: 'DATA', title: 'User Data' },
  { slug: 'BOATS', title: 'User Boats' },
  { slug: 'PROJECTS', title: 'User Projects' },
  { slug: 'INVOICES', title: 'User Invoices' },
  { slug: 'DOCUMENTS', title: 'Documents' },
];

const pages = {
  DATA: UserData,
  BOATS: UserBoats,
  PROJECTS: UserProjects,
  INVOICES: UserInvoices,
  DOCUMENTS: UserDocuments,
};

function UserInfoPage() {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchingStatus = useSelector(userPage.selectors.fetchingStatus);
  const addingBoatStatus = useSelector(userPage.selectors.addingBoatStatus);
  const {
    user: { firstname, lastname },
  } = useSelector(userPage.selectors.data);
  const [activeTab, setActiveTab] = useState(tabs[0].slug);
  const [editUserPopupOpen, setEditUserPopupOpen] = useToggle(false);
  const loading = fetchingStatus === status.PENDING;
  const CurrentPage = pages[activeTab];

  const fetchData = () => {
    dispatch(userPage.thunks.getUserInfo({ userId }));
  };

  const goToUsers = () => {
    history.push('/manager/users');
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  useEffect(() => {
    if (addingBoatStatus === status.SUCCESS) {
      fetchData();
      dispatch(userPage.actions.RESET_STATUS('addingBoatStatus'));
    }
  }, [addingBoatStatus]);

  return (
    <>
      <Wrap>
        <RLoadingOverlay isVisible={loading} />
        <Header>
          <div>
            <PageTitle>User info</PageTitle>
            <BreadCrumbs onClick={goToUsers}>
              Users&nbsp;&frasl;&nbsp;
              <strong>
                {firstname}&nbsp;{lastname}
              </strong>
            </BreadCrumbs>
          </div>
          <RButton
            disabled={fetchingStatus === status.FAIL}
            onClick={setEditUserPopupOpen}
          >
            Edit user
          </RButton>
        </Header>
      </Wrap>
      <RTabs
        options={tabs}
        activeTab={activeTab}
        setTab={setActiveTab}
      />
      <Spacer m="30px 0 0 0 " />
      <CurrentPage />
      {editUserPopupOpen && <EditUserPopup onClose={setEditUserPopupOpen} />}
    </>
  );
}

export default UserInfoPage;
