import styled from 'styled-components';

const Form = styled.form`
  & > div {
    &:last-of-type {
      margin-bottom: 16px;
    }

    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
`;

export { Form };
