import { RButton } from 'components/Form/RButton';
import { Table } from 'pages/Manager/UsersPage/Table';
import { AddUserPopup } from 'pages/Manager/UsersPage/AddUserPopup';
import { useToggle } from 'hooks/useToggle';
import { PageTitle, PageDesc, Header } from 'pages/_shared/styled';
import { Wrap } from './styled';

function UsersPage() {
  const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useToggle(false);

  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Users list</PageTitle>
          <PageDesc>List of users added to the system</PageDesc>
        </div>
        <RButton onClick={setIsAddUserPopupOpen}>Add user</RButton>
      </Header>
      <Table />

      {isAddUserPopupOpen && <AddUserPopup onClose={setIsAddUserPopupOpen} />}
    </Wrap>
  );
}

export default UsersPage;
