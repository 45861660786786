export const appDateFormat = 'dd.MM.yyyy';

export const appTimeFormat = 'HH:mm';

export const calendarTimeFormat = 'HH:mm aa';

export const twelveTimeFormat = 'hh:mm aa';

export const hour = 'HH';

export const minute = 'mm';
