import { css } from 'styled-components';
import { font } from 'utils/const';

const Block = ({
  w = 'auto',
  h = 'auto',
  p = 0,
  m = 0,
  b = 'none',
  bg = 'transparent',
}) => css`
  width: ${w};
  height: ${h};
  padding: ${p};
  margin: ${m};
  border: ${b};
  background: ${bg};
`;

const Flex = ({
  align = 'center',
  justify = 'flex-start',
  direction = 'row',
  gap = 0,
}) => css`
  display: flex;
  align-items: ${align};
  flex-direction: ${direction};
  justify-content: ${justify};
  gap: ${gap};
`;

const TextEllipsis = ({ width }) => css`
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextEllipsisLines = ({ linesCount = 3, width = '200px' }) => css`
  display: -webkit-box;
  max-width: ${width};
  -webkit-line-clamp: ${linesCount};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ScrollBar = () => css`
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
`;

const ScrollBarHidden = () => css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const Circle = ({ size = '21px', background = '#ffffff' }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${background};
  width: ${size};
  height: ${size};
  border: 1px solid transparent;
  border-radius: 50%;
  margin: 0 1px;
  padding: 7px;
  line-height: 1;
  outline: 0;
  cursor: pointer;
`;

const Text = ({ size }) => `
  font-size: ${size || font.sm};
`;

const Container = ({ width = 420 }) => css`
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  width: ${width}px;
  z-index: 50;
`;

export {
  Block,
  Flex,
  TextEllipsis,
  TextEllipsisLines,
  ScrollBar,
  ScrollBarHidden,
  Circle,
  Text,
  Container,
};
