import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ConfirmDeleteMessagePopup } from 'components/ChatPage/Messages/ConfirmDeleteMessagePopup';
import { MessageReaction } from 'components/ChatPage/Messages/Message/_shared/Reaction';
import { RFileComponent } from 'components/RFileComponent';
import { RContextMenu } from 'components/RContextMenu';
import { IconBucket } from 'components/Icons';
import { chatsRoom } from 'store/chats/room';
import { useContextActions } from 'hooks/useContextActions';
import { useToggle } from 'hooks/useToggle';
import { Time } from '../_shared/Time';
import { MsgText } from '../_shared/MsgText';
import { Wrap, Content, TextBlock, Footer, FileWrap } from './styled';

OwnMessage.propTypes = {
  message: PropTypes.shape({
    _id: PropTypes.string,
    isRead: PropTypes.bool,
    message: PropTypes.string,
    createdAt: PropTypes.string,
    reaction: PropTypes.string,
    reactionIsRead: PropTypes.bool,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        originalName: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string,
      })
    ),
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        originalName: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string,
      })
    ),
  }),
  onReadReaction: PropTypes.func,
};

export function OwnMessage({ message, onReadReaction }) {
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const messageRef = useRef(null);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useToggle();
  const [show, setShow] = useContextActions(messageRef);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const messageIsVisibleWithReaction =
    inView && message.reaction && !message.reactionIsRead;

  const onDeleteMessage = () => {
    setIsConfirmDeletePopupOpen();
    setShow(false);
  };

  const onSubmitDeleteMessage = () => {
    dispatch(
      chatsRoom.thunks.deleteOwnMessage({
        messageId: message._id,
        params: { roomId },
      })
    );
  };

  const contextMenuOptions = useMemo(
    () => [
      {
        Icon: IconBucket,
        title: 'Delete message',
        action: onDeleteMessage,
      },
    ],
    []
  );

  const onHandleRightClick = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const markReactionsAsRead = () => {
    onReadReaction(message._id);
  };

  const renderAttachedFiles = useCallback(() => {
    return (
      <>
        {[...message.files, ...message.photos].map(
          ({ _id, originalName: name, url, thumb }) => (
            <FileWrap key={_id || url}>
              <RFileComponent
                ellipsisWidth={150}
                thumb={thumb}
                name={name}
                url={url}
              />
            </FileWrap>
          )
        )}
      </>
    );
  }, [message]);

  useEffect(() => {
    if (messageIsVisibleWithReaction) {
      markReactionsAsRead();
    }
  }, [inView, message.reaction]);

  return (
    <Wrap ref={ref}>
      <Content
        ref={messageRef}
        onContextMenu={onHandleRightClick}
      >
        {show && <RContextMenu options={contextMenuOptions} />}
        <TextBlock>
          <MsgText
            message={message.message}
            css={{
              overflowWrap: 'break-word',
              width: '100%',
            }}
          />
        </TextBlock>
        {renderAttachedFiles()}
        <Footer reaction={message.reaction}>
          {message.reaction ? (
            <MessageReaction reaction={message.reaction} />
          ) : null}
          <Time date={message.createdAt} />
        </Footer>
      </Content>
      {isConfirmDeletePopupOpen && (
        <ConfirmDeleteMessagePopup
          message={message}
          onClose={setIsConfirmDeletePopupOpen}
          onDelete={onSubmitDeleteMessage}
        />
      )}
    </Wrap>
  );
}
