import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.chatContrast};
`;

const NoMessages = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 390px;
  text-align: center;
  padding: 16px 32px;
  background-color: gold;
  border-radius: 10px;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 16px 16px 46px 0;

  & > :first-child {
    margin-top: auto;
  }
`;

const NewMessagesText = styled.div``;

const NewMessages = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  ${NewMessagesText} {
    color: ${({ theme }) => theme.concord};
    white-space: nowrap;
    padding: 0 10px 15px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.concord};
  }
`;

export { Wrap, NoMessages, MessagesContainer, NewMessages, NewMessagesText };
