import PropTypes from 'prop-types';
import { useState, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import eu from 'date-fns/locale/eu';

import { appTimeFormat } from 'utils/formats';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  Wrap,
  Label,
  LabelStatic,
  FieldWrap,
  HelperText,
} from '../_shared/styled';
import { FieldInput, Time } from './styled';

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelStatic: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  helperTextStatic: PropTypes.bool,
  fullWidth: PropTypes.bool,
  height: PropTypes.number,
  css: PropTypes.object,
};

function TextField({
  name,
  label,
  labelStatic = false,
  value,
  onChange = () => {},
  error = false,
  helperText,
  helperTextStatic = true,
  fullWidth = false,
  height = 48,
  css,
}) {
  const fieldWrapRef = useRef();
  const minTime = new Date().setHours(0, 15);
  const maxTime = new Date().setHours(23, 45);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsFocus(true);
    setIsOpen(true);
  };

  const onChangeTime = (time) => {
    onChange(time);
    setIsOpen(false);
  };

  const isFilled = useMemo(() => {
    return value || value.length > 0;
  }, [value]);

  useOutsideClick(fieldWrapRef, () => {
    setIsOpen(false);
    setIsFocus(false);
  });

  return (
    <Wrap
      css={css}
      fullWidth={fullWidth}
      withLabel={label && !labelStatic}
    >
      {label && labelStatic ? (
        <LabelStatic
          htmlFor={name}
          error={error}
        >
          {label}
        </LabelStatic>
      ) : null}
      <FieldWrap ref={fieldWrapRef}>
        {!labelStatic && (
          <Label
            htmlFor={name}
            focus={isFocus}
            activated={isFilled}
            error={error}
          >
            {label}
          </Label>
        )}
        <FieldInput
          id={name}
          height={height}
          error={error}
          value={value && format(new Date(value), appTimeFormat)}
          onClick={onClick}
          readOnly
          labelStatic={labelStatic}
        />
        {isOpen && (
          <Time>
            <DatePicker
              inline
              locale={eu}
              className="field-input"
              placeholderText="Select time"
              selected={value || minTime}
              onChange={onChangeTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              name="pickUpTime"
              dateFormat={appTimeFormat}
              minTime={minTime}
              maxTime={maxTime}
            />
          </Time>
        )}
      </FieldWrap>
      {helperTextStatic && <HelperText>{error && helperText}</HelperText>}
    </Wrap>
  );
}

RInputTimePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.any,
};

export function RInputTimePicker({ label, name, control, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          label={label}
          value={value || ''}
          onChange={onChange}
          error={!!error}
          helperText={error?.message ?? ''}
          {...rest}
        />
      )}
    />
  );
}
