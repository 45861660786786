import styled from 'styled-components';

const Popup = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ width }) => `${width}px`};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  max-height: ${({ maxHeight }) => `${maxHeight}px` || 'auto'};
  margin: auto;
  padding: 60px 128px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;

  ${({ css }) => css}
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 24px;
  ${({ css }) => css}
`;

const Close = styled.div`
  position: absolute;
  top: 36px;
  right: 36px;
`;

export { Popup, Title, Close };
