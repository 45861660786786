import { useEffect, useState } from 'react';

export function useContextActions(elRef) {
  const [show, setShow] = useState(false);

  const hideContextMenu = () => {
    setShow(false);
  };

  const handleClick = () => (show ? setShow(false) : null);

  useEffect(() => {
    const messageBody = elRef.current;
    window.addEventListener('click', handleClick);
    messageBody.addEventListener('mouseleave', hideContextMenu);
    return () => {
      window.removeEventListener('click', handleClick);
      messageBody.removeEventListener('mouseleave', hideContextMenu);
    };
  }, [show]);

  return [show, setShow];
}
