import PropTypes from 'prop-types';

import { Reaction } from 'components/ChatPage/Messages/Message/_shared/styled';
import { Typography } from 'components/App/GlobalStyled';

MessageReaction.propTypes = {
  reaction: PropTypes.string,
};

export function MessageReaction({ reaction }) {
  return (
    <Reaction>
      <Typography
        ta="center"
        fz="14px"
        cursor="default"
      >
        {reaction}
      </Typography>
    </Reaction>
  );
}
