const selectors = {
  list: (state) => state.userNotifications.list,
  newNotification: (state) => state.userNotifications.newNotification,
  listCount: (state) => state.userNotifications.listCount,
  unreadMessagesCount: (state) => state.userNotifications.unreadMessagesCount,
  listStatus: (state) => state.userNotifications.listStatus,
  listIsVisible: (state) => state.userNotifications.listIsVisible,
  notificationTriggered: (state) =>
    state.userNotifications.notificationTriggered,
  deleteAllNotificationsStatus: (state) =>
    state.userNotifications.deleteAllNotificationsStatus,
};

export { selectors };
