import styled from 'styled-components';

import { device } from 'utils/device';
import { Flex } from 'components/App/GlobalStyled';

const Wrap = styled.div`
  margin-left: auto;
  max-width: 100%;
  padding: 16px;

  @media (${device.md}) {
    max-width: 90%;
  }
`;

const TextBlock = styled.div`
  ${Flex({ justify: 'center' })};
  color: ${({ theme }) => theme.granite};
  background-color: #f8f9fb;
`;

export { Wrap, TextBlock };
