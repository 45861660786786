import styled from 'styled-components';

const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.grayTitle};
`;

const Wrap = styled.div`
  margin: 16px 0;
  display: grid;
  grid-gap: 16px;
`;

export { Text, Wrap };
