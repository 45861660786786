import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { RButtonIcon } from 'components/Form/RButtonIcon';
import { RFileUpload } from 'components/Form/RFileUpload';
import { RTextField } from 'components/Form/RTextField';
import { RModalPortal } from 'components/RModalPortal';
import { RButton } from 'components/Form/RButton';
import { IconClose } from 'components/Icons';
import { RPopup } from 'components/RPopup';
import { RMask } from 'components/RMask';
import { status } from 'utils/const';
import { prepareFormData } from 'utils/prepare';
import { userPage } from 'store/manager/userPage';
import { FlexWrap, Spacer, Typography } from 'components/App/GlobalStyled';
import { schema, defaultValues } from './config';
import { Form } from './styled';

AddBoatPopup.propTypes = {
  onClose: PropTypes.func,
};

const fields = [
  { name: 'name', label: 'Yacht name', type: 'text' },
  { name: 'manufacturer', label: 'Yacht firm', type: 'text' },
  { name: 'length', label: 'Yacht length', type: 'number' },
];

export function AddBoatPopup({ onClose = () => {} }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const addingBoatStatus = useSelector(userPage.selectors.addingBoatStatus);
  const fileUploadRef = useRef(null);

  const { handleSubmit, control } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onAdd = (data) => {
    if (!selectedFile) {
      fileUploadRef.current.cbError({ error: 'This field is required' });
      return;
    }

    const preparedData = {
      ...data,
      userId,
      photo: selectedFile,
    };
    dispatch(userPage.thunks.addBoat(prepareFormData(preparedData)));
  };

  const onLoadFile = (file, _, name) => {
    setSelectedFile(file);
    setFileName(name);
  };

  const onDeleteFile = () => {
    setFileName(null);
    setSelectedFile(null);
  };

  const renderFields = () => {
    return fields.map(({ type, ...rest }) => (
      <RTextField
        {...rest}
        type={type}
        key={rest.name}
        control={control}
        fullWidth
      />
    ));
  };

  useEffect(() => {
    if (addingBoatStatus === status.SUCCESS) {
      onClose();
      toast.success('Boat successfully added');
      dispatch(userPage.actions.RESET_STATUS('addingBoatStatus'));
    }
  }, [addingBoatStatus]);

  return (
    <RModalPortal>
      <RMask>
        <RPopup
          width={600}
          onClose={onClose}
          popupTitle="Add boat"
        >
          <RLoadingOverlay isVisible={addingBoatStatus === status.PENDING} />
          <Form onSubmit={handleSubmit(onAdd)}>
            {!selectedFile ? (
              <RFileUpload
                onFileLoad={onLoadFile}
                label="Add Photo"
                name="photo"
                ref={fileUploadRef}
              />
            ) : (
              <>
                <FlexWrap justify="space-between">
                  <Typography
                    color={theme.como}
                    css={{ wordBreak: 'break-word' }}
                  >
                    {fileName}
                  </Typography>
                  <RButtonIcon
                    size={24}
                    color="#000000"
                    onClick={onDeleteFile}
                  >
                    <IconClose />
                  </RButtonIcon>
                </FlexWrap>
                <Spacer m="0 0 10px 0" />
              </>
            )}
            {renderFields()}
            <RButton
              type="submit"
              fullWidth
            >
              Save
            </RButton>
          </Form>
        </RPopup>
      </RMask>
    </RModalPortal>
  );
}
