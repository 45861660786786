import { useCallback } from 'react';
import PropTypes, { arrayOf } from 'prop-types';

import { reactionsEmoji } from 'utils/const';
import { ContextMenuContainer, ReactionContainer } from './styled';

ReactionsPopup.propTypes = {
  options: arrayOf(PropTypes.string),
  currentOption: PropTypes.string,
  onChange: PropTypes.func,
  takeReactionBack: PropTypes.func,
};

export function ReactionsPopup({
  options = reactionsEmoji,
  currentOption,
  onChange,
  takeReactionBack,
}) {
  const renderOptions = useCallback(() => {
    return options.map((option) => {
      const selected = currentOption === option;

      return (
        <ReactionContainer
          key={option}
          selected={selected}
          onClick={selected ? takeReactionBack : onChange(option)}
        >
          {option}
        </ReactionContainer>
      );
    });
  }, []);

  return <ContextMenuContainer>{renderOptions()}</ContextMenuContainer>;
}
