import styled from 'styled-components';

import { Block } from '../mixins';

const Typography = styled.div`
  ${Block};
  font-size: ${({ fz = '16px' }) => fz};
  font-weight: ${({ fw = 400 }) => fw};
  font-style: ${({ fs = 'normal' }) => fs};
  text-transform: ${({ tt = 'none' }) => tt};
  text-decoration: ${({ td = 'none' }) => td};
  text-align: ${({ ta = 'left' }) => ta};
  color: ${({ color = 'initial' }) => color};
  cursor: ${({ cursor = 'auto' }) => cursor};
  ${({ css }) => css}
`;

const Heading = styled(Typography)``;

export { Typography, Heading };
