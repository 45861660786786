import axios from 'axios';

import store from 'store';
import { auth as authModule } from 'store/auth';
import { WEB_API_ROUTES } from './api-routes';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  baseTimeout: 30000,
});

instance.interceptors.request.use((config) => {
  const { auth } = store.getState();
  if (auth.token) config.headers.Authorization = `Bearer ${auth.token}`;
  return config;
});

let times = 0;
instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    const { status } = err.response;
    const authRoutes = originalConfig.url !== WEB_API_ROUTES.auth.login;
    if (authRoutes && status === 401) {
      if (times < 1) {
        times += 1;
        try {
          store.dispatch(authModule.actions.SWIPE_TOKENS());
          const res = await instance.post(WEB_API_ROUTES.auth.refreshToken);
          store.dispatch(authModule.actions.SET_REFRESH_TOKEN(res.data));
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else {
        store.dispatch(authModule.actions.RESET_STATE());
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
