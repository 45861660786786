import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useBlockLayout,
  useTable,
  usePagination,
  useRowSelect,
  useExpanded,
} from 'react-table';

import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { TablePagination } from 'components/RTable/TablePagination';
import {
  Main,
  Table,
  TBody,
  TCell,
  THeader,
  THeaderRow,
  THead,
  TRow,
  TScroll,
  EmptyText,
} from './styled';

RTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchData: PropTypes.func,
  onRowClick: PropTypes.func,
  onChangeSort: PropTypes.func,
  loading: PropTypes.bool,
  disablePagination: PropTypes.bool,
  isClickableRow: PropTypes.bool,
  height: PropTypes.number,
  perPage: PropTypes.number,
  totalRows: PropTypes.number,
  pageCount: PropTypes.number,
  css: PropTypes.object,
};

function getTableEntityProps(oldGetProps, newStyles) {
  return {
    ...oldGetProps,
    style: {
      ...oldGetProps.style,
      ...newStyles,
    },
  };
}

export function RTable({
  columns,
  data,
  height,
  perPage = 10,
  totalRows,
  disablePagination = false,
  loading = false,
  isClickableRow = false,
  onRowClick = () => {},
  fetchData = () => {},
  pageCount: controlledPageCount,
  css,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: perPage },
      pageCount: controlledPageCount,
      manualPagination: true,
      autoResetPage: false,
    },
    useBlockLayout,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <Main css={css}>
      <RLoadingOverlay isVisible={loading} />
      <Table
        {...getTableProps()}
        className="sticky"
      >
        <TScroll height={height}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <THeaderRow
                key={Math.random()}
                {...headerGroup.getHeaderGroupProps()}
                className="tr"
              >
                {headerGroup.headers.map((column) => (
                  <THeader
                    key={column.id}
                    {...getTableEntityProps(
                      column.getHeaderProps(),
                      column.headStyle
                    )}
                    className="th"
                    isSorted={column.isSorted}
                  >
                    {column.render('Header')}
                  </THeader>
                ))}
              </THeaderRow>
            ))}
          </THead>

          <TBody
            {...getTableBodyProps()}
            className="body"
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <TRow
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={onRowClick(row)}
                  className="tr"
                  isClickable={isClickableRow}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TCell
                        key={Math.random()}
                        {...getTableEntityProps(
                          cell.getCellProps(),
                          cell.column.bodyStyle
                        )}
                        className="td"
                      >
                        {cell.render('Cell')}
                      </TCell>
                    );
                  })}
                </TRow>
              );
            })}
            {!data.length ? <EmptyText>Nothing yet</EmptyText> : null}
          </TBody>
        </TScroll>
      </Table>

      {!disablePagination && data.length ? (
        <TablePagination
          totalRows={totalRows}
          perPage={perPage}
          pageSize={pageSize}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          loading={loading}
        />
      ) : null}
    </Main>
  );
}
