import { MemoizedTextEllipsesCell } from 'components/RTable/SharedCells/TextEllipsesCell';
import { MemoizedDateCell } from './DateCell';

export const columns = [
  {
    Header: 'Project name',
    accessor: 'title',
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 150,
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Track',
    accessor: 'track',
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Priority',
    accessor: 'urgency',
    width: 80,
    headStyle: {
      paddingBlock: '12px',
    },
    bodyStyle: {
      paddingBlock: '12px',
    },
    Cell: MemoizedTextEllipsesCell,
  },
  {
    Header: 'Creation date',
    accessor: 'createdAt',
    headStyle: {
      justifyContent: 'flex-end',
      paddingBlock: '12px',
    },
    bodyStyle: {
      justifyContent: 'flex-end',
      paddingBlock: '12px',
    },
    Cell: MemoizedDateCell,
  },
];
