import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { profileModel } from 'models/profile';
import { projectModel } from 'models/project';
import { invoiceModel } from 'models/invoice';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  invoice: invoiceModel(),
  project: projectModel(),
  profile: profileModel(),
  fetchingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'invoicePage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getInvoiceDetails.pending, (state) => {
        state.fetchingStatus = status.PENDING;
      })
      .addCase(
        thunks.getInvoiceDetails.fulfilled,
        (state, { payload: { invoice, profile, project } }) => {
          state.profile = profileModel(
            profile._id,
            profile.firstname,
            profile.lastname,
            profile.email,
            profile.phone,
            profile.role
          );
          state.project = projectModel(
            project._id,
            project.userId,
            project.title,
            project.description,
            project.urgency,
            project.status,
            project.track,
            project.serviceProvider,
            project.category,
            project.cost,
            project.photos,
            project.files,
            project.createdAt,
            project.updatedAt,
            project.appointment
          );
          state.invoice = invoiceModel(
            invoice._id,
            invoice.amount,
            invoice.description,
            invoice.status,
            invoice.createdAt,
            invoice.updatedAt
          );
          state.fetchingStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getInvoiceDetails.rejected, (state) => {
        state.fetchingStatus = status.FAIL;
      });
  },
});

const invoicePage = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { invoicePage };
export default slice.reducer;
