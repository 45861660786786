import PropTypes from 'prop-types';

import { Logo } from './styled';

AppLogo.propTypes = {
  css: PropTypes.object,
};

export function AppLogo({ css }) {
  return <Logo css={css} />;
}
