export const invoiceModel = (
  _id = '',
  amount = 0,
  description = '',
  status = '',
  createdAt = '',
  updatedAt = ''
) => {
  return { _id, amount, description, status, createdAt, updatedAt };
};
