import styled from 'styled-components';

const Wrap = styled.div`
  padding-block: 35px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 32px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.concord};
  margin-top: 8px;
`;

export { Wrap, Title, Text };
