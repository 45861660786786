import PropTypes from 'prop-types';

import { Notification, Header, Title } from '../_shared/styled';

NoteNotification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    client: PropTypes.shape({
      _id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  }),
};

export function NoteNotification({ notification }) {
  return (
    <Notification>
      <Header>
        <Title>
          <span>
            {notification.client.firstname}&nbsp;
            {notification.client.lastname}
          </span>
          &nbsp;created a new note
        </Title>
      </Header>
    </Notification>
  );
}
