import { Table } from 'pages/Admin/UsersPage/Table';
import { PageTitle, PageDesc, Header } from 'pages/_shared/styled';
import { Wrap } from './styled';

function UsersPage() {
  return (
    <Wrap>
      <Header>
        <div>
          <PageTitle>Users list</PageTitle>
          <PageDesc>List of users added to the system</PageDesc>
        </div>
      </Header>
      <Table />
    </Wrap>
  );
}

export default UsersPage;
