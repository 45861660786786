import { useEffect, useState } from 'react';

const documentStates = {
  VISIBLE: 'visible',
};

function getVisibility() {
  if (typeof document === 'undefined') return true;
  return document.visibilityState;
}

export function useDocumentVisibility() {
  const [documentVisibility, setDocumentVisibility] = useState(getVisibility());

  function handleVisibilityChange() {
    setDocumentVisibility(getVisibility());
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return documentVisibility === documentStates.VISIBLE;
}
