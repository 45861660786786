const selectors = {
  listMsg: (state) => state.chatsRoom.listMsg,
  listMsgPag: (state) => state.chatsRoom.listMsgPag,
  listMsgStatus: (state) => state.chatsRoom.listMsgStatus,
  listOldMsg: (state) => state.chatsRoom.listOldMsg,
  listOldMsgPag: (state) => state.chatsRoom.listOldMsgPag,
  listOldMsgStatus: (state) => state.chatsRoom.listOldMsgStatus,
  listUnreadMsg: (state) => state.chatsRoom.listUnreadMsg,
  listNewMsg: (state) => state.chatsRoom.listNewMsg,
  currentRoomId: (state) => state.chatsRoom.currentRoomId,
  targetUserId: (state) => state.chatsRoom.targetUserId,
  profile: (state) => state.chatsRoom.profile,
  project: (state) => state.chatsRoom.project,
  sendMessageStatus: (state) => state.chatsRoom.sendMessageStatus,
  fileUploadStatus: (state) => state.chatsRoom.fileUploadStatus,
  deleteOwnMessageStatus: (state) => state.chatsRoom.deleteOwnMessageStatus,
};

export { selectors };
