import PropTypes from 'prop-types';

import { transformNumber } from 'utils/prepare';

import { Text, Wrap } from './styled';

InvoiceInfo.propTypes = {
  invoice: PropTypes.shape({
    amount: PropTypes.number,
    description: PropTypes.string,
  }),
};

export function InvoiceInfo({ invoice }) {
  const { amount, description } = invoice ?? {};
  return (
    <Wrap>
      <Text>$ {transformNumber(amount / 100)}</Text>
      <Text>{description}</Text>
    </Wrap>
  );
}
