import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { projectDetails } from 'store/manager/projectDetails';
import { status } from 'utils/const';
import { PageTitle, Header, BreadCrumbs } from 'pages/_shared/styled';
import { Spacer } from 'components/App/GlobalStyled';
import { ProjectInfo } from './ProjectInfo';
import { ProjectSteps } from './ProjectSteps';
import { Wrap, Flex } from './styled';

const ProjectDetails = () => {
  const history = useHistory();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const statusDetails = useSelector(projectDetails.selectors.detailsStatus);
  const { title } = useSelector(projectDetails.selectors.project) ?? {};

  const goToProjects = () => {
    history.push('/manager/projects');
  };

  useEffect(() => {
    if (projectId) {
      dispatch(projectDetails.thunks.getProjectDetails(projectId));
    }

    return () => dispatch(projectDetails.actions.RESET_STATE());
  }, [projectId]);

  return (
    <>
      {status.PENDING === statusDetails ? (
        <Loader size={80} />
      ) : (
        <Wrap>
          <Header>
            <div>
              <PageTitle>Project details</PageTitle>
              <BreadCrumbs
                onClick={goToProjects}
                css={{ color: '#587365' }}
              >
                Projects&nbsp;&frasl;&nbsp;
                <strong>{title}</strong>
              </BreadCrumbs>
            </div>
          </Header>
          <Spacer m="0 0 20px 0" />
          <Flex>
            <ProjectInfo />
            <ProjectSteps />
          </Flex>
        </Wrap>
      )}
    </>
  );
};

export default ProjectDetails;
