import { MemoizedManufacturerCell } from 'pages/Manager/UserPage/Boats/Table/ManufacturerCell';

export const columns = [
  {
    Header: 'Boat Name',
    accessor: 'name',
    width: 100,
  },
  {
    Header: 'Boat make',
    accessor: 'manufacturer',
    Cell: MemoizedManufacturerCell,
  },
  {
    Header: '',
    accessor: 'empty-1',
  },
  {
    Header: '',
    accessor: 'empty-2',
  },
];
