import { ReactComponent as IconInbox } from 'assets/img/sidebar/inbox.svg';
import { ReactComponent as IconUsers } from 'assets/img/sidebar/users.svg';
import { ReactComponent as IconBoats } from 'assets/img/sidebar/boats.svg';
import { ReactComponent as IconProjects } from 'assets/img/sidebar/projects.svg';
import { ReactComponent as IconInvoices } from 'assets/img/sidebar/invoices.svg';
import { ReactComponent as IconManagers } from 'assets/img/sidebar/chart-pie.svg';

const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const managerSidebarLinks = [
  {
    route: '/manager/inbox',
    icon: <IconInbox />,
    title: 'Inbox',
    subLinks: [
      {
        route: `/manager/inbox/general`,
        title: 'General',
        counter: 'generalChatCount',
      },
      {
        route: `/manager/inbox/project`,
        title: 'Projects',
        counter: 'projectChatCount',
      },
    ],
  },
  { route: '/manager/users', icon: <IconUsers />, title: ' Users' },
  { route: '/manager/boats', icon: <IconBoats />, title: 'Boats' },
  { route: '/manager/projects', icon: <IconProjects />, title: 'Projects' },
  { route: '/manager/invoices', icon: <IconInvoices />, title: 'Invoices' },
];

const adminSidebarLinks = [
  { route: '/admin/users', icon: <IconUsers />, title: ' Users' },
  { route: '/admin/managers', icon: <IconManagers />, title: 'Managers' },
];

const font = {
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '22px',
  xl: '24px',
};

const messageTypes = {
  OWN: 'own',
  OPPOSITE: 'opposite',
  SYSTEM: 'system',
};

const chatStatuses = {
  active: 'In progress',
  completed: 'Completed',
  canceled: 'Canceled',
};

const chatTypes = {
  general: 'general',
  project: 'project',
};

const projectChatTypes = {
  active: 'active',
  completed: 'completed',
};

const notificationTypes = {
  CHAT: 'message.send',
  CHAT_REACTION: 'message.reaction',
  NOTE: 'note.create',
  PROJECT: 'project.create',
  PROJECT_UPDATE: 'project.file.update',
  PROJECT_CANCELED: 'project.cancel',
  INVOICE_PAID: 'notification.payment.success',
};

const projectChatTabs = [
  {
    title: 'Active',
    slug: 'active',
  },
  {
    title: 'Completed',
    slug: 'completed',
  },
];

const statusSteps = [
  'Vendor search',
  'Scheduling appointment',
  'Job completed',
  'Invoice ready',
  'Completed',
];

const projectTrack = {
  search: 'Vendor search',
  scheduling: 'Scheduling appointment',
  workCompleted: 'Job completed',
  invoice: 'Invoice ready',
  completed: 'Completed',
};

const projectStatuses = {
  active: 'In progress',
  completed: 'Completed',
  canceled: 'Canceled',
};

const photosFormats = [
  'image/heic',
  'image/heif',
  'image/png',
  'image/jpg',
  'image/jpeg',
];

const filesFormats = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const reactionsEmoji = ['❤️', '👍', '👎', '😃', '❓', '❗'];

export {
  status,
  months,
  adminSidebarLinks,
  managerSidebarLinks,
  font,
  messageTypes,
  chatStatuses,
  chatTypes,
  projectChatTypes,
  notificationTypes,
  projectChatTabs,
  statusSteps,
  projectTrack,
  projectStatuses,
  photosFormats,
  filesFormats,
  reactionsEmoji,
};
