import { createSlice } from '@reduxjs/toolkit';

import { projectStatuses, status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  invoice: false,
  project: {},
  steps: {
    statusSteps: 'Vendor search',
  },
  editMode: false,
  detailsStatus: status.PENDING,
};

const slice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    SET_EDIT_MODE: (state, { payload }) => {
      state.editMode = payload;
    },
    SET_STATUS_STEP: (state, { payload }) => {
      state.steps.statusSteps = payload;
    },
    CANCEL_CURRENT_PROJECT: (state, { payload }) => {
      if (
        window.location.pathname.includes(
          `/manager/projects/${payload.project._id}`
        )
      ) {
        state.project.status = projectStatuses.canceled;
      }
    },
    RESET_STATE: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProjectDetails.fulfilled, (state, { payload }) => {
        const { project, invoices } = payload ?? {};
        state.detailsStatus = status.SUCCESS;
        state.project = project;
        state.steps.statusSteps = project.track;

        if (invoices.length) {
          // eslint-disable-next-line prefer-destructuring
          state.invoice = invoices[0];
        } else {
          state.invoice = false;
        }
      })
      .addCase(thunks.getProjectDetails.rejected, (state) => {
        state.detailsStatus = status.FAIL;
      })
      .addCase(thunks.createInvoice.fulfilled, (state, { payload }) => {
        state.invoice = payload;
      })
      .addCase(thunks.updateStatusProject.fulfilled, (state, { payload }) => {
        const { status, track } = payload;
        state.project.status = status;
        state.project.track = track;
        state.steps.statusSteps = track;
      });
  },
});

const projectDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { projectDetails };
export default slice.reducer;
