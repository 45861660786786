import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { memo } from 'react';

import { appDateFormat } from 'utils/formats';

CreateDateCell.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.object,
    value: PropTypes.string,
  }),
};

function CreateDateCell({ cell, row }) {
  return `${format(
    new Date(
      row.original.creationDate ? row.original.creationDate * 1000 : cell.value
    ),
    appDateFormat
  )}`;
}

export const MemoizedCreateDateCell = memo(CreateDateCell);
