import styled from 'styled-components';

import { Circle, Flex } from 'components/App/GlobalStyled';

const Reaction = styled.div`
  ${Circle({ size: '24px', background: '#587365' })}
  ${Flex({
    justify: 'center',
  })}
  cursor: auto;
`;

export { Reaction };
