import styled from 'styled-components';

const Form = styled.form`
  margin-top: 24px;
`;

const Btn = styled.button`
  display: none;
`;

export { Form, Btn };
