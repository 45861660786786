import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const adminUsersPage = {
  getUsersList(params) {
    return http.get(
      WEB_API_ROUTES.admin.usersPage.getUsersList.replace('{type}', 'users'),
      { params }
    );
  },
};
