import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

export const adminManagersPage = {
  getManagersList(params) {
    return http.get(
      WEB_API_ROUTES.admin.managersPage.getManagersList.replace(
        '{type}',
        'managers'
      ),
      {
        params,
      }
    );
  },
  addManager(formData) {
    return http.post(WEB_API_ROUTES.admin.managersPage.addManager, formData);
  },
};
